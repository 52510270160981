import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import MainLayout from '../../Components/MainLayout';
import StoreCategory from '../../Components/ui/StoreCategory';
import PromotionalStoreCard from '../../Components/ui/PromotionalStoreCard';
import Footer from '../../Components/shared/footer';
import OverrideAudio from '../../Assets/Overrides/override-audio.png';
import OverrideVideo from '../../Assets/Overrides/override-video.png';
import OverrideScript from '../../Assets/Overrides/override-script.png';
import BackgroundImage from '../../Assets/Images/heroledger-background-store.jfif';

const serverBaseURL = '';

function Store() {
  const [characterData, setCharacterData] = useState([]);
  const [scriptData, setScriptData] = useState([]);
  const [logoData, setLogoData] = useState([]);
  const [backgroundData, setBackgroundData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [propsData, setPropsData] = useState([]);

  const [characterLoader, setCharacterLoader] = useState(true);
  const [scriptLoader, setScriptLoader] = useState(true);
  const [logoLoader, setLogoLoader] = useState(true);
  const [backgroundLoader, setBackgroundLoader] = useState(true);
  const [videoLoader, setVideoLoader] = useState(true);
  const [audioLoader, setAudioLoader] = useState(true);
  const [propsLoader, setPropsLoader] = useState(true);

  const getAllAssets = async () => {
    // character
    axios.get(serverBaseURL + '/getCharacterAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setCharacterData(resData);
      setCharacterLoader(false);
    });

    // Script
    axios.get(serverBaseURL + '/getScriptAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setScriptData(resData);
      setScriptLoader(false);
    });
    // logo
    axios.get(serverBaseURL + '/getLogoAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setLogoData(resData);
      setLogoLoader(false);
    });
    // background
    axios.get(serverBaseURL + '/getBackgroundAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setBackgroundData(resData);
      setBackgroundLoader(false);
    });
    // audio
    axios.get(serverBaseURL + '/getAudioAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setAudioData(resData);
      setAudioLoader(false);
    });
    // video
    axios.get(serverBaseURL + '/getVideoAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setVideoData(resData);
      setVideoLoader(false);
    });
    // props
    axios.get(serverBaseURL + '/getPropsAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      setPropsData(resData);
      setPropsLoader(false);
    });
  };

  useEffect(() => {
    getAllAssets();
  }, []);

  const [crumbs, setCrumbs] = useState([{ name: 'Home', route: '/overview' }]);

  return (
    <MainLayout
      pageTitle={'Store'}
      crumbs={crumbs}
      setCrumbs={setCrumbs}
      isInStore={true}
    >
      <div
        className="px-4 pt-2"
        id="storeContainer"
        style={{ color: '#EEB609' }}
      >
        {/* <div className="row mb-2">
          {/* <div className="col-md-4 col-sm-12 pr-2">
            <PromotionalStoreCard
              text={'See the best of HeroLedger'}
              background={`url(${BackgroundImage}) center center,  rgba(0, 0, 0, 0.6)`}
            />
          </div> */}
        {/* <div className="col-md-4 col-sm-12 pr-2">
            <PromotionalStoreCard
              text={'10% OFF'}
              background={`url(${BackgroundImage}) center bottom, rgba(215, 106, 1, 0.6)`}
            />
          </div> */}
        {/* <div className="col-md-4 col-sm-12">
            <PromotionalStoreCard
              text={'New Product'}
              background={`url(${BackgroundImage}) center top, rgba(0, 0, 0, 0.6)`}
              onclick={handleClickProduct}
            />
          </div> */
      /* </div> */}
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="row">
              <div className="col-12">Category</div>
              <div className="col-12">
                <select className="form-control form-control-sm">
                  <option>Characters</option>
                  <option>Scripts</option>
                  <option>Logos</option>
                  <option>Background</option>
                  <option>Videos</option>
                  <option>Audios</option>
                  <option>Props</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="row">
              <div className="col-12">Price</div>
              <div className="col-12">
                <select className="form-control form-control-sm">
                  {/* <option>Small select</option> */}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="row">
              <div className="col-12">Ratings</div>
              <div className="col-12">
                <select className="form-control form-control-sm">
                  {/* <option>Small select</option> */}
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="row">
              <div className="col-12">Order By</div>
              <div className="col-12">
                <select className="form-control form-control-sm">
                  {/* <option>Small select</option> */}
                </select>
              </div>
            </div>
          </div>
        </div>
        <StoreCategory
          title="Characters"
          items={characterData}
          loader={characterLoader}
          backgroundImage={null}
        />
        <StoreCategory
          title="Scripts"
          overrideItemsImage={OverrideScript}
          items={scriptData}
          loader={scriptLoader}
          backgroundImage={`url(${BackgroundImage}), rgba(0, 0, 0, 0.3)`}
        />
        <StoreCategory
          title="Logos"
          items={logoData}
          loader={logoLoader}
          backgroundImage={`url(${BackgroundImage}), rgba(215, 106, 1, 0.6)`}
        />
        <StoreCategory
          title="Backgrounds"
          items={backgroundData}
          loader={backgroundLoader}
          backgroundImage={null}
        />
        <StoreCategory
          title="Videos"
          overrideItemsImage={OverrideVideo}
          items={videoData}
          loader={videoLoader}
          backgroundImage={`url(${BackgroundImage}), rgba(0, 0, 0, 0.3)`}
        />
        <StoreCategory
          title="Audios"
          overrideItemsImage={OverrideAudio}
          items={audioData}
          loader={audioLoader}
          backgroundImage={`url(${BackgroundImage}), rgba(215, 106, 1, 0.6)`}
        />
        <StoreCategory
          title="Props"
          items={propsData}
          loader={propsLoader}
          backgroundImage={null}
        />
      </div>
      <Footer />
    </MainLayout>
  );
}

export default Store;
