/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';

import HeroLedgerLogo from '../../Assets/heroledger-logo-mini.png';
import IconAlert from '../../Assets/Icons/icon-alert.png';
import IconCart from '../../Assets/Icons/icon-cart.png';
import IconChat from '../../Assets/Icons/icon-chat.png';
import IconSearch from '../../Assets/Icons/icon-search.png';
import DummyUser from '../../Assets/Images/dummy-user.png';
import Breadcrumb from '../../Components/shared/Breadcrumb';

const serverBaseURL = '';

const Topnavbar = ({ pageTitle, crumbs, setCrumbs, children, isInStore }) => {
  const imgDropdown = {
    display: 'none',
    /* position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1; */
  };
  const selected = (crumb) => {
    console.log(crumb);
  };
  const [isLoading, setLoader] = useState(false);
  const [profileClick, clickProfile] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchHidden, setIsSearchHidden] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [notifications, setNotifications] = useState([{}]);
  const [newNotiNum, setNewNotiNum] = useState('3');

  const { email, name, image, _id } = JSON.parse(localStorage.getItem('user'));
  // console.log(image);
  const isMobileWidth = window.innerWidth > 768;
  const sidebarRef = document.querySelector('#sidebar');
  const sideBarContainerRef = document.querySelector('.sideBarContainer');

  let history = useHistory();
  const toggleSidebarClass = () => {
    sidebarRef.classList.remove('hide-names');
    // sidebarRef.classList.toggle('active');
  };

  const toggleSidebarNames = () => {
    sidebarRef.classList.toggle('hide-names');
    sideBarContainerRef.classList.toggle('active');
  };

  const capitalizeName = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const getUserInfo = async () => {
    await axios.get('/getImage', { params: { email } }).then((res) => {
      const userData = res.data.data[0];
      localStorage.setItem('user', JSON.stringify(userData));
    });
  };
  const objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length &&
    Object.keys(o1).every((p) => o1[p] === o2[p]);

  const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
  let latestNotification = [];
  const getNotifications = async () => {
    await axios
      .post(serverBaseURL + '/getNotification', { _id: _id, seen: false })
      .then((res) => {
        if (!arraysEqual(latestNotification, res.data)) {
          latestNotification = res.data;
          setNotifications([...res.data]);
        }
      });
  };
  useEffect(() => {
    console.log('Notifications', notifications);
  }, [notifications]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.has('code')) {
      console.log(query.get('code'));
      axios
        .post(serverBaseURL + '/authenticatePaypal', {
          code: query.get('code'),
          _id: JSON.parse(localStorage.getItem('user'))._id,
        })
        .then((res) => {
          if (res.data.error) {
            toast.warn(res.data.reason);
          } else {
            // window.location = window.location.href.split("?")[0];
            window.location.pathname = '/assets';
          }
        });
    }
  }, []);

  const logout = () => {
    setLoader(true);
    axios
      .put('/logout', { email })
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          history.push('/');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    history.push(`/search/${searchValue}`);
    setIsSearchHidden(false);
    setSearchValue('');
  };

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const notificationDOM = useRef(null);
  function useNotifyOutsideClickDetect(ref) {
    useEffect(() => {
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsNotify(false);
        }
      }

      document.addEventListener('click', handleOutsideClick);
    }, [ref]);
  }

  useEffect(() => {
    if (_id) {
      getNotifications();
      setInterval(() => {
        getNotifications();
      }, 15000);
    }
  }, [_id]);

  useNotifyOutsideClickDetect(notificationDOM);
  useEffect(() => {
    getUserInfo();
  }, []);

  const EachNotification = ({ msg, date, k }) => {
    return (
      <div className="NotificationDropDown__eachNotification" key={k}>
        <div className="NotificationDropDown__eachNotification__imageContainer"></div>
        <div className="NotificationDropDown__eachNotification__notificationText">
          <p>{msg}</p>

          <span style={{ color: 'orange' }}>{moment(date).fromNow()}</span>
        </div>
      </div>
    );
  };

  const NotificationDom = () => {
    let n = [];
    for (let i = notifications.length - 1; i >= 0; i--) {
      n.push(
        <EachNotification
          msg={notifications[i].text}
          date={notifications[i].date}
          k={Math.random()}
          key={i}
        />
      );
    }
    return n;
  };
  let n = NotificationDom();
  const Notifications = (props) => {
    const dropdownmenu = useRef(null);

    useEffect(() => {
      function handleOutsideClick(event) {
        if (
          dropdownmenu.current &&
          !dropdownmenu.current.contains(event.target)
        ) {
          setIsNotify(false);
        }
      }

      document.addEventListener('click', handleOutsideClick);
    }, [dropdownmenu]);
    return (
      <div className="Dropdown" ref={dropdownmenu}>
        {props.children}
      </div>
    );
  };
  return (
    <div
      id="content"
      style={
        isInStore ? { padding: 0, background: 'rgba(0,0,0,0)' } : { padding: 0 }
      }
    >
      <ToastContainer />
      <nav
        className={
          isMobileWidth
            ? 'navbar navbar-expand-lg navbar-light '
            : 'navbar navbar-expand-lg navbar-light bg-dark'
        }
        style={isInStore ? { background: 'rgba(0,0,0,0)' } : {}}
      >
        <div
          className="container-fluid"
        // style={isInStore ? { background: "rgb(200,200,200)" } : {}}
        >
          <button
            type="button"
            className={
              isMobileWidth
                ? 'btn btn-light d-block d-sm-none'
                : 'btn btn-dark d-block d-sm-none'
            }
            onClick={toggleSidebarClass}
            style={isInStore ? { background: 'rgba(0,0,0,0)' } : {}}
          >
            <i className="fas fa-align-left"></i>
          </button>
          <button
            type="button"
            className={
              isMobileWidth
                ? 'btn btn-light d-none d-sm-block mr-4'
                : 'btn btn-dark d-none d-sm-block mr-4'
            }
            style={
              isInStore ? { background: 'rgba(0,0,0,0)', border: 'none' } : {}
            }
            onClick={toggleSidebarNames}
          >
            <i className="fas fa-align-left"></i>
          </button>

          <div
            className={'d-none d-sm-block text-align-center ml-4 mr-auto mb-4'}
            style={
              isInStore
                ? {
                  fontSize: '26px',
                  fontWeight: '700',
                  position: 'relative',
                  top: '13px',
                  left: '-44px',
                  color: 'white',
                }
                : {
                  fontSize: '26px',
                  fontWeight: '700',
                  position: 'relative',
                  top: '13px',
                  left: '-44px',
                  color: '#343a40',
                }
            }
          >
            {pageTitle}
          </div>
          <div
            className={'d-block d-md-none text-align-center mx-auto text-light'}
          >
            <img src={HeroLedgerLogo} alt="HeroLedger Logo" /> HeroLedger
          </div>

          <button
            className="btn btn-light d-inline-block d-lg-none"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-align-justify"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="nav navbar-nav ml-auto align-items-center">
              {/* <li className="nav-item px-2 py-1">
                  <img src={IconCart} alt="Icon Cart" />
                </li> */}

              <li
                className="nav-item px-2 py-1"
                style={{ display: isSearchHidden ? 'none' : 'block' }}
              >
                <form onSubmit={handleFormSubmit}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search products..."
                    onChange={handleChange}
                    required
                  />
                  <button type="submit" hidden></button>
                </form>
              </li>
              <li
                className="nav-item px-2 py-1 cursor-pointer"
                onClick={() => setIsSearchHidden(false)}
              >
                <img src={IconSearch} alt="Icon Search" />
              </li>
              {/* <li className="nav-item px-2 py-1">
                <img src={IconChat} alt="Icon Chat" />
              </li> */}
              <li
                className="nav-item px-2 py-1 mr-3"
                onClick={() => setIsNotify(true)}
                ref={notificationDOM}
              >
                <img src={IconAlert} alt="Icon Alert" />
                {/* <span className="notificationNumber">{newNotiNum}</span> */}
                {isNotify ? (
                  <div className="nContainer">
                    {
                      <Notifications>
                        <div className="NotificationDropDown">
                          <div className="NotificationDropDown__topbar">
                            Notifications
                          </div>
                          {n}
                        </div>
                      </Notifications>
                    }
                  </div>
                ) : (
                  <></>
                )}
              </li>
              <li className="nav-item px-2 py-1" style={{ cursor: 'pointer' }}>
                <div
                  className="row align-items-center"
                  style={{ position: 'relative' }}
                >
                  <p className="font-weight-bold" style={{ margin: '0 5px' }}>
                    {capitalizeName(name)}
                  </p>
                  <div className="dropdown pr-5 pl-2">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => {
                        clickProfile(true);
                      }}
                    >
                      <NavLink
                        className="d-flex justify-content-center align-items-center"
                        to="/profile"
                      >
                        <img
                          className="img-fluid img-thumbnail"
                          src={image ? '/image/' + image : DummyUser}
                          alt="Avatar"
                          style={{
                            height: '50px',
                            width: '50px',
                            objectFit: 'cover',
                            borderRadius: '50%',
                          }}
                        />
                      </NavLink>
                      <div className="dropdown-content">
                        <NavLink
                          className="d-flex justify-content-center align-items-center"
                          to="/profile"
                        >
                          <span>View Profile</span>
                        </NavLink>
                        <div
                          className="d-flex justify-content-center align-items-center py-2"
                          style={{ cursor: 'pointer', color: '#999' }}
                          onClick={logout}
                        >
                          {' '}
                          <div>Logout</div>
                          <i className="pl-2 pt-1 fas fa-sign-out-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="metamaskConnect"
                    onClick={() => {
                      const getAccount = async () => {
                        const accounts = await window.ethereum.request({
                          method: 'eth_requestAccounts',
                        });
                        console.log(accounts[0]);
                        return accounts[0];
                      };
                      if (typeof window.ethereum !== 'undefined') {
                        console.log('Ethereum is installed');
                        const account = getAccount();
                        console.log(account);
                      } else {
                        toast.warn(
                          'Metamask is not installed, please download the extension for your browser'
                        );
                      }
                    }}
                  >
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/64px-MetaMask_Fox.svg.png"
                      className="metamaskButton"
                      alt="Connect to Metamask"
                    />
                  </div>
                </div>
              </li>

              {isLoading && (
                <li className="nav-item px-2 py-1 ml-2">
                  <i className="fas fa-spinner rotate-center"></i>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <Breadcrumb crumbs={crumbs} selected={selected} isInStore={isInStore} />
      {children}
    </div>
  );
};

export default Topnavbar;
/*
  1. metamask connect with 
*/
