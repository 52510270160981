import React from 'react';
import axios from 'axios';

const serverBaseURL = '';

function basicPopup(url) {
  window.open(
    url,
    'popUpWindow',
    'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
  );
}

const CoinbaseButton = ({ productID, price }) => {
  const handleClick = () => {
    axios
      .post(serverBaseURL + '/coinbase-charge', {
        productID: productID,
      })
      .then((res) => {
        console.log(res.data.hosted_url);
        basicPopup(res.data.hosted_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <button
        style={{
          backgroundColor: 'transparent',
          width: '100%',
          height: '100%',
          border: 'none',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          backgroundColor: '#f2994a',
        }}
        className="btn-primary btn px-5 text-center metamaskBtnPayment"
        onClick={handleClick}
      >
        Initiate payment
      </button>
    </>
  );
};
export default CoinbaseButton;
