import React from "react";
import MainLayout from "../../Components/MainLayout";

const Privacy = () => {
  return (
    <>
      <div>
        <MainLayout
          removeSidebar={true}
          isInStore={false}
          isInTerms={true}
          isInPrivacy={true}
          pageTitle="Privacy Policy"
        />
      </div>
    </>
  );
};
export default Privacy;
