import React from "react";
import { NavLink } from "react-router-dom";

const SidebarItem = ({ url, icon, description }) => {
  return (
    <NavLink
      to={url}
      activeClassName={"sidebar-menu-item-selected"}
      className="w-100 py-3 row sidebar-menu-item"
      style={{
        margin: 0,
        alignItems: "center",
      }}
    >
      <div className="col-2 d-flex justify-content-center align-items-center m-auto">
      <img src={icon} alt={`${description} Icon`} className="text-center icon-width" />
      </div>
      <span className="col-10">{description}</span>
    </NavLink>
  );
};

export default SidebarItem;
