// import React, { useEffect, useRef } from "react";
// import { ReactDOM } from "react";
// // /* global paypal */
// const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
// const PaypalButton = () => {
//   const paypalRef = useRef(null);
//   useEffect(() => {
//     // /* global paypal */
//     // if (window.paypalBtn) window.paypalBtn.close();

//     const paypalButton = window.paypal.Buttons({
//       createOrder: function (data, actions) {
//         // Set up the transaction

//         return actions.order.create({
//           purchase_units: [
//             {
//               amount: {
//                 value: "0.01",
//                 currency_code: "USD",
//               },
//             },
//           ],
//         });
//       },
//     });
//     paypalButton.render(paypalRef.current);
//     // paypalButton.close();
//   }, []);
//   const createOrder = (data, actions) => {
//     return actions.order.create({
//       purchase_units: [
//         {
//           amount: { value: "100" },
//         },
//       ],
//     });
//   };
//   return (
//     <>
//       <div>
//         <div ref={paypalRef} id="paypalbtn"></div>
//         {/* <PayPalButton
//           createOrder={(data, actions) => createOrder(data, actions)}
//         /> */}
//       </div>
//     </>
//   );
// };
// export default PaypalButton;
/* global paypal */
import React from 'react';
import axios from 'axios';

import ReactDOM from 'react-dom';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

function PaypalButton({ price, productId, description, handleBuyAction }) {
  console.log(price, 'price');
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: '0.01',
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture();
  };
  const initialOptions = {
    'client-id': 'test',
    currency: 'USD',
    intent: 'capture',
    'data-client-token': 'abc123xyz==',
  };
  return (
    <PayPalScriptProvider
      options={{
        'client-id':
          'AfzKRNnWr2MbGi07v9iRqYoUwCJg5PJbwMgj00gK7_oT-Zx86zx5nuZ_7BAh1J35cT-uwUxFkT9a8jYi',
        // components: "buttons",
      }}
    >
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: description,
                amount: {
                  value: price,
                },
                // custom_id: productId,
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          console.log('DATA', data);
          // axios
          //   .post("/capturePayment", {
          //     orderId: data.orderID,
          //     accessToken: data.facilitatorAccessToken,
          //   })
          //   .then((res) => {
          //     console.log(res);
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          try {
            const order = await actions.order.capture();
            console.log('ORDER', order);
            // axios.post("/saveTransaction", {
            //   data: order,
            // });
            handleBuyAction();
          } catch (err) {}

          // TODO send these data to backend to be stored to database

          // TODO
        }}
        onError={(err) => {
          console.log(err);
        }}
      />
    </PayPalScriptProvider>
  );
}

export default PaypalButton;
