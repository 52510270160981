import axios from 'axios';

export const purchaseProduct = async (contract, productInfos) => {
  try {
    console.log(productInfos, 'productInfos');
    const { email, countryCode, countryName } = JSON.parse(
      localStorage.getItem('user')
    );
    const account = localStorage.getItem('account');
    await contract.methods
      .purchaseProduct(
        productInfos.productId,
        email,
        productInfos.price.toString(),
        countryCode,
        productInfos.ownerAddress
      )
      .send({ from: account, gas: 6000000, gasPrice: 6000000 })
      .once('receipt', (receipt) => {
        const BCData = receipt.events.productPurchased;
        const returnData = receipt.events.productPurchased.returnValues;
        console.log(returnData);
        const updatedProduct = {
          productId: returnData.productId,
          ownerAddress: returnData.ownerAddress,
          ownerEmail: returnData.ownerEmail,
          inStore: returnData.inStore,
          timestamp: returnData.timestamp,
          transactionHash: BCData.transactionHash,
        };

        const transactionDetails = {
          productId: returnData.productId,
          productName: returnData.productName,
          transactionHash: BCData.transactionHash,
          transactionType: 'Purchase',
          previousOwner: returnData.seller,
          currentOwner: returnData.ownerEmail,
          purchaseDate: returnData.timestamp,
          amountinEth: returnData.amount,
          txtamount: productInfos.price,
          countryCode: countryCode,
          counrtyName: countryName,
          blockchaintxtid: returnData.amount,
          registrationDate: returnData.registrationDate,
        };

        axios
          .put('/purchaseProduct', { updatedProduct })
          .then((updatedProductResponse) => {
            axios
              .post('/addTransaction', { transactionDetails })
              .then((transactionDetailsResponse) => {
                alert('Purchase Succesfful, Product added to your account');
              });
          })
          .catch((error) => {
            alert('Purchase not successful, Please try again', error);
          });
      });
  } catch (error) {
    console.log('Error on purchasing product. Contact the administrator.');
    console.error(error);
  }
};
