import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import PrivateRouter from "./PrivateRoute";
import PreLogin1 from "./Pages/Pre-Login/PreLogin1";
import Register from "./Pages/Pre-Login/Register";
import ProductDescription from "./Pages/ProductDescription";
import Store from "./Pages/Home/Store";
import Overview from "./Pages/Home/Overview";
import MyData from "./Pages/Home/MyData";
import Terms from "./Pages/Home/TermsOfUse";
import Privacy from "./Pages/Home/PrivacyPolicy";

import Assets from "./Pages/Home/Assets";
import Licensing from "./Pages/Home/Licensing";
import Transactions from "./Pages/Home/Transactions";
import Setting from "./Pages/Home/Setting";
import Payment from "./Pages/Home/Payment.js";
import Profile from "./Pages/Profile";
import Characters from "./Pages/Home/IndividualCategories/Characters";
import Logos from "./Pages/Home/IndividualCategories/Logos";
import Scripts from "./Pages/Home/IndividualCategories/Scripts";
import Audios from "./Pages/Home/IndividualCategories/Audios";
import Videos from "./Pages/Home/IndividualCategories/Videos";
import Props from "./Pages/Home/IndividualCategories/Props";
import Backgrounds from "./Pages/Home/IndividualCategories/Backgrounds";
import Blank from "./Pages/Blank";
import StoreCategoryPage from "./Pages/Store/StoreCategoryPage";
import ProductDetailPage from "./Pages/Store/ProductDetailPage";
import AccountRecoveryPage from "./Pages/Pre-Login/AccountRecovery";
import Documents from "./Pages/Home/Documents";

const AppRouter = () => {
  return (
    <Router>
      <Route exact path="/" component={PreLogin1} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/recovery" component={AccountRecoveryPage} />
      <PrivateRouter exact path="/overview" component={Overview} />
      <PrivateRouter exact path="/mydata" component={MyData} />
      <PrivateRouter exact path="/product" component={ProductDescription} />
      <PrivateRouter exact path="/assets" component={Assets} />
      <PrivateRouter exact path="/licensing" component={Licensing} />
      <PrivateRouter exact path="/transactions" component={Transactions} />
      <PrivateRouter exact path="/profile" component={Profile} />
      <PrivateRouter exact path="/terms" component={Terms} />
      <PrivateRouter exact path="/privacyPolicy" component={Privacy} />
      <PrivateRouter exact path="/documents" component={Documents} />

      {/* STORE ROUTES */}
      <PrivateRouter exact path="/store" component={Store} />
      <PrivateRouter
        exact
        path="/store/category/:categoryName"
        component={StoreCategoryPage}
      />
      <PrivateRouter
        exact
        path="/store/product/:productId"
        component={ProductDetailPage}
      />

      <PrivateRouter
        exact
        path="/search/:searchParams"
        component={StoreCategoryPage}
      />

      {/*Tokens not implemented */}
      {/* <PrivateRouter exact path="/tokens" component={Blank} /> */}
      {/* Connections not implemented */}
      {/* <PrivateRouter exact path="/connections" component={Blank} /> */}
      <PrivateRouter exact path="/settings" component={Setting} />
      <PrivateRouter exact path="/payment" component={Payment} />

      <PrivateRouter exact path="/characters" component={Characters} />
      <PrivateRouter exact path="/scripts" component={Scripts} />
      <PrivateRouter exact path="/logos" component={Logos} />
      <PrivateRouter exact path="/audios" component={Audios} />
      <PrivateRouter exact path="/videos" component={Videos} />
      <PrivateRouter exact path="/props" component={Props} />
      <PrivateRouter exact path="/backgrounds" component={Backgrounds} />
    </Router>
  );
};

export default AppRouter;
