import React from "react";

const TermsOfUse = () => {
  return (
    <>
      <br />
      <div className="terms">
        <div className="terms_con">
          <p className="terms_con_1">
            By using the HeroLedger.io web site ("Service"), or any services of
            Rast·r Technologies, LLC ("Rast·r"), you are agreeing to be bound by
            the following terms and conditions ("Terms of Service"). IF YOU ARE
            ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL
            ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH
            ENTITY, ITS AFFILIATES AND ALL USERS WHO ACCESS OUR SERVICES THROUGH
            YOUR ACCOUNT TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS
            "YOU" OR "YOUR" SHALL REFER TO SUCH ENTITY, ITS AFFILIATES AND USERS
            ASSOCIATED WITH IT. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO
            NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS
            AGREEMENT AND MAY NOT USE THE SERVICES.{" "}
          </p>
          <p>
            If Rast·r makes material changes to these Terms, we will notify you
            by email or by posting a notice on our site before the changes are
            effective. Any new features that augment or enhance the current
            Service, including the release of new tools and resources, shall be
            subject to the Terms of Service. Continued use of the Service after
            any such changes shall constitute your consent to such changes. You
            can review the most current version of the Terms of Service at any
            time at:{" "}
            <a href="http:localhost:3000/terms">https://heroledger.io/terms</a>
          </p>
          <p>
            Violation of any of the terms below will result in the termination
            of your Account. While Rast·r prohibits such conduct and Content on
            the Service, you understand and agree that Rast·r cannot be
            responsible for the Content posted on the Service and you
            nonetheless may be exposed to such materials. You agree to use the
            Service at your own risk.
          </p>
          <p>
            <ol>
              <li>You must be 13 years or older to use this Service.</li>
              <br />
              <li>
                You must be a human. Accounts registered by "bots" or other
                automated methods are not permitted.
              </li>
              <br />
              <li>
                You must provide your legal full name, a valid email address,
                and any other information requested in order to complete the
                signup process.
              </li>
              <br />
              <li>
                Your login may only be used by one person - a single login
                shared by multiple people is not permitted.
              </li>
              <br />
              <li>
                You are responsible for maintaining the security of your account
                and password. Rast·r cannot and will not be liable for any loss
                or damage from your failure to comply with this security
                obligation.
              </li>
              <br />
              <li>
                You are responsible for all Content posted and activity that
                occurs under your account (even when Content is posted by others
                who have prohibited access under your account).
              </li>
              <br />
              <li>
                One person or legal entity may maintain more than one account.
              </li>
              <br />
              <li>
                You may not use the Service for any illegal or unauthorized
                purpose. You must not, in the use of the Service, violate any
                laws in your jurisdiction (including but not limited to
                copyright or trademark laws).
              </li>
            </ol>
          </p>
          <br />
          <p>
            Customers are bound by these Terms of Service plus the following
            specific terms:
          </p>
          <p>
            <ol>
              <li>
                You expressly understand and agree that Rast·r shall not be
                liable for any direct, indirect, incidental, special,
                consequential or exemplary damages, including but not limited
                to, damages for loss of profits, goodwill, use, data or other
                intangible losses (even if Rast·r has been advised of the
                possibility of such damages), resulting from your use of the
                Service or third-party products that access data via the
                Service.
              </li>
              <br />
              <li>
                Abuse or excessively frequent requests to HeroLedger via login
                may result in the temporary or permanent suspension of your
                account's access to the Service. Rast·r, in its sole discretion,
                will determine abuse or excessive usage of the Service. Rast·r
                will make a reasonable attempt via email to warn the account
                owner prior to suspension.
              </li>
              <br />
              <li>
                Rast·r reserves the right at any time to modify or discontinue,
                temporarily or permanently, your access to the Service (or any
                part thereof) with or without notice.
              </li>
              <br />
              <li>
                All transactions requiring payment must enter a valid credit
                card and/or public crypto wallet address.
              </li>
              <br />
              <li>
                For all transactions requiring payment, the Service charges at
                Point of Sale. Transactions are non-refundable. If a mistake in
                purchase is made, the payment will be applied to a marketplace
                items of equal or greater price. You must contact:
                support@rastr.io to rectify transactions.
              </li>
              <br />
              <li>
                All fees are exclusive of all taxes, levies, or duties imposed
                by taxing authorities, and you shall be responsible for payment
                of all such taxes, levies, or duties, excluding only United
                States (federal or state) taxes.
              </li>
              <br />
              <li>
                You are solely responsible for properly canceling your account.
                An email or phone request to cancel your account is not
                considered cancellation. You can cancel your account at any time
                by clicking on the Account link in the global navigation bar at
                the top of the screen. The Account screen provides a simple no
                questions asked cancellation link.
              </li>
              <br />
              <li>
                If you cancel the Service your cancellation will take effect
                immediately. All of your Content will be immediately deleted
                from your profile. This information can not be recovered once
                your account is cancelled.
              </li>
              <br />
              <li>
                Rast·r, in its sole discretion, has the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Service, or any other Rast·r services, for any reason
                at any time. Such termination of the Service will result in the
                deactivation or deletion of your Account or your access to your
                Account, and the forfeiture and relinquishment of all Content in
                your Account. Rast·r reserves the right to refuse service to
                anyone for any reason at any time.
              </li>
              <br />
              <li>
                We claim no intellectual property rights over the material you
                provide to the Service. Your profile and materials uploaded
                remain yours. However, by setting your pages to be viewed
                publicly, you agree to allow others to view your Content. By
                setting your repositories to be viewed publicly, you agree to
                allow others to view and fork your repositories.
              </li>
              <br />
              <li>
                Rast·r does not pre-screen Content, but Rast·r and its designee
                have the right (but not the obligation) in their sole discretion
                to refuse or remove any Content that is available via the
                Service.
              </li>
              <br />
              <li>
                You shall defend Rast·r against any claim, demand, suit or
                proceeding made or brought against Rast·r by a third party
                alleging that Your Content, or Your use of the Service in
                violation of this Agreement, infringes or misappropriates the
                intellectual property rights of a third party or violates
                applicable law, and shall indemnify Rast·r for any damages
                finally awarded against, and for reasonable attorney’s fees
                incurred by, Rast·r in connection with any such claim, demand,
                suit or proceeding; provided, that Rast·r (a) promptly gives You
                written notice of the claim, demand, suit or proceeding; (b)
                gives You sole control of the defense and settlement of the
                claim, demand, suit or proceeding (provided that You may not
                settle any claim, demand, suit or proceeding unless the
                settlement unconditionally releases Rast·r of all liability);
                and (c) provides to You all reasonable assistance, at Your
                expense.
              </li>
              <br />
              <li>
                The look and feel of the Service is copyright ©2022 Rast·r
                Technologies, LLC. All rights reserved. You may not duplicate,
                copy, or reuse any portion of the HTML/CSS, Javascript or visual
                design elements or concepts without express written permission
                from Rast·r.
              </li>
              <br />
              <li>
                Your use of the Service is at your sole risk. The service is
                provided on an "as is" and "as available" basis.
              </li>
              <br />
              <li>
                Customer and Technical Support for HeroLedger services are only
                available in English, via email.
              </li>
              <br />
              <li>
                You understand that Rast·r uses third party vendors and hosting
                partners to provide the necessary hardware, software,
                networking, storage, and related technology required to run the
                Service.
              </li>
              <br />
              <li>
                You must not modify, adapt or hack the Service or modify another
                website so as to falsely imply that it is associated with the
                Service, Rast·r, or any other Rast·r service.
              </li>
              <br />
              <li>
                You may use HeroLedger subdomains (e.g., yourname.heroledger.io)
                solely as permitted and intended by the HeroLedger Pages tool to
                host your company pages, personal pages, or open source project
                pages, and for no other purpose. You may not use HeroLedger
                subdomains in violation of Rast·r's trademark or other rights or
                in violation of applicable law. Rast·r reserves the right at all
                times to reclaim any HeroLedger subdomain without liability to
                you.
              </li>
              <br />
              <li>
                You agree not to reproduce, duplicate, copy, sell, resell or
                exploit any portion of the Service, use of the Service, or
                access to the Service without the express written permission by
                Rast·r.
              </li>
              <br />
              <li>
                You agree the Buyer (not licensee) of intellectual property sold
                in the Service assumes all legal use and ownership rights.
              </li>
              <br />
              <li>
                You agree not the violate the copyright and trademark rights of
                all other users of the Service.
              </li>

              <br />
              <li>
                You agree to pay all contractual royalty requirements to
                intellectual property owners.
              </li>

              <br />
              <li>
                We may, but have no obligation to, remove Content and Accounts
                containing Content that we determine in our sole discretion are
                unlawful, offensive, threatening, libelous, defamatory,
                pornographic, obscene or otherwise objectionable or violates any
                party's intellectual property or these Terms of Service.
              </li>

              <br />
              <li>
                Verbal, physical, written or other abuse (including threats of
                abuse or retribution) of any Rast·r customer, employee, member,
                or officer will result in immediate account termination and
                reporting to the proper authorities.
              </li>

              <br />
              <li>
                You understand that the technical processing and transmission of
                the Service, including your Content, may be transfered
                unencrypted and involve (a) transmissions over various networks;
                and (b) changes to conform and adapt to technical requirements
                of connecting networks or devices.
              </li>
              <br />
              <li>
                You must not upload, post, host, or transmit unsolicited email,
                SMSs, or "spam" messages.
              </li>
              <br />
              <li>
                You must not transmit any worms or viruses or any code of a
                destructive nature.
              </li>
              <br />
              <li>
                If your bandwidth usage significantly exceeds the average
                bandwidth usage (as determined solely by Rast·r) of other Rast·r
                customers, we reserve the right to immediately disable your
                account or throttle your file hosting until you can reduce your
                bandwidth consumption.
              </li>
              <br />
              <li>
                Rast·r does not warrant that (i) the service will meet your
                specific requirements, (ii) the service will be uninterrupted,
                timely, secure, or error-free, (iii) the results that may be
                obtained from the use of the service will be accurate or
                reliable, (iv) the quality of any products, services,
                information, or other material purchased or obtained by you
                through the service will meet your expectations, and (v) any
                errors in the Service will be corrected.
              </li>
              <br />
              <li>
                You expressly understand and agree that Rast·r shall not be
                liable for any direct, indirect, incidental, special,
                consequential or exemplary damages, including but not limited
                to, damages for loss of profits, goodwill, use, data or other
                intangible losses (even if Rast·r has been advised of the
                possibility of such damages), resulting from: (i) the use or the
                inability to use the service; (ii) the cost of procurement of
                substitute goods and services resulting from any goods, data,
                information or services purchased or obtained or messages
                received or transactions entered into through or from the
                service; (iii) unauthorized access to or alteration of your
                transmissions or data; (iv) statements or conduct of any third
                party on the service; (v) or any other matter relating to the
                service.
              </li>
              <br />
              <li>
                The failure of Rast·r to exercise or enforce any right or
                provision of the Terms of Service shall not constitute a waiver
                of such right or provision. The Terms of Service constitutes the
                entire agreement between you and Rast·r and govern your use of
                the Service, superseding any prior agreements between you and
                Rast·r (including, but not limited to, any prior versions of the
                Terms of Service). You agree that these Terms of Service and
                Your use of the Service are governed under California law.
              </li>
            </ol>
          </p>
          <div>
            <p
              style={{
                color: "black",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              Questions about the Terms of Service should be sent to
              <a href="mailo:support@rastr.io" style={{ color: "blue" }}>
                {" "}
                support@rastr.io.
              </a>
            </p>
          </div>
          <div>
            <p
              style={{
                color: "black",
                fontFamily: "Arial, Helvetica, sans-serif",
              }}
            >
              This Terms of Service Statement was last updated effective on
              September 1, 2022.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsOfUse;
