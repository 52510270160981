import styled from 'styled-components'

export const TabContainer = styled.div`
    display: flex;
    background: #fff;
    margin: 10px 0;
    border-radius: 10px;
    -webkit-tap-highlight-color: transparent;
    p{
        font-size: 0.9rem;
        color:#6A6A6A;
    }
    input{
        padding: 5px;
        border: 1px solid #CACACA;
box-sizing: border-box;
border-radius: 4px;

    }
    input::placeholder{
        font-size: 0.8rem;
    }
    @media screen and (max-width: 760px){
        flex-direction: column;
    }
    input[type='radio']:after {
        width: 17px;
        height: 17px;
        border-radius: 25px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

    input[type='radio']:checked:after {
        background-color: #f2994a;
    }
    
    select{
        border: 1px solid #CACACA;
        background-color: #fff;
        font-size: 0.9rem;
        padding: 10px 10px;
        color: #6A6A6A;
        border-radius: 4px;
    }
`
export const TabHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 23%;
    height:fit-content;
    cursor: pointer;
    flex-wrap: nowrap;
    background: #F4F4F4;
    border-radius: 10px;
    @media screen and (max-width: 760px){
    flex-direction: row;
    overflow-x:scroll;
    -webkit-tap-highlight-color: transparent;
    ::-webkit-scrollbar {
        background: #000;
        height: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: hsl(235, 21%, 11%);
        border-radius: 10px;
      }
      ::-webkit-scrollbar-track {
        background-color: hsla(235, 21%, 11%, 0.322);
        box-shadow: 0 0 3px hsl(235, 21%, 11%);
        border-radius: 10px;
        
      }
    }

`
export const TabTitle = styled.div`
    color: #6A6A6A;
    font-size: 1rem;
    font-weight: 600;
    padding: 1.5rem;
    background: ${({ show }) => (show ? 'rgba(255,255,255,0.1)' : '')};
    background-color: ${({ show }) => (show ? '#DBDBDB' : 'rgba(255,255,255,0.2)')};
    border-radius: 10px;
    transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    display: inline;
    -webkit-tap-highlight-color: transparent;
    min-width: 120px;
    p{
        font-size: 0.7rem;
        color: #585858;
    }
    &:hover{
        background: #DBDBDB;
        

    }
    @media screen and (max-width: 760px){
        border-left: 0;
        border-bottom: 2px solid ${({ show }) => (show ? '#00e963' : 'rgba(255,255,255,0.2)')};
    }

`
export const tab = styled.div`
        
`

export const TabContent = styled.div`
display: ${({ show }) => (show ? 'block' : 'none')};
background-color: rgba(255,255,255, 0.1);
    padding: 1rem 2rem;
    color: #000;
    width: 77%;
    @media screen and (max-width: 760px){
        width: 100%;
        padding: 2rem 1rem;
    }
`
export const Heading = styled.h3`
    color: #6A6A6A;
    font-size: 20px;
`
export const Date = styled.p`
    color: #00c9d3;
    font-size: 0.9rem;
    padding: 0.8rem 0;
`
export const Body = styled.div`
    line-height: 1.6rem;
    text-wrap: no-wrap;
    padding: 0.5rem 0;
    input{
        font-size: 14px;
    }
    input[type="text"]
    {
    font-size:12px;
    }
    .horizontal-line{
        width: 100%; height: 20px; border-bottom: 1px solid grey; text-align: center;margin-top:3px;
    }
    .horizontal-line span{
        position: absolute;margin-top:5px;right: 61%;font-size: 13px; background-color: #FFF;padding: 0 10px;
    }
    .form-group{
        margin-bottom: 0.2rem;
    }
    h4,h5{
        color: #6A6A6A;
    }
    p{
        margin-bottom: 5px;
    }
    label {
        display: inline;
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 43px;
        height: 15px;
        margin-bottom: 5px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: grey;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 8px;
        width: 8px;
        left: 4px;
        bottom: 3.55px;
        background-color: white;
        -webkit-transition: .4s;
        transition: 0.5s;
      }
      
      input:checked + .slider {
        background-color: #F0A84A;
        ;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
      .seedPhrase p, .confirmSeedPhrase p{
        background-color: #BABABA;
        padding: 10px;
        border-radius: 10px;
        margin: 5px;
        color: #fff !important;
        display: inline-block;
      }
      p .block{
          background-color: #F2994A;
          display: inline-block;

      }
    
`
export const Arrow = styled.p`
    padding-right: 1rem;
`
export const Logo = styled.img`

    width: ${({ bigWidth }) => (bigWidth ? '150px' : '100px')} ;

`