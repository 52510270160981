import React, { useEffect, useState } from "react";
import MainLayout from "../../Components/MainLayout";
import About from "../../Assets/Icons/Settings/about.png";
import Billing from "../../Assets/Icons/Settings/billing.png";
import Security from "../../Assets/Icons/Settings/security.png";
import Appearance from "../../Assets/Icons/Settings/appearance.png";

import PaypalLogin from "../../Components/ui/Paypal/paypalPayouts";

import {
  TabContainer,
  TabTitle,
  TabHeader,
  TabContent,
  Heading,
  Body,
} from "./TabElements";
import Footer from "../../Components/shared/footer";
import Axios from "axios";
import { toast } from "react-toastify";
const Setting = () => {

  const [toggleState, setToggleState] = useState(1);
  const toggle = (index) => {
    setToggleState(index);
  };
  const [crumbs, setCrumbs] = useState([{ name: "Home", route: "/overview" }]);
  const [payment, setPayment] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState({
    to: "",
    hasPaypal: false,
    hasETH: false,
  });

  const getPaymentDetails = () => {
    Axios.post("/getPaymentDetails", {
      _id: JSON.parse(localStorage.getItem("user"))._id,
    })
      .then((res) => {
        if (res.data.error) {
          toast.warn(res.data.reason);
        } else {
          setPaymentDetails({ ...res.data });
          if (res.data.to === "paypal") setPayment(1);
          else if (res.data.to === "ETH") setPayment(2);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.warn(
          "There was some problem with our server, please reload and try again"
        );
      });
  };

  const handleUpdate = () => {
    let type = payment;

    Axios.post("/updatePayment", {
      payment: type,
      _id: JSON.parse(localStorage.getItem("user"))._id,
    });
  };

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <MainLayout pageTitle="Settings" crumbs={crumbs} setCrumbs={setCrumbs}>
      <div className="container" style={{ minHeight: "70vh" }}>
        <TabContainer>
          <TabHeader>
            <TabTitle
              show={toggleState === 1 ? true : false}
              onClick={() => toggle(1)}
            >
              <div className="row">
                <div className="col-2">
                  <img src={About} alt="about" />
                </div>
                <div className="col">
                  About
                  <p>Read about the system</p>
                </div>
              </div>
            </TabTitle>
            <TabTitle
              show={toggleState === 2 ? true : false}
              onClick={() => toggle(2)}
            >
              <div className="row">
                <div className="col-2">
                  <img src={Security} alt="about" />
                </div>
                <div className="col">
                  Security
                  <p>Choose what will be shown and privacy settings</p>
                </div>
              </div>
            </TabTitle>
            <TabTitle
              show={toggleState === 3 ? true : false}
              onClick={() => toggle(3)}
            >
              <div className="row">
                <div className="col-2">
                  <img src={Appearance} alt="about" />
                </div>
                <div className="col">
                  Appearance
                  <p>Change the color scheme</p>
                </div>
              </div>
            </TabTitle>
            <TabTitle
              show={toggleState === 4 ? true : false}
              onClick={() => toggle(4)}
            >
              <div className="row">
                <div className="col-2">
                  <img src={Billing} alt="about" />
                </div>
                <div className="col">
                  Billing
                  <p>Change the currency</p>
                </div>
              </div>
            </TabTitle>
          </TabHeader>

          <TabContent show={toggleState === 2 ? true : false}>
            <Heading>Security</Heading>
            <small>Choose what information to show</small>
            <Body>
              <div className="row d-flex justify-content-between">
                <div className="col-md-6">
                  <h4>Location Data</h4>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>
                      Available in the profile of my country
                    </p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>
                      Receive email when someone views
                      <br /> your profile
                    </p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>
                      Available in the profile of my country
                    </p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4>Notification</h4>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>
                      When someone buys one of my products
                    </p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>
                      Someone saves my product on the wish list
                    </p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <h4>Password</h4>
                  <div className="small">New password</div>
                  <input placeholder="Type in your new password"></input>
                  <div className="small">Confirm new password</div>
                  <input placeholder="Type in your new password"></input>
                  <div className="d-flex justify-content-center mr-5">
                    <div className="btn-primary btn mt-3 text-center mr-5">
                      <small>Change password</small>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4>Privacy Policy</h4>
                  <p>
                    To read our privacy policies <a href="/temp">click here</a>
                  </p>
                </div>
              </div>
            </Body>
          </TabContent>
          <TabContent show={toggleState === 3 ? true : false}>
            <Heading>Appearance</Heading>
            <small>Change the color scheme</small>
            <Body>
              <div className="row d-flex justify-content-between">
                <div className="col-md-6">
                  <h4>Color scheme</h4>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>Dark mode or white mode</p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ display: "inline" }}>Menu slide</p>
                    <label className="switch ml-3">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <h4>Date and Time</h4>
                  <p>Select date and time format</p>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      May 2nd, 2021
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      defaultChecked
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      21-02-5
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      05/02/2021
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      02/05/2021
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <p>Select the time format</p>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                    />
                    <label className="form-check-label" for="flexRadioDefault1">
                      24 hour format
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      defaultChecked
                    />
                    <label className="form-check-label" for="flexRadioDefault2">
                      12 hour AM/PM format
                    </label>
                  </div>
                  <p className="mt-3">Choose the timezone</p>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>New York, United States</option>
                    <option value="1">California, United States</option>
                    <option value="2">California, United States</option>
                    <option value="3">California, United States</option>
                  </select>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <div className="btn-primary btn mt-3 text-center mr-2">
                  <small>Save changes</small>
                </div>
              </div>
            </Body>
          </TabContent>
          <TabContent show={toggleState === 4 ? true : false}>
            <Heading>Billing</Heading>
            <small>Change the currency</small>
            <Body>
              <div className="row d-flex justify-content-between">
                <div className="col-md-12">
                  <h4>Select how you want to receive payment</h4>
                  <p>Select the better option</p>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={payment === 1 ? true : false}
                          onChange={() => setPayment(1)}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Paypal
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={payment === 2 ? true : false}
                          onChange={() => setPayment(2)}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          ETH
                        </label>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        width: "100%",
                      }}
                    >
                      {/* <PaypalLogin />  PaypalPayouts.js line 23*/}
                    </div>
                  </div>
                </div>
              </div>
              {/* <p className="mt-3">Choose the currency</p>
              <select
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>New York, United States</option>
                <option value="1">California, United States</option>
                <option value="2">California, United States</option>
                <option value="3">California, United States</option>
              </select> */}

              <div className="d-flex justify-content-end">
                <div className="btn-primary btn mt-3 text-center mr-2">
                  <small>Update</small>
                </div>
              </div>
            </Body>
          </TabContent>
        </TabContainer>
      </div>
      <Footer />
    </MainLayout>
  );
};

export default Setting;
