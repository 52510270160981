import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { contractReducer } from "./reducers/contractReducer";
import { notificationReducer } from "./reducers/notification";

const reducer = combineReducers({
  contract: contractReducer,
  notify: notificationReducer,
});

const middleware = [thunk];
const initialState = {};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
