import React from "react";
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import ReduxStore from "./Redux/Store";

const App = () => {
  return (
    <Provider store={ReduxStore}>
      <AppRouter />
    </Provider>
  );
};

export default App;
