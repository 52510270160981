import React from "react";
import { NavLink } from "react-router-dom";

const Card = ({
  title,
  subtitle,
  detailsTitle,
  detailsLink,
  removePadding,
  children,
}) => {
  return (
    <div className="card">
      <div
        className="card-header"
        style={{
          backgroundColor: "white",
          borderBottom: "none",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="card-text">
          {title && <h5 className="card-title"> {title} </h5>}
          {subtitle && (
            <h6
              className="card-subtitle text-muted font-weight-light"
              style={{ fontSize: "75%" }}
            >
              {subtitle}
            </h6>
          )}
        </div>
        {detailsTitle && (
          <div className="card-view-details">
            <NavLink to={detailsLink}>{detailsTitle}</NavLink>
          </div>
        )}
      </div>
      <div className={removePadding ? "card-body p-0" : "card-body py-0"}>
        {children}
      </div>
    </div>
  );
};

export default Card;
