import React from "react";

const footer = () => {
  return (
    <>
      <footer>
        <div className="container pt-3">
          <div className="text-center">
            <i className="fas fa-ellipsis-h"></i>
          </div>

          <div className="row text-center">
            <div className="col-md-4 box">
              <span className="copyright quick-links">
                Copyright &copy; Rast·r<sup>TM</sup> &nbsp;&nbsp;{" "}
                {new Date().getFullYear()}
              </span>
            </div>
            <div className="col-md-4 box">
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                     <a href="https://t.me/+xN15OmU7pIwwNjcx">
                  <i className="fab fa-telegram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                <a href="https://www.youtube.com/@rastrtechnologies">
                  <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                <a href="https://discord.gg/eGxbkbr">
                    <i className="fab fa-discord"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 box">
              <ul className="list-inline quick-links">
                <li className="list-inline-item">
                  <a href="http://localhost:3000/privacyPolicy">
                    Privacy Policy
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="http://localhost:3000/terms">Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
