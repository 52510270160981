import React from "react";
import { VectorMap } from "react-jvectormap";

const WorldMap = ( {mapData} ) => {

  const handleClick = (e, countryCode) => {
    console.log(countryCode);
  };

  const colorScheme = ["#fbe0c9", "#f2994a"];

  return (
    <VectorMap
      map={"world_mill"}
      backgroundColor="transparent"
      zoomOnScroll={false}
      containerStyle={{
        width: "100%",
        height: "420px",
      }}
      onRegionClick={handleClick}
      containerClassName="map"
      regionStyle={{
        initial: {
          fill: "#e4e4e4",
          "fill-opacity": 0.9,
          stroke: "none",
          "stroke-width": 0,
          "stroke-opacity": 0,
        },
        hover: {
          "fill-opacity": 0.8,
          cursor: "pointer",
        },
        selectedHover: {},
      }}
      regionsSelectable={false}
      series={{
        regions: [
          {
            values: mapData,
            scale: colorScheme,
            normalizeFunction: "polynomial",
          },
        ],
      }}
    />
  );
};

export default WorldMap;
