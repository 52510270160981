/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { getContract } from '../../Redux/actions/contractAction';
import LoadingSpinner from '../../Components/ui/LoadingSpinner';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import 'react-toastify/dist/ReactToastify.css';
import OTPpage from './OTPpage';
// Styles are in pre-login1

const serverBaseURL = '';
function PreLogin1() {
  const dispatch = useDispatch();
  let history = useHistory();

  useSelector((state) => state.contract);

  const [userDetails, setUserDetails] = useState({
    password: '',
    email: '',
    remember: false,
  });

  const [otpInfo, setOtpInfo] = useState({
    email: '',
    _id: '',
  });

  const [loader, setLoader] = useState(false);
  const [openOTPpage, setOpenOTPpage] = useState(false);

  useEffect(() => {
    setLoader(false);
  }, [openOTPpage]);

  // const [otp, setOtp] = useState();

  // const responseGoogle = (response) => {
  //   try {
  //     let userDetails = {
  //       password: response.profileObj.googleId,
  //       email: response.profileObj.email,
  //     };
  //     axios
  //       .post(serverBaseURL + `/login`, { userDetails })
  //       .then(async (res) => {
  //         if (res.error) {
  //           return new Error();
  //         }
  //         const userResponseDetails = {
  //           name: res.data.user.name,
  //           email: res.data.user.email,
  //           token: res.data.token,
  //           remember: userDetails.remember,
  //         };
  //         localStorage.setItem("token", res.data.token);
  //         localStorage.setItem("user", JSON.stringify(userResponseDetails));
  //         dispatch(getContract());
  //         // alert("Log-in Successful");
  //         setLoader(false);
  //         window.location.href = "/overview";
  //       })
  //       .catch((error) => {
  //         // alert("Login Failed, Please register using Google and try again");
  //         toast.warn(
  //           "Login Failed, Please register using Google and try again",
  //           {
  //             position: "top-right",
  //             autoClose: 3000,
  //             hideProgressBar: true,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           }
  //         );
  //         setLoader(false);
  //       });
  //   } catch (error) {
  //     // alert("Google Sign on Failed");
  //     toast.warn("Google Sign in Failed!", {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };
  const responseFacebook = (response) => {
    try {
      let userDetails = {
        password: response.id,
        email: response.email,
      };
      axios
        .post(serverBaseURL + `/login`, { userDetails })
        .then(async (res) => {
          if (res.error) {
            return new Error();
          }
          const userResponseDetails = {
            name: res.data.user.name,
            email: res.data.user.email,
            token: res.data.token,
            remember: userDetails.remember,
          };
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(userResponseDetails));
          dispatch(getContract());
          // alert("Log-in Successful");
          setLoader(false);
          window.location.href = '/overview';
        })
        .catch((error) => {
          // alert("Login Failed, Please register using Google and try again");
          toast.warn(
            'Login Failed, Please register using Google and try again',
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setLoader(false);
        });
    } catch (error) {
      // alert("Google Sign on Failed");
      toast.warn('Google Sign in Failed!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    await axios
      .post(`/login`, { userDetails })
      .then(async (res) => {
        console.log(res, 'res');
        if (res.data.error === 'Not verified') {
          setOpenOTPpage(true);
          setOtpInfo({ ...otpInfo, email: res.data.email, _id: res.data._id });
        } else {
          const userResponseDetails = {
            name: res.data.user.name,
            email: res.data.user.email,
            image: res.data.user.image,
            token: res.data.token,
            remember: userDetails.remember,
          };
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(userResponseDetails));
          localStorage.setItem('cosmosaddress', res.data.user.cosmosaddress);
          localStorage.setItem('countrycode', res.data.user.countryCode);
          localStorage.setItem('countryname', res.data.user.countryName);
          localStorage.setItem('newLogin', true);
          // history.push("/overview");
          window.location.pathname = '/overview';
        }
      })
      .catch((error) => {
        console.log(error);
        // alert("Incorrect Email and Password combination!!. Try again");
        toast.warn('Incorrect Email and Password combination!!. Try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
      });
  };

  const handleCallbackGoogle = (res) => {
    setLoader(true);
    console.log(res.credential);
    console.log(JSON.stringify(res));
    axios
      .post(serverBaseURL + '/googleLogin', {
        credential: res.credential,
      })
      .then((res2) => {
        const userResponseDetails = {
          name: res2.data.user.name,
          email: res2.data.user.email,
          image: res2.data.user.image,
          token: res2.data.token,
          remember: userDetails.remember,
        };
        localStorage.setItem('token', res2.data.token);
        localStorage.setItem('user', JSON.stringify(userResponseDetails));
        localStorage.setItem('cosmosaddress', res2.data.user.cosmosaddress);
        localStorage.setItem('countrycode', res2.data.user.countryCode);
        localStorage.setItem('countryname', res2.data.user.countryName);

        // history.push("/overview");
        window.location.pathname = '/overview';
      })
      .catch((error) => {
        console.log(error);
        toast.warn('Login failed, Try again', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
      });
  };

  // const verifiOTP = () => {
  //   if (otp.length === 6) {
  //     axios
  //       .post(`${serverBaseURL}/verifyOTP`, {
  //         userID: otpInfo._id,
  //         OTP: otp,
  //       })
  //       .then((res) => {
  //         if (!res.data.error) {
  //           window.location.pathname = "/";
  //         } else {
  //           toast.warn(res.data.reason);
  //         }
  //       });
  //   }
  // };

  // useEffect(() => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id:
  //       "934470204882-p850214g6njj8l4qdi4iqqoa8838ds7p.apps.googleusercontent.com",
  //     callback: (res) => handleCallbackGoogle(res),
  //   });
  //   // google.accounts.id.renderButton(document.getElementById("googleButton"), {
  //   //   theme: "dark",
  //   //   size: "large",
  //   // });
  //   // google.accounts.id.prompt();
  // }, []);

  return (
    <div className="container-fluid pre-login-home">
      <div className="row pre-login-card">
        <ToastContainer />
        <div className="col-md-7 img-left d-flex">
          <span className="m-auto text-center">
            {/* <h6
              style={{
                position: "absolute",
                color: "#fff",
                top: "55%",
                right: "20%",
                fontWeight: "700",
              }}
            >
              TM
            </h6> */}
            <img
              style={{ width: '350px' }}
              alt="HeroLedger Logo"
              className="img-fluid"
              src={require('../../Assets/updated/HeroledgerLogoEye.png')}
            />
            <br />

            <h1
              style={{ position: 'relative' }}
              className="tag-line  text-white pb-4 font-weight-bold "
            >
              Rast·r<sup>TM</sup> &nbsp;APPLICATION
            </h1>
          </span>
          <div className="d-flex align-self-end justify-content-start version-text mb-2 text-white">
            Version Beta
          </div>
        </div>
        {/* <div className="col-md-1">
        </div> */}
        <div className="col-md-5 card-back flex-row mx-auto px-0 d-flex align-items-center">
          {openOTPpage ? (
            <OTPpage
              // otp={otp}
              // setOtp={setOtp}
              // time={time}
              // setTime={setTime}
              // verifiOTP={verifiOTP}
              changeEmail={setOpenOTPpage}
              email={otpInfo.email}
              _id={otpInfo._id}
            />
          ) : (
            <LoginPage
              handleSubmit={handleSubmit}
              setUserDetails={setUserDetails}
              userDetails={userDetails}
              loader={loader}
              responseFacebook={responseFacebook}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const LoginPage = ({
  handleSubmit,
  setUserDetails,
  userDetails,
  responseFacebook,
  loader,
}) => {
  return (
    <>
      <div className="pre-login-card-body card-body">
        <h4 className="title text-center mt-2 font-weight-bold">
          <span style={{ color: '#ef992f' }}>Login</span> to Heroledger
        </h4>
        <form className="pre-login-form-box px-3" onSubmit={handleSubmit}>
          <div className="form-input">
            <span>
              <i className="fa fa-envelope-o"></i>
            </span>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              onChange={(e) =>
                setUserDetails({ ...userDetails, email: e.target.value })
              }
            />
          </div>
          <div className="form-input">
            <span>
              <i className="fa fa-key"></i>
            </span>
            <input
              type="password"
              name="password"
              placeholder="Password"
              required
              onChange={(e) =>
                setUserDetails({ ...userDetails, password: e.target.value })
              }
            />
          </div>
          <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="cb1"
                name="remember"
                checked={userDetails.remember}
                onChange={() =>
                  setUserDetails({
                    ...userDetails,
                    remember: !userDetails.remember,
                  })
                }
              />
              <label
                className="custom-control-label font-12 text-white"
                htmlFor="cb1"
              >
                Remember me
              </label>
            </div>
          </div>
          <div className="mb-3">
            <button type="submit" className="btn btn-block text-uppercase">
              {loader ? (
                <LoadingSpinner />
              ) : (
                <>
                  <i className="fas fa-sign-in-alt"></i> Login
                </>
              )}
            </button>
          </div>
          <div className="text-right font-12">
            <button
              onClick={() => {
                window.location.pathname = '/recovery';
              }}
              href="#"
              className="forget-link btn btn-default btn-sm"
            >
              <small className="font-weight-bold">Forgot Password?</small>
            </button>
          </div>

          <hr className="my-2" />
          <div className="text-center font-12 mb-1 font-weight-bold text-white">
            Don't have an account?{' '}
            <Link to="/register" className="text-primary font-12">
              Register here
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default PreLogin1;
