/* eslint-disable react-hooks/exhaustive-deps */
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MainLayout from '../../Components/MainLayout';
import LoadingSpinner from '../../Components/ui/LoadingSpinner';
import Heroledger from '../../blockchain/abis/heroledger.json';
import Web3 from 'web3';
import OverrideAudio from '../../Assets/Overrides/override-audio.png';
import OverrideVideo from '../../Assets/Overrides/override-video.png';
import OverrideScript from '../../Assets/Overrides/override-script.png';
import StoreItemCard from '../../Components/ui/StoreItemCard';
import { Tab, Tabs } from 'react-bootstrap';
import { purchaseProduct } from '../../Redux/actions/purchaseProductAction';
import Payment from '../../Pages/Home/Payment';
import { useDispatch, useSelector } from 'react-redux';
import { getContract } from '../../Redux/actions/contractAction';
import { licenseProduct } from '../../Redux/actions/licenseProductAction';
import { useHistory } from 'react-router-dom';

const serverBaseURL = '';
const endpoints = {
  character: '/getCharacterAssets',
  script: '/getScriptAssets',
  logo: '/getLogoAssets',
  background: '/getBackgroundAssets',
  audio: '/getAudioAssets',
  video: '/getVideoAssets',
  prop: '/getPropsAssets',
  props: '/getPropsAssets',
};

const ProductDetailPage = () => {
  const [similarProducts, setSimilarProducts] = useState([]);
  const [productInfos, setProductInfos] = useState(undefined);
  const [loader, setLoader] = useState(true);
  const [check, setCheck] = useState(false);
  const [contract, setContract] = useState({});
  let history = useHistory();

  useEffect(() => {
    loadContract();
  }, []);

  useEffect(() => {
    console.log(contract, 'contract');
  }, [contract]);

  const loadContract = async () => {
    window.web3 = new Web3(
      new Web3.providers.HttpProvider('https://comicchain.io')
    );
    const web3 = window.web3;
    const networkId = await web3.eth.net.getId();
    const networkData = Heroledger.networks[networkId];
    if (networkData) {
      const heroledger = await new web3.eth.Contract(
        Heroledger.abi,
        networkData.address
      );
      setContract(heroledger);
    }
  };

  let { productId } = useParams();

  const loadProducts = (productType) => {
    Axios.get(endpoints[productType]).then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);

      setSimilarProducts(resData.slice(0, 3));
    });
  };
  const productTypeMap = {
    script: 'Scripts',
    logo: 'Logos',
    background: 'Backgrounds',
    audio: 'Audios',
    video: 'Videos',
    props: 'Props',
    character: 'Characters',
  };
  const loadProductInfos = () => {
    Axios.get(serverBaseURL + `/getSingleProduct?productId=${productId}`).then(
      (res) => {
        setCrumbs(
          crumbs.concat([
            {
              name: productTypeMap[res.data.data[0].productType],
              route: `/store/category/${
                productTypeMap[res.data.data[0].productType]
              }`,
            },
            {
              name: res.data.data[0].productName,
              route: `/store/product/${res.data.data[0].productId}`,
            },
          ])
        );
        setProductInfos(res.data.data[0]);
        if (!res.data.data[0].InStore) {
          history.push('/store');
        }
        loadProducts(res.data.data[0].productType);
        setLoader(false);
      }
    );
  };

  const handleBuyAction = () => {
    console.log('----------=------------');
    console.log(contract, 'contract in handle Buy Action');
    purchaseProduct(contract, productInfos);
  };

  const handleLicenseAction = () => {
    console.log('Contract', contract);
    licenseProduct(contract, productInfos);
  };

  useEffect(() => {
    setLoader(true);
    loadProductInfos();
  }, [productId]);

  const [crumbs, setCrumbs] = useState([
    { name: 'Home', route: '/overview' },
    { name: 'Store', route: '/store' },
  ]);

  return (
    <MainLayout
      pageTitle={
        productInfos && productInfos.productName
          ? 'Product Details: ' + productInfos.productName
          : 'Product Details'
      }
      crumbs={crumbs}
      setCrumbs={setCrumbs}
    >
      <div className="container">
        {loader && <LoadingSpinner />}
        {loader || (
          <div className="row">
            <div className="col-md-9 col-sm-12 row  py-2">
              <div className="col-md-5 col-sm-6 col-xs-12">
                <StoreItemCard
                  item={productInfos}
                  crumbs={crumbs}
                  setCrumbs={setCrumbs}
                  hideFooter={true}
                  overrideItemsImage={
                    productInfos.productType.toLowerCase() === 'audio'
                      ? OverrideAudio
                      : productInfos.productType.toLowerCase() === 'video'
                      ? OverrideVideo
                      : productInfos.productType.toLowerCase() === 'script'
                      ? OverrideScript
                      : false
                  }
                />
              </div>
              {/* <div className="col-md-1 d-none d-md-block d-lg-block"></div> */}
              <div className="col-md-7 col-sm-6 col-xs-12 py-2 product-details">
                <div className="product-details__ratings text-muted">
                  <i className="far fa-star"></i> 4.5 (23 Reviews)
                </div>
                <div className="product-details__title h1 py-2">
                  {productInfos.productName}
                </div>
                <div className="product-details__owner font-weight-bold py-2">
                  Asset owner contact: {productInfos.ownerEmail}
                </div>
                <div className="product-details__categories py-2">
                  <label className="text-muted py-2 px-4">
                    {productInfos.productType}
                  </label>
                </div>
                <div className="product-details__price pb-2">
                  Purchase Price ${productInfos.priceinUsd}
                </div>
                <div className="product-details__actions">
                  <button
                    className="btn btn-primary w-50 mb-2"
                    // onClick={handleBuyAction}
                    data-toggle="modal"
                    data-target="#paymentPopup"
                  >
                    Purchase
                  </button>
                </div>
                {productInfos.license && (
                  <>
                    <div className="product-details__price pb-2">
                      License Price ${productInfos.licenseFeeUsd}
                    </div>
                    <div className="product-details__actions">
                      <button
                        className="btn btn-primary w-50"
                        data-toggle="modal"
                        data-target="#licensing-terms"
                      >
                        <i title="License" className="far fa-clone"></i> License
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="col-12 product-details__description">
                <Tabs defaultActiveKey="description">
                  <Tab eventKey="description" title="Description">
                    {productInfos.fullDescription && (
                      <p className="font-weigth-bold text-dark pt-2">
                        {productInfos.fullDescription}
                      </p>
                    )}
                    <hr />
                    <div className="product-details__owner font-weight-bold">
                      License
                    </div>
                    {productInfos.licenseDescription && (
                      <p className="font-weigth-bold text-dark">
                        {productInfos.licenseDescription}
                      </p>
                    )}
                  </Tab>
                  <Tab eventKey="reviews" title="Reviews">
                    <p>reviews</p>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 py-2">
              <p className="font-weight-bold h4 text-dark">Similar Products</p>
              {similarProducts.map((product) => (
                <div className="pb-2" key={product.blockHash}>
                  <StoreItemCard
                    item={product}
                    hideFooter={false}
                    overrideItemsImage={
                      product.productType.toLowerCase() === 'audio'
                        ? OverrideAudio
                        : product.productType.toLowerCase() === 'video'
                        ? OverrideVideo
                        : product.productType.toLowerCase() === 'script'
                        ? OverrideScript
                        : false
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* Modal-licensing terms */}
      {/* <div className="modal licensing-modal" id="licensing-terms">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title ">LICENSING AGREEMENT</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Asset Name:{" "}
                <span className="font-weight-bold text-dark">
                  {productInfos?.productName}
                </span>
              </p>
              <p>
                Hash:
                <span className="font-weight-bold text-dark">
                  {productInfos?.transactionHash}
                </span>
              </p>
              <div className="watermark">SAMPLE</div>
              <p>
                This Artist Licensing Agreement (the “AGREEMENT”) is entered
                into effective this date, (date added here) between LICENSOR
                NAME (“ARTIST”) and LICENSEE (“CLIENT”).
              </p>
              <p>
                <b>Scope of this Agreement.</b> This Agreement applies to any
                image, graphics, digital assets, or digital images created or
                taken by Artist and delivered to the Client (collectively known
                as “IMAGES”). This Agreement governs the relationship between
                the parties and in no communication or other exchange, shall
                modify the terms of this Agreement unless agreed to in writing.
              </p>
              <p>
                <b>Rights:</b> All Images and rights relating to them, including
                copyright and ownership rights in the media in which the Images
                are stored, remain the sole and exclusive property of the
                Artist. This license provides the Client with the limited right
                to reproduce, publicly display, and distribute the Images only
                for the agreed upon terms as set forth in the Client Invoice and
                signed by both parties. Images used for any purpose not directly
                related outside of those terms must be with the express
                permission of Artist and may include the payment of additional
                fees, unless otherwise agreed to in writing. Images may contain
                copyright management information (CMI) at the discretion of the
                Artist in the form of either 1) a copyright notice © and/or 2)
                other copyright and ownership information embedded in the
                metadata or elsewhere unless otherwise agreed to by the Parties.
                Removing and/or altering such information is prohibited and
                constitutes a violation of the Digital Millennium Copyright Act
                (DMCA) and Client will be responsible to the Artist for any
                penalties and awards available under that statute.
              </p>{" "}
              <p>
                {" "}
                <b>Relationship of the Parties:</b> The parties agree that
                Artist is an independent contractor and that neither Artist nor
                Artist’s employees or contract personnel are, or shall be deemed
                to be, employees of Client. No agency, partnership, joint
                venture, or employee-employer relationship is intended or
                created by this Agreement. Neither party is authorized to act as
                agent or bind the other party except as expressly stated in this
                Agreement. Artist and the Images or any other deliverables
                prepared by Artist shall not be deemed a work for hire as
                defined under Copyright Law. All rights granted to Client are
                contractual in nature and are expressly defined by this
                Agreement.
              </p>
              <p>
                <b>Creation:</b> The manner and method of creating any Image is
                solely at the discretion of Artist and the Client has no right
                to control Artist’s manner and method of performance under this
                Agreement. Artist will use his/her best efforts to: (a) ensure
                that the Images conform to Client’s specifications; and (b)
                submit all Images to Client in publishable quality, on or before
                the applicable deadlines.
              </p>
              <p>
                <b>Delivery:</b> Artist may select delivery of designs in PDF,
                JPEG, PNG, or other standard formats at a resolution that Artist
                determines will be suitable for the Images as licensed. It is
                the Client’s responsibility to verify that the Images are
                suitable for reproduction and that if the Images are not deemed
                suitable, to notify the Artist within five (5) business days.
                Artist’s sole obligation will be to replace the Images at a
                suitable resolution but in no event will Artist be liable for
                poor reproduction quality, delays, or consequential damages.
              </p>
            </div>
            <div className="modal-footer">
              <div className="mr-auto">
                <input
                  type="checkbox"
                  value={check}
                  onChange={() => setCheck(!check)}
                />{" "}
                {"    "} &nbsp;I have read and accept the terms and information
                contained in this license.
              </div>
              <button
                type="button"
                className="btn btn-success"
                disabled={!check}
                onClick={handleLicenseAction}
              >
                Proceed to purchase
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}

      {/* PaymentPopup */}
      <div
        className="modal fade bd-example-modal-xl"
        id="paymentPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Payment Options
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Payment
                handleBuyAction={handleBuyAction}
                price={productInfos?.priceinUsd}
                productId={productId}
              />
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div> */}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ProductDetailPage;
