import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
const paypal = window.paypal;
// const AACComponent = paypal.PayoutsAAC.driver("react", {
//   React,
//   ReactDOM,
// });
const serverBaseURL = '';
const PaypalLogin = () => {
  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem('user'))._id);
    const query = new URLSearchParams(window.location.search);
    if (query.has('code')) {
      console.log(query.get('code'));
      axios.post(serverBaseURL + '/authenticatePaypal', {
        code: query.get('code'),
        _id: JSON.parse(localStorage.getItem('user'))._id,
      });
    }

    paypal.use(['login'], function (login) {
      login.render({
        appid:
          'AfzKRNnWr2MbGi07v9iRqYoUwCJg5PJbwMgj00gK7_oT-Zx86zx5nuZ_7BAh1J35cT-uwUxFkT9a8jYi', //client-id
        authend: 'sandbox',
        scopes: 'openid email https://uri.paypal.com/services/paypalattributes',
        containerid: 'paypalContainer',
        responseType: 'code',
        locale: 'en-us',
        buttonType: 'CWP',
        buttonShape: 'rectangle',
        buttonSize: 'sm',
        fullPage: 'false',
        returnurl: 'http://206.189.232.171:3000/assets',
      });
    });
  }, []);

  return (
    <>
      <div id="paypalContainer"></div>
    </>
  );
};

export default PaypalLogin;
