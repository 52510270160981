/* eslint-disable react-hooks/exhaustive-deps */
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MainLayout from '../../Components/MainLayout';
import LoadingSpinner from '../../Components/ui/LoadingSpinner';
import StoreItemCard from '../../Components/ui/StoreItemCard';
import DummyCard from '../../Components/ui/DummyCard';
import OverrideAudio from '../../Assets/Overrides/override-audio.png';
import OverrideVideo from '../../Assets/Overrides/override-video.png';
import OverrideScript from '../../Assets/Overrides/override-script.png';

const endpoints = {
  Characters: '/getCharacterAssets',
  Scripts: '/getScriptAssets',
  Logos: '/getLogoAssets',
  Backgrounds: '/getBackgroundAssets',
  Audios: '/getAudioAssets',
  Videos: '/getVideoAssets',
  Props: '/getPropsAssets',
};

const StoreCategoryPage = () => {
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);

  let { categoryName, searchParams } = useParams();

  const loadProducts = () => {
    Axios.get(endpoints[categoryName]).then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);

      setProducts(resData);
      setLoader(false);
    });
  };

  const runSearch = async () => {
    let toBeSearched = [];

    await Axios.get('/getCharacterAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });
    await Axios.get('/getScriptAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });
    await Axios.get('/getLogoAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });
    await Axios.get('/getBackgroundAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });
    await Axios.get('/getAudioAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });
    await Axios.get('/getVideoAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });
    await Axios.get('/getPropsAssets').then((res) => {
      let resData = res.data.data
        .filter((ele) => ele.InStore === true && ele.priceinUsd > 0)
        .map((ele) => ele);
      toBeSearched = [...toBeSearched, ...resData];
    });

    // eslint-disable-next-line array-callback-return
    const results = toBeSearched.filter((item) => {
      if (
        (item.description &&
          item.description
            .toLowerCase()
            .includes(searchParams.toLowerCase())) ||
        (item.fullDescription &&
          item.fullDescription
            .toLowerCase()
            .includes(searchParams.toLowerCase())) ||
        (item.productName &&
          item.productName
            .toLowerCase()
            .includes(searchParams.toLowerCase())) ||
        (item.productType &&
          item.productType.toLowerCase().includes(searchParams.toLowerCase()))
      ) {
        return item;
      }
    });

    setProducts(results);
    setLoader(false);
  };

  useEffect(() => {
    setLoader(true);
    categoryName && loadProducts();
    searchParams && runSearch();
  }, [categoryName, searchParams]);
  const [crumbs, setCrumbs] = useState([
    { name: 'Home', route: '/overview' },
    { name: 'Store', route: '/store' },
  ]);

  return (
    <MainLayout
      pageTitle={
        categoryName ? categoryName : `Search results for: ${searchParams}`
      }
      crumbs={crumbs}
      setCrumbs={setCrumbs}
    >
      {loader ? (
        <LoadingSpinner />
      ) : products && products.length > 0 ? (
        <div className="row p-4">
          {products.map((product) => (
            <div key={product._id} className="col-md-3 col-sm-6 col-xs-12 py-2">
              <StoreItemCard
                item={product}
                hideFooter={false}
                overrideItemsImage={
                  product.productType.toLowerCase() === 'audio'
                    ? OverrideAudio
                    : product.productType.toLowerCase() === 'video'
                    ? OverrideVideo
                    : product.productType.toLowerCase() === 'script'
                    ? OverrideScript
                    : false
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <div>
          <div className="d-flex">
            <DummyCard />
          </div>
          <div className="d-flex">
            <DummyCard />
          </div>
          <div className="d-flex">
            <DummyCard />
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default StoreCategoryPage;
