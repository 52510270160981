export function getDate(timestamp) {
    const stamp = new Date(timestamp * 1000);
    const date = stamp.getDate();
    const month = stamp.getMonth() + 1;
    const year = stamp.getFullYear();
    const hours = stamp.getHours();
    const minutes = stamp.getMinutes();
    const seconds = stamp.getSeconds();

    const time = `${date <= 9 ? '0' + date : date}-${month <= 9 ? '0' + month : month
      }-${year} ${hours <= 9 ? '0' + hours : hours}:${minutes <= 9 ? '0' + minutes : minutes
      }:${seconds <= 9 ? '0' + seconds : seconds}`;
    return time;
  };