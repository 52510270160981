import React from 'react';
import { NavLink } from 'react-router-dom';
import LoadingSpinner from './LoadingSpinner';
import StoreItemCard from './StoreItemCard';
import DummyCard from './DummyCard';
const StoreCategory = ({
  title,
  items,
  backgroundImage,
  loader,
  overrideItemsImage,
}) => {
  return (
    <div
      className="row py-5"
      style={{
        // background: backgroundImage || null,
        backgroundBlendMode: 'multiply',
      }}
    >
      <div className="col-8 pb-4 font-weight-bold" style={{ fontSize: '34px' }}>
        {title}
      </div>
      <div
        className="col-4 pb-4 text-primary text-right"
        style={{
          textShadow: backgroundImage ? '0px 0px 5px rgba(0, 0, 0, 1)' : 'none',
        }}
      >
        <NavLink to={`/store/category/${title}`}>See More</NavLink>
      </div>
      {!loader ? (
        items.length === 0 ? (
          <DummyCard />
        ) : (
          items.slice(0, 4).map((item) => (
            <div key={item._id} className="col-lg-3 col-sm-6 col-xs-12 py-2">
              <StoreItemCard
                hideFooter={true}
                item={item}
                overrideItemsImage={overrideItemsImage}
              />
            </div>
          ))
        )
      ) : (
        <div className="col-12">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default StoreCategory;
// #1c1c1b
