const initialState = {};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 1:
      return { loading: true, products: [] };
    case 2:
      return { loading: false, state: action.payload };
    default:
      return state;
  }
};
