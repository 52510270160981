import React from 'react';
import { BrowserRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

const breadcrumb = {
  backgroundColor: 'white',
  borderRadius: '0.37rem',
  fontSize: '14px',
  color: '#fff',
  margin: '0',
  padding: '0 0 0.5rem 1rem',
  background: '#F7F8FC',
};

function Breadcrumb(props) {
  function isLast(index) {
    return index === props.crumbs.length - 1;
  }
  if (props && props.crumbs && props.crumbs.map) {
    if (props.isInStore) {
      breadcrumb.background = 'rgba(0,0,0,0)';
      breadcrumb.color = 'rgb(255,255,255)';
    }
    return (
      <div className="justify-content-center">
        <ol
          className="breadcrumb"
          style={{ ...breadcrumb, position: 'relative', left: '18px' }}
        >
          {props.crumbs.map((crumb, ci) => {
            const disabled = isLast(ci) ? 'disabled' : '';

            return (
              <li key={ci} className="breadcrumb-item align-items-center">
                <NavLink
                  style={
                    props.isInStore ? { color: 'white' } : { color: '#343a40' }
                  }
                  className={` btn-link ${disabled}`}
                  to={crumb.route}
                >
                  {crumb.name}
                </NavLink>
              </li>
            );
          })}
        </ol>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default Breadcrumb;
