import React from "react";
import MainLayout from "../../Components/MainLayout";

const Terms = () => {
  return (
    <>
      <div>
        <MainLayout
          removeSidebar={true}
          isInStore={false}
          isInTerms={true}
          pageTitle="Terms of Use"
        />
      </div>
    </>
  );
};
export default Terms;
