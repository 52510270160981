import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom';
import { getData } from 'country-list';
import { ToastContainer, toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import OTPpage from './OTPpage';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const serverBaseURL = '';
function Register() {
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    latitude: '',
    longitude: '',
    countryCode: '',
    countryName: '',
    state: '',
    phone: '',
  });
  const [countryList, setCountryList] = useState([]);
  const [whichPage, setWhichPage] = useState(0);
  const [acknowledged, setAcknowledged] = useState(false);
  const [tickedOne, setTickedOne] = useState(false);
  const checkIfTicked = (ticked) => {
    if (ticked.created || ticked.purchased || ticked.lisenced || ticked.gift)
      return true;
    return false;
  };
  const [ticked, setTicked] = useState({
    created: false,
    purchased: false,
    lisenced: false,
    gift: false,
  });
  const [time, setTime] = useState(120);
  const [otpPage, setOtpPage] = useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setCountryList(getData());
  }, []);
  useEffect(
    () => {
      setLoader(false);
    },
    { otpPage }
  );
  useEffect(() => {
    let check = checkIfTicked(ticked);
    setTickedOne(check);
  }, [ticked]);
  // useEffect(() => {
  //   const getLocation = async () => {
  //     // let ip = await publicIp.v4().then((res) => res);
  //     const ip = "179.225.157.216";
  //     // Used https://geolocation-db.com/dashboard# to get location by IP
  //     axios
  //       .get(
  //         `https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91/${ip}`
  //       )
  //       .then((res) => {
  //         setUserDetails({
  //           ...userDetails,
  //           latitude: res.data.latitude,
  //           longitude: res.data.longitude,
  //           countryCode: res.data.country_code,
  //           countryName: res.data.country_name,
  //           state: res.data.state,
  //         });
  //       });
  //   };

  //   getLocation();
  //   if ("geolocation" in navigator) {
  //     console.log("Available");
  //   } else {
  //     console.log("Not Available");
  //   }

  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     setUserDetails({
  //       ...userDetails,
  //       latitude: position.coords.latitude,
  //       longitude: position.coords.longitude,
  //     });
  //   });
  // });

  // const responseGoogle = (response) => {
  //   console.log(response);
  //   try {
  //     const registerDetails = {
  //       name:
  //         response.profileObj.givenName + " " + response.profileObj.familyName,
  //       email: response.profileObj.email,
  //       password: response.profileObj.googleId,
  //       latitude: userDetails.latitude,
  //       longitude: userDetails.longitude,
  //       countryCode: userDetails.countryCode,
  //       countryName: userDetails.countryName,
  //       state: userDetails.state,
  //       image: response.profileObj.imageUrl,
  //     };
  //     console.log(registerDetails);
  //     axios
  //       .post(serverBaseURL + `/signup`, { registerDetails })
  //       .then((res) => {
  //         // alert("Registration Successful, continue to login");
  //         toast.success("Registration Successful, continue to login", {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         setLoader(false);
  //         window.location.pathname = "/";
  //       })
  //       .catch((error) => {
  //         // alert("Registration Failed, Please try again");
  //         console.log(error, "Makabhisda");
  //         toast.warn(error, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         setLoader(false);
  //       });
  //   } catch (error) {
  //     // alert("Registration Failed");
  //     toast.warn("Registration Failed", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     setLoader(false);
  //   }
  // };
  const responseFacebook = (response) => {
    console.log(response);
    try {
      const registerDetails = {
        name: response.name,
        email: response.email,
        password: response.id,
        latitude: userDetails.latitude,
        longitude: userDetails.longitude,
        countryCode: userDetails.countryCode,
        countryName: userDetails.countryName,
        state: userDetails.state,
        image: response.picture.url,
      };
      axios
        .post(serverBaseURL + `/signup`, { registerDetails })
        .then((res) => {
          // alert("Registration Successful, continue to login");
          toast.success('Registration Successful, continue to login', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false);
          // window.location.pathname = "/";
        })
        .catch((error) => {
          // alert("Registration Failed, Please try again");
          console.log(error, 'Makabhisda');
          toast.warn(error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false);
        });
    } catch (error) {
      toast.warn('Registration Failed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoader(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!acknowledged) {
      toast.warn('Please acknowledge');
      return;
    }
    setLoader(true);
    if (userDetails.password === userDetails.confirmPassword) {
      const registerDetails = {
        name: userDetails.name,
        email: userDetails.email,
        password: userDetails.password,
        latitude: userDetails.latitude,
        longitude: userDetails.longitude,
        countryCode: userDetails.countryCode,
        countryName: userDetails.countryName,
        state: userDetails.state,
        phone: userDetails.phone,
        image: '',
        personalOwnership: ticked,
      };
      // console.log(registerDetails);
      axios
        .post(serverBaseURL + `/signup`, { registerDetails })
        .then((res) => {
          // alert("Registration Successful, continue to login");
          toast.success('Registration Successful, continue to login', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false);
          // window.location.pathname = "/";
          const userResponseDetails = {
            name: res.data.user.name,
            email: res.data.user.email,
            image: res.data.user.image,
            token: res.data.token,
            remember: userDetails.remember,
            _id: res.data.user._id,
          };
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('user', JSON.stringify(userResponseDetails));
          localStorage.setItem('cosmosaddress', res.data.user.cosmosaddress);
          localStorage.setItem('countrycode', res.data.user.countryCode);
          localStorage.setItem('countryname', res.data.user.countryName);
          setOtpPage(true);
        })
        .catch((error) => {
          // alert("Registration Failed, Please try again");

          toast.warn(error?.response?.data?.error || 'Registration Failed', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false);
        });
    } else {
      // alert("Passwords dont match");
      toast.warn('Passwords dont match', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoader(false);
    }
  };

  const handleCallbackGoogle = (res) => {
    setLoader(true);
    console.log(JSON.stringify(res));
    axios
      .post(serverBaseURL + '/googleSignUp', {
        credential: res.credential,
        isGoogle: true,
        latitude: userDetails.latitude,
        longitude: userDetails.longitude,
        countryCode: userDetails.countryCode,
        countryName: userDetails.countryName,
        state: userDetails.state,
      })
      .then((res2) => {
        toast.success('Registration Successful, continue to login', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
        window.location.pathname = '/';
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.error || 'Registration Failed', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
      });
  };
  // const verifiOTP = () => {
  //   if (otp.length === 6) {
  //     console.log(JSON.parse(localStorage.getItem("user")));
  //     axios
  //       .post(`${serverBaseURL}/verifyOTP`, {
  //         userID: JSON.parse(localStorage.getItem("user"))._id,
  //         OTP: otp,
  //       })
  //       .then((res) => {
  //         if (!res.data.error) {
  //           window.location.pathname = "/";
  //         } else {
  //           toast.warn(res.data.reason);
  //         }
  //       });
  //   }
  // };
  // useEffect(() => {
  //   /* global google */
  //   google.accounts.id.initialize({
  //     client_id:
  //       "934470204882-p850214g6njj8l4qdi4iqqoa8838ds7p.apps.googleusercontent.com",
  //     callback: (res) => handleCallbackGoogle(res),
  //   });
  //   // google.accounts.id.renderButton(document.getElementById("googleButton"), {
  //   //   theme: "dark",
  //   //   size: "large",
  //   // });
  //   // google.accounts.id.prompt();
  // }, []);

  return (
    <div className="container-fluid pre-login-home">
      <div className="row px-3 pre-login-card">
        <ToastContainer />
        <div className="col-md-7 img-left d-flex">
          {whichPage === 2 || whichPage === 1 ? (
            <span className="m-auto text-center">
              <img
                style={{ width: '350px' }}
                alt="HeroLedger Logo"
                className="img-fluid"
                src={require('../../Assets/updated/HeroledgerLogoEye.png')}
              />
              <br />
              {/* <h1 className="tag-line  text-white pb-4 font-weight-bold ">
                A MULTIVERSE APPLICATION
              </h1> */}
            </span>
          ) : (
            <>
              {/* <Acknoledgement
                acknowledged={acknowledged}
                setAcknowledged={setAcknowledged}
              /> */}
              <PersonalOwnership
                ticked={ticked}
                setTicked={setTicked}
                setTickedOne={setTickedOne}
                tickedOne={tickedOne}
                checkIfTicked={checkIfTicked}
                setWhichPage={setWhichPage}
              />
            </>
          )}

          <div className="d-flex align-self-end justify-content-start version-text mb-2 text-white">
            Version Beta
          </div>
        </div>
        <div className="col-md-5 card-back flex-row mx-auto px-0">
          {whichPage === 2 ? (
            <OTPpage
              // time={time}
              // setTime={setTime}
              // verifiOTP={verifiOTP}
              _id={JSON.parse(localStorage.getItem('user'))._id}
              otpPage={otpPage}
              changeEmail={setOtpPage}
              email={userDetails.email}
            />
          ) : whichPage === 1 ? (
            <>
              <RegistrationForm
                handleSubmit={handleSubmit}
                setUserDetails={setUserDetails}
                userDetails={userDetails}
                countryList={countryList}
                loader={loader}
                acknowledged={acknowledged}
                otpPage={otpPage}
                setOtpPage={setOtpPage}
                setWhichPage={setWhichPage}
              />
            </>
          ) : (
            <Acknoledgement
              acknowledged={acknowledged}
              setAcknowledged={setAcknowledged}
              tickedOne={tickedOne}
              setWhichPage={setWhichPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const RegistrationForm = ({
  handleSubmit,
  setUserDetails,
  userDetails,
  countryList,
  loader,
  acknowledged,
  otpPage,
  setOtpPage,

  setWhichPage,
}) => {
  return (
    <div className="pre-login-card-body card-body">
      <h5 className="title text-center mt-2 font-weight-bold">
        <span style={{ color: '#ef992f' }}>Register</span> into Heroledger
      </h5>

      <form className="pre-login-form-box px-3" onSubmit={handleSubmit}>
        <div className="form-input">
          <span>
            <i className="fa fa-user"></i>
          </span>
          <input
            type="text"
            name="username"
            placeholder="Name"
            maxLength={30}
            required
            onChange={(e) =>
              setUserDetails({ ...userDetails, name: e.target.value })
            }
          />
        </div>
        <div className="form-input">
          <span>
            <i className="fa fa-envelope-o"></i>
          </span>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            onChange={(e) =>
              setUserDetails({ ...userDetails, email: e.target.value })
            }
          />
        </div>
        <div className="form-input">
          <span>
            <i className="fas fa-phone"></i>
          </span>
          {/* <input
            type="Number"
            name="phone"
            placeholder="Phone"
            required
            onChange={(e) =>
              setUserDetails({ ...userDetails, phone: e.target.value })
            }
          /> */}
          <PhoneInput
            country={'us'}
            value={userDetails.phone}
            inputClass="phoneInput"
            buttonStyle={{ backgroundColor: 'transparent', border: 'none' }}
            inputStyle={{ marginBottom: '0px' }}
            onChange={(e) => setUserDetails({ ...userDetails, phone: e })}
          />
        </div>
        <div className="form-input">
          <span>
            <i className="fas fa-map-marker-alt"></i>
          </span>
          <select
            className="form-control"
            name="countryCode"
            placeholder="Select your country"
            required
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                countryCode: e.target.value,
                countryName: countryList.find(
                  (country) => country.code === e.target.value
                ).name,
              });
            }}
          >
            <option>Select your country</option>
            {countryList.map((country) => (
              <option id={country.name} key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
          {/* <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }
                /> */}
        </div>
        <div className="form-input">
          <span>
            <i className="fa fa-key"></i>
          </span>
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            onChange={(e) =>
              setUserDetails({ ...userDetails, password: e.target.value })
            }
          />
        </div>
        <div className="form-input">
          <span>
            <i className="fa fa-key"></i>
          </span>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            required
            onChange={(e) =>
              setUserDetails({
                ...userDetails,
                confirmPassword: e.target.value,
              })
            }
          />
        </div>
        <div className="mb-3">
          {loader ? (
            <button
              type="submit"
              disabled
              className="btn btn-block text-uppercase"
            >
              <div className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
          ) : (
            <button
              type="submit"
              className="btn btn-block text-uppercase"
              style={acknowledged ? {} : { backgroundColor: '#ffc68a' }}
            >
              <i className="fas fa-sign-in-alt"></i> Register
            </button>
          )}
        </div>

        {/*<div className="text-center font-12 mb-1 text-white">
                {" "}
                or Register with
              </div>

               <div className="row mb-1">
                <div className="col-6 font-12">
                  <FacebookLogin
                    appId="462472422458876"
                    // autoLoad={true}
                    fields="name,email,picture"
                    // onClick={componentClicked}
                    render={(renderProps) => (
                      <>
                        <a
                          rel="noreferrer noopener"
                          target="_blank"
                          onClick={renderProps.onClick}
                          className="btn btn-block btn-social btn-facebook"
                        >
                          Facebook
                        </a>
                      </>
                    )}
                    callback={(e) => {
                      responseFacebook(e);
                    }}
                  />
                </div>
                <div className="col-6 font-12">
                  <div
                    className="GoogleLoginButtonContainer"
                    id="googleButtonContainer"
                    style={{
                      // display: "hidden",
                      // opacity: "0",
                      // width: "0px",
                      // height: "0px",
                      // overflow: "hidden",
                      width: "",
                    }}
                  >
                    <div id="googleButton" className="GoogleLoginButton"></div>
                  </div>
                  <button
                    className="btn btn-block btn-social btn-google"
                    style={{ overflow: "hidden" }}
                    type="button"
                    onClick={() => {
                      console.log("clicked");
                     // /*google global
                      google.accounts.id.prompt((noti) => {
                        console.log(noti);
                        if (noti.isNotDisplayed()) {
                          toast.warn(
                            "Please clear site cache since you closed the previous login"
                          );
                        }
                      });
                      // google.accounts.id.prompt();
                      // document.getElementById("googleButtonContainer").click();
                    }}
                    // onClick={renderProps.onClick}
                    // disabled={renderProps.disabled}
                  >
                    Google
                  </button>
                </div>
              </div> */}
        <hr className="my-2" />
        <div className="text-center font-12 mb-1 font-weight-bold text-white">
          Already have an account?{' '}
          <Link className="text-primary font-12" to="/">
            Login here
          </Link>
        </div>
      </form>
    </div>
  );
};

const Acknoledgement = ({
  acknowledged,
  setAcknowledged,
  tickedOne,
  setWhichPage,
}) => {
  return (
    <>
      <div className="ack">
        <div className="ack_1">
          <div className="ack_1_c" style={{ width: '90%' }}>
            <div className="ack_1_title">ACKNOWLEDGEMENTS</div>
            {/* <br /> */}
            <div className="ack_1_txt">
              You aknowledge that you are required to follow Rastr Technologies{' '}
              <a
                href="http://localhost:3000/terms"
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                Privacy policy
              </a>{' '}
              and{' '}
              <a
                href="http://localhost:3000/privacyPolicy"
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
                Terms of Service{' '}
              </a>{' '}
              while using the HeroLedger application. You acknowledge the
              digital files you are uploading, registering, selling or
              auctioning within this application do not violate Rastr's Terms of
              Service for artistic intellectual property (i.e. illustrations,
              written work, audio, graphic design and etc.).
              <br />
              <br /> You further acknowledge that all ownership rights of the
              artistic works you sell and/or auction on this site are
              transferred in full to the buyer. Ownership rights are not
              transferred with licensing activities in this application as
              licenses only convey certain use rights for a specified duration
              of time, which revert back to their owner once the terms of the
              license agreement are either fulfilled, forfeited, expired or
              breached.
            </div>
            <div className="ack_1_c_btnC">
              <div className="checkboxContainer">
                <input
                  id="a"
                  type="checkbox"
                  tabIndex="1"
                  onChange={() => setAcknowledged(!acknowledged)}
                />
                <label className="green-background" htmlFor="a">
                  I, Acknowledge all of the above.
                </label>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-block text-uppercase"
            onClick={() => {
              if (acknowledged && tickedOne) {
                setWhichPage(1);
              } else {
                toast.warn('Please check all the checkbox that apply');
              }
            }}
            style={
              acknowledged && tickedOne
                ? {
                    position: 'absolute',
                    top: '90%',
                    width: '90%',
                    backgroundColor: 'orange',
                  }
                : {
                    position: 'absolute',
                    top: '90%',
                    width: '90%',
                    backgroundColor: '#ffc68a',
                  }
            }
          >
            <i className="fas fa-sign-in-alt"></i> Next
          </button>
        </div>
      </div>
    </>
  );
};

const Timer = ({ t }) => {
  return (
    <>
      <span style={{ fontWeight: 'bolder', fontSize: '18px' }}>{t}</span>
    </>
  );
};

// const VerificationCode = ({
//   otp,
//   setOtp,
//   time,
//   setTime,
//   verifiOTP,
//   otpPage,
//   setOtpPage,
// }) => {
//   useEffect(() => {
//     let timeInterval = setInterval(() => {
//       if (time <= 0) clearInterval(timeInterval);
//       else setTime(time--);
//     }, 1000);
//   }, []);
//   return (
//     <div id="wrapper">
//       <div id="dialog">
//         <div>
//           Please enter the 6-digit verification code sent to:{" "}
//           {"bimalpandey32@gmail.com"}
//         </div>
//         <div id="form">
//           {/* <input
//             id="inputqwe1"
//             type="text"
//             maxLength="1"
//             size="1"
//             min="0"
//             max="9"
//             pattern="[0-9]{1}"
//           />
//           <input
//             id="inputqwe2"
//             type="text"
//             maxLength="1"
//             size="1"
//             min="0"
//             max="9"
//             pattern="[0-9]{1}"
//           />
//           <input
//             id="inputqwe3"
//             type="text"
//             maxLength="1"
//             size="1"
//             min="0"
//             max="9"
//             pattern="[0-9]{1}"
//           />
//           <input
//             id="inputqwe4"
//             type="text"
//             maxLength="1"
//             size="1"
//             min="0"
//             max="9"
//             pattern="[0-9]{1}"
//           />
//           <input
//             id="inputqwe5"
//             type="text"
//             maxLength="1"
//             size="1"
//             min="0"
//             max="9"
//             pattern="[0-9]{1}"
//           />
//           <input
//             id="inputqwe6"
//             type="text"
//             maxLength="1"
//             size="1"
//             min="0"
//             max="9"
//             pattern="[0-9]{1}"
//           /> */}
//           <OtpInput
//             value={otp}
//             onChange={(e) => setOtp(e)}
//             numInputs={6}
//             separator={<span style={{ paddingRight: "5px" }}></span>}
//           />
//           {/* <form
//             method="get"
//             id="digit-group"
//             data-group-name="digits"
//             data-autosubmit="false"
//             autoComplete="off"
//           >
//             <input
//               type="text"
//               id="digit-1"
//               name="digit-1"
//               maxLength="1"
//               data-next="digit-2"
//               className="inputss"
//             />
//             <input
//               type="text"
//               id="digit-2"
//               name="digit-2"
//               maxLength="1"
//               data-next="digit-3"
//               data-previous="digit-1"
//               className="inputss"
//             />
//             <input
//               type="text"
//               id="digit-3"
//               maxLength="1"
//               name="digit-3"
//               data-next="digit-4"
//               data-previous="digit-2"
//               className="inputss"
//             />
//             <input
//               type="text"
//               id="digit-4"
//               maxLength="1"
//               name="digit-4"
//               data-next="digit-5"
//               data-previous="digit-3"
//               className="inputss"
//             />
//           </form> */}
//           <button
//             className="btn btn-primary btn-embossed"
//             onClick={() => {
//               verifiOTP();
//             }}
//           >
//             Verify
//           </button>
//         </div>
//         <div>
//           <br />
//           <div>
//             Resend OTP in <Timer t={time} /> sec{" "}
//           </div>
//           <br />
//           <a href="#">Change email</a>
//         </div>
//       </div>
//     </div>
//   );
// };

const PersonalOwnership = ({
  setTicked,
  ticked,
  setTickedOne,
  tickedOne,
  checkIfTicked,
}) => {
  useEffect(() => {
    document.getElementById('datePicker').valueAsDate = new Date();

    // const paintCanvas = document.querySelector(".js-paint");
    // const context = paintCanvas.getContext("2d");
    // context.lineCap = "round";
    // let x = 0,
    //   y = 0;
    // let isMouseDown = false;
    // const stopDrawing = () => {
    //   isMouseDown = false;
    // };
    // const startDrawing = (event) => {
    //   isMouseDown = true;
    //   [x, y] = [event.offsetX, event.offsetY];
    // };
    // const drawLine = (event) => {
    //   if (isMouseDown) {
    //     let rect = event.target.getBoundingClientRect();
    //     let x2 = event.clientX - rect.left; //x position within the element.
    //     let y2 = event.clientY - rect.top;
    //     const newX = event.clientX;
    //     const newY = event.clientY;
    //     context.beginPath();
    //     context.moveTo(x, y);
    //     context.lineTo(x2, y2);
    //     context.stroke();
    //     //[x, y] = [newX, newY];
    //     x = x2;
    //     y = y2;
    //   }
    // };
    // paintCanvas.addEventListener("mousedown", startDrawing);
    // paintCanvas.addEventListener("mousemove", drawLine);
    // paintCanvas.addEventListener("mouseup", stopDrawing);
    // paintCanvas.addEventListener("mouseout", stopDrawing);
  }, []);
  return (
    <>
      <div className="ack">
        <div className="ack_1">
          <div className="ack_1_c">
            <div className="ack_1_title">PERSONAL OWNERSHIP STATEMENT</div>
            {/* <br /> */}
            <div className="ack_1_txt">
              I certify that I have 100% ownership and/or controlling use rights
              of the artistic works represented by the digital files I register,
              sell, license and auction on the HeroLedger application.
              <br />
              <br /> The intellectual property I register, sell, license and
              auction on this application was (Check any that apply):
            </div>
            <div className="ack_1_c_btnC">
              <div className="checkboxContainer">
                <input
                  id="e"
                  type="checkbox"
                  tabindex="1"
                  value={ticked.created}
                  onChange={() => {
                    setTicked({ ...ticked, created: !ticked.created });
                    // let check = checkIfTicked(ticked);
                    // console.log(check);
                    // setTickedOne(check);
                  }}
                />
                <label className="green-background" for="e">
                  Created by me
                </label>
              </div>
              <div className="checkboxContainer">
                <input
                  id="b"
                  type="checkbox"
                  tabindex="1"
                  value={ticked.purchased}
                  onChange={() => {
                    setTicked({ ...ticked, purchased: !ticked.purchased });
                    // setTickedOne(checkIfTicked(ticked));
                  }}
                />
                <label className="green-background" for="b">
                  Purchased by me
                </label>
              </div>
              <div className="checkboxContainer">
                <input
                  id="c"
                  type="checkbox"
                  tabindex="1"
                  value={ticked.lisenced}
                  onChange={() => {
                    setTicked({ ...ticked, lisenced: !ticked.lisenced });
                    // setTickedOne(checkIfTicked(ticked));
                  }}
                />
                <label className="green-background" for="c">
                  Licensed by me
                </label>
              </div>
              <div className="checkboxContainer">
                <input
                  id="d"
                  type="checkbox"
                  tabindex="1"
                  value={ticked.gift}
                  onChange={() => {
                    setTicked({ ...ticked, gift: !ticked.gift });
                    // setTickedOne(checkIfTicked(ticked));
                  }}
                />
                <label className="green-background" for="d">
                  Received as a gift
                </label>
              </div>
            </div>
            <div className="ack_1_txt">
              I certify that all information in this statment is complete and
              accurate and that I am competent to sign this document.
            </div>
            {/* <canvas
              className="js-paint  paint-canvas ack_canv"
              width={200}
              height={100}
            ></canvas> */}
            <div>
              <input type={'date'} id="datePicker" placeholder="Name" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
