import React from "react";
// import DummyLogo from '../../Assets/dummyCardLogo.png'
import DummyLogo from "../../Assets/updated/heroledgerLogoFinal.png";
const DummyCard = () => {
  const elements = [1, 2, 3, 4];
  return (
    <>
      {elements.map((value, index) => {
        return (
          <div
            style={{
              margin: "15px",
              display: "flex",
              placeItems: "center",
              background: "#1c1c1b",
            }}
            className="store-item dummyCard"
          >
            <img
              className="img-fluid"
              src={DummyLogo}
              style={{ width: "85%", height: "auto", paddingTop: "15%" }}
              alt={"Multiverse-logo"}
            // onClick={handleClickProduct}
            />
            <div className="store-item__header" style={{ width: "100%", backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
              <div className="store-item__header-rating">
                <i className="far fa-star mr-1"></i>
                <i className="far fa-star mr-1"></i>
                <i className="far fa-star mr-1"></i>
                <i className="far fa-star mr-1"></i>
                <i className="far fa-star"></i>
              </div>
              <div className="store-item__header-actions">
                <i title="Add to favorites" className="p-2 far fa-heart"></i>
                {/* <i title="Buy" className="p-2 fas fa-cart-plus" onClick={handleBuyAction}></i> */}
              </div>
            </div>

            {/* <div className="store-item__footer">
              <div className="store-item__footer-price">Price $120</div>
              <div className="store-item__footer-actions">
                <i
                  title="License"
                  className="p-2 far fa-clone"
                  data-toggle="modal"
                  data-target="#licensing-terms"
                ></i>
                <i
                  title="See more info"
                  className="p-2 fas fa-info-circle"
                  //   onClick={handleClickProduct}
                ></i>
              </div>
            </div> */}

            {/* Modal-licensing terms */}
            {/* <div className="modal licensing-modal" id="licensing-terms">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title ">LICENSING AGREEMENT</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>
                      Asset Name:{" "}
                      <span className="font-weight-bold text-dark">
                        Multiverse Logo
                      </span>
                    </p>
                    <p>
                      Hash:
                      <span className="font-weight-bold text-dark"></span>
                    </p>
                    <div className="watermark">SAMPLE</div>
                    <p>
                      This Artist Licensing Agreement (the “AGREEMENT”) is
                      entered into effective this date, (date added here)
                      between LICENSOR NAME (“ARTIST”) and LICENSEE (“CLIENT”).
                    </p>
                    <p>
                      <b>Scope of this Agreement.</b> This Agreement applies to
                      any image, graphics, digital assets, or digital images
                      created or taken by Artist and delivered to the Client
                      (collectively known as “IMAGES”). This Agreement governs
                      the relationship between the parties and in no
                      communication or other exchange, shall modify the terms of
                      this Agreement unless agreed to in writing.
                    </p>
                    <p>
                      <b>Rights:</b> All Images and rights relating to them,
                      including copyright and ownership rights in the media in
                      which the Images are stored, remain the sole and exclusive
                      property of the Artist. This license provides the Client
                      with the limited right to reproduce, publicly display, and
                      distribute the Images only for the agreed upon terms as
                      set forth in the Client Invoice and signed by both
                      parties. Images used for any purpose not directly related
                      outside of those terms must be with the express permission
                      of Artist and may include the payment of additional fees,
                      unless otherwise agreed to in writing. Images may contain
                      copyright management information (CMI) at the discretion
                      of the Artist in the form of either 1) a copyright notice
                      © and/or 2) other copyright and ownership information
                      embedded in the metadata or elsewhere unless otherwise
                      agreed to by the Parties. Removing and/or altering such
                      information is prohibited and constitutes a violation of
                      the Digital Millennium Copyright Act (DMCA) and Client
                      will be responsible to the Artist for any penalties and
                      awards available under that statute.
                    </p>{" "}
                    <p>
                      {" "}
                      <b>Relationship of the Parties:</b> The parties agree that
                      Artist is an independent contractor and that neither
                      Artist nor Artist’s employees or contract personnel are,
                      or shall be deemed to be, employees of Client. No agency,
                      partnership, joint venture, or employee-employer
                      relationship is intended or created by this Agreement.
                      Neither party is authorized to act as agent or bind the
                      other party except as expressly stated in this Agreement.
                      Artist and the Images or any other deliverables prepared
                      by Artist shall not be deemed a work for hire as defined
                      under Copyright Law. All rights granted to Client are
                      contractual in nature and are expressly defined by this
                      Agreement.
                    </p>
                    <p>
                      <b>Creation:</b> The manner and method of creating any
                      Image is solely at the discretion of Artist and the Client
                      has no right to control Artist’s manner and method of
                      performance under this Agreement. Artist will use his/her
                      best efforts to: (a) ensure that the Images conform to
                      Client’s specifications; and (b) submit all Images to
                      Client in publishable quality, on or before the applicable
                      deadlines.
                    </p>
                    <p>
                      <b>Delivery:</b> Artist may select delivery of designs in
                      PDF, JPEG, PNG, or other standard formats at a resolution
                      that Artist determines will be suitable for the Images as
                      licensed. It is the Client’s responsibility to verify that
                      the Images are suitable for reproduction and that if the
                      Images are not deemed suitable, to notify the Artist
                      within five (5) business days. Artist’s sole obligation
                      will be to replace the Images at a suitable resolution but
                      in no event will Artist be liable for poor reproduction
                      quality, delays, or consequential damages.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <div className="mr-auto">
                      <input type="checkbox" /> {"    "} &nbsp;I have read and
                      accept the terms and information contained in this
                      license.
                    </div>
                    <button
                      type="button"
                      className="btn btn-success"
                      // disabled={!check}
                      // onClick={handleLicenseAction}
                    >
                      Proceed to purchase
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        );
      })}
    </>
  );
};

export default DummyCard;
