import React, { useEffect, useState } from 'react';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';

const serverBaseURL = '';
const Timer = ({ t }) => {
  return (
    <>
      <span style={{ fontWeight: 'bolder', fontSize: '18px' }}>{t}</span>
    </>
  );
};
const OTPpage = ({
  //   otp,
  //   setOtp,
  //   time,
  //   setTime,
  //   verifiOTP,
  //   otpPage,
  //   setOtpPage,
  email,
  _id,
  changeEmail,
}) => {
  const [otp, setOtp] = useState('');
  let [time, setTime] = useState(120);
  let [newInterval, setNewInterval] = useState(true);
  let timeIntervel;
  const verifiOTP = () => {
    if (otp.length === 6) {
      axios
        .post(`${serverBaseURL}/verifyOTP`, {
          userID: _id,
          OTP: otp,
        })
        .then((res) => {
          if (!res.data.error) {
            window.location.pathname = '/';
          } else {
            toast.warn(res.data.reason);
          }
        });
    }
  };

  const resendOTP = () => {
    axios
      .post(serverBaseURL + '/resendOTP', {
        _id: _id,
      })
      .then((res) => {
        if (res.data.error) {
          toast.warn(res.data.reason);
        } else {
          setTime(120);
          setOtp('');
          clearInterval(timeIntervel);
          setNewInterval(!newInterval);
        }
      });
  };
  useEffect(() => {
    timeIntervel = setInterval(() => {
      if (time < 0) clearInterval(timeIntervel);
      else setTime(time--);
    }, 1000);
  }, [newInterval]);
  return (
    <div id="wrapper">
      <div id="dialog">
        <div>
          Please enter the verification code sent to:{' '}
          <span style={{ fontSize: '16px', color: 'orange' }}>{email}</span>
        </div>
        <div id="OTPform">
          <div
            style={{ display: 'grid', placeItems: 'center', height: '100%' }}
          >
            <OtpInput
              value={otp}
              onChange={(e) => setOtp(e)}
              inputStyle={'otpInputReset'}
              numInputs={6}
              separator={<span style={{ paddingRight: '5px' }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
            />

            <button
              className="btn btn-primary btn-embossed"
              onClick={() => {
                verifiOTP();
              }}
            >
              Verify
            </button>
          </div>
        </div>
        <div>
          <br />
          <div
            onClick={() => {
              if (time <= 0) {
                resendOTP();
              } else {
                toast.warn('Please wait ' + time + ' more seconds');
              }
            }}
          >
            <button className="btn btn-primary btn-embossed">Resend OTP in <Timer t={time} /> sec</button>
          </div>
          <br />
          <a href="#" onClick={() => changeEmail(false)}>
          <button className="btn btn-primary btn-embossed" onClick={() => changeEmail(false)}>Change Email</button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default OTPpage;
