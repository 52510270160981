import React from "react";

const LoadingSpinner = () => {
  return (
    <div
      className="row justify-content-center align-items-center"
      style={{ height: "50px" }}
    >
      <div className="spinner-border text-info text-center" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;
