import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getContract } from '../../Redux/actions/contractAction';
import { licenseProduct } from '../../Redux/actions/licenseProductAction';
import { purchaseProduct } from '../../Redux/actions/purchaseProductAction';

import htmltopdf from 'html2pdf.js';

const StoreItemCard = ({ item, overrideItemsImage, hideFooter }) => {
  const [check, setCheck] = useState(false);
  const history = useHistory();
  const handleClickProduct = () => {
    history.push(`/store/product/${item.productId}`);
  };

  const handleBuyAction = () => {
    purchaseProduct(contract, item);
  };

  const handleLicenseAction = () => {
    licenseProduct(contract, item);
  };

  const contract = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getContract());
  }, [dispatch]);

  return (
    <div className="store-item">
      <img
        className="img-fluid"
        src={overrideItemsImage ? overrideItemsImage : '/image/' + item.image}
        style={{ padding: overrideItemsImage ? '10px' : '0px' }}
        alt={item.productName}
        onClick={handleClickProduct}
      />
      <div className="store-item__header" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
        <div className="store-item__header-rating">
          <i className="far fa-star mr-1"></i>
          <i className="far fa-star mr-1"></i>
          <i className="far fa-star mr-1"></i>
          <i className="far fa-star mr-1"></i>
          <i className="far fa-star"></i>
        </div>
        <div className="store-item__header-actions" style={{ flexDirection: 'row' }}>
          <i title="Add to favorites" className="p-2 far fa-heart"></i>
          <i
            title="Buy"
            className="p-2 fas fa-cart-plus"
            onClick={handleBuyAction}
          ></i>
        </div>
      </div>
      {
        hideFooter || (
          <div className="store-item__footer">
            <div className="store-item__footer-price">
              Price ${item.priceinUsd}
            </div>
            <div className="store-item__footer-actions">
              {item.license && (
                <i
                  title="License"
                  className="p-2 far fa-clone"
                  data-toggle="modal"
                  data-target="#licensing-terms"
                ></i>
              )}
              <i
                title="See more info"
                className="p-2 fas fa-info-circle"
                onClick={handleClickProduct}
              ></i>
            </div>
          </div>
        )
      }
      {/* Modal-licensing terms */}
      {/* <div className="modal licensing-modal" id="licensing-terms">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title ">LICENSING AGREEMENT</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Asset Name:{" "}
                <span className="font-weight-bold text-dark">
                  {item.productName}
                </span>
              </p>
              <p>
                Hash:
                <span className="font-weight-bold text-dark">
                  {item.transactionHash}
                </span>
              </p>
              <div className="watermark">SAMPLE</div>
              <p>
                This Artist Licensing Agreement (the “AGREEMENT”) is entered
                into effective this date, (date added here) between LICENSOR
                NAME (“ARTIST”) and LICENSEE (“CLIENT”).
              </p>
              <p>
                <b>Scope of this Agreement.</b> This Agreement applies to any
                image, graphics, digital assets, or digital images created or
                taken by Artist and delivered to the Client (collectively known
                as “IMAGES”). This Agreement governs the relationship between
                the parties and in no communication or other exchange, shall
                modify the terms of this Agreement unless agreed to in writing.
              </p>
              <p>
                <b>Rights:</b> All Images and rights relating to them, including
                copyright and ownership rights in the media in which the Images
                are stored, remain the sole and exclusive property of the
                Artist. This license provides the Client with the limited right
                to reproduce, publicly display, and distribute the Images only
                for the agreed upon terms as set forth in the Client Invoice and
                signed by both parties. Images used for any purpose not directly
                related outside of those terms must be with the express
                permission of Artist and may include the payment of additional
                fees, unless otherwise agreed to in writing. Images may contain
                copyright management information (CMI) at the discretion of the
                Artist in the form of either 1) a copyright notice © and/or 2)
                other copyright and ownership information embedded in the
                metadata or elsewhere unless otherwise agreed to by the Parties.
                Removing and/or altering such information is prohibited and
                constitutes a violation of the Digital Millennium Copyright Act
                (DMCA) and Client will be responsible to the Artist for any
                penalties and awards available under that statute.
              </p>{" "}
              <p>
                {" "}
                <b>Relationship of the Parties:</b> The parties agree that
                Artist is an independent contractor and that neither Artist nor
                Artist’s employees or contract personnel are, or shall be deemed
                to be, employees of Client. No agency, partnership, joint
                venture, or employee-employer relationship is intended or
                created by this Agreement. Neither party is authorized to act as
                agent or bind the other party except as expressly stated in this
                Agreement. Artist and the Images or any other deliverables
                prepared by Artist shall not be deemed a work for hire as
                defined under Copyright Law. All rights granted to Client are
                contractual in nature and are expressly defined by this
                Agreement.
              </p>
              <p>
                <b>Creation:</b> The manner and method of creating any Image is
                solely at the discretion of Artist and the Client has no right
                to control Artist’s manner and method of performance under this
                Agreement. Artist will use his/her best efforts to: (a) ensure
                that the Images conform to Client’s specifications; and (b)
                submit all Images to Client in publishable quality, on or before
                the applicable deadlines.
              </p>
              <p>
                <b>Delivery:</b> Artist may select delivery of designs in PDF,
                JPEG, PNG, or other standard formats at a resolution that Artist
                determines will be suitable for the Images as licensed. It is
                the Client’s responsibility to verify that the Images are
                suitable for reproduction and that if the Images are not deemed
                suitable, to notify the Artist within five (5) business days.
                Artist’s sole obligation will be to replace the Images at a
                suitable resolution but in no event will Artist be liable for
                poor reproduction quality, delays, or consequential damages.
              </p>
            </div>
            <div className="modal-footer">
              <div className="mr-auto">
                <input
                  type="checkbox"
                  value={check}
                  onChange={() => setCheck(!check)}
                />{" "}
                {"    "} &nbsp;I have read and accept the terms and information
                contained in this license.
              </div>
              <button
                type="button"
                className="btn btn-success"
                disabled={!check}
                onClick={handleLicenseAction}
              >
                Proceed to purchase
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <LisencingModal />
    </div >
  );
};

const LisencingModal = () => {
  const savePDF = () => {
    let ele = document.getElementById('licensePDF');
    var opt = {
      // margin: 0,
      filename: 'licenseAgreement.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 1,
        dpi: 100,
        useCORS: true,
        letterRendering: true,
        getContext: '2d',
      },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    };
    console.log(ele);
    htmltopdf(ele, opt);
  };
  useEffect(() => {
    // savePDF();
  }, []);
  return (
    <>
      <div className="modal licensing-modal" id="licensing-terms">
        <div className="modal-dialog modal-lg" role="document" id="licensePDF">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title ">COMIC ASSET™ LICENSING AGREEMENT</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => savePDF()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ color: 'black' }}>
              <div>
                <p style={{ color: 'black' }}>Comic Asset:{ }</p>
                <p style={{ color: 'black' }}>Comic Asset Hash:{ }</p>
                <p style={{ color: 'black' }}>
                  Comic Asset Registration Date:{ }
                </p>
                <p style={{ color: 'black' }}>
                  Comic Asset Author / Original Owner / Licensor:{ }
                </p>
              </div>
              <div>
                <p style={{ color: 'black' }}>
                  This Comic Asset Licensing Agreement (the “AGREEMENT”) is
                  entered into effective this date,{' [DATE]'} between
                  {'[ARTISTNAME]'} (“ARTIST”) and{' [CLIENT NAME]'} (“CLIENT”).
                  All references to the Client in this Agreement shall include
                  Client’s parent companies, affiliates, and subsidiaries.
                </p>
                {/* <br /> */}
                <p style={{ color: 'black' }}>
                  Scope of this Agreement. This Agreement applies to any image,
                  graphics, digital assets, or digital images created or taken
                  by Artist and delivered to the Client (collectively known as
                  “IMAGES”). This Agreement governs the relationship between the
                  parties and in no communication or other exchange, shall
                  modify the terms of this Agreement unless agreed to in
                  writing.
                </p>
                {/* <br /> */}
                <p style={{ color: 'black' }}>
                  Rights: All Images and rights relating to them, including
                  copyright and ownership rights in the media in which the
                  Images are stored, remain the sole and exclusive property of
                  the Artist. This license provides the Client with the limited
                  right to reproduce, publicly display, and distribute the
                  Images only for the agreed upon terms as set forth below and
                  signed by both parties. Images used for any purpose not
                  directly related outside of those terms must be with the
                  express permission of Artist and may include the payment of
                  additional fees, unless otherwise agreed to in writing. Images
                  may contain copyright management information (CMI) at the
                  discretion of the Artist in the form of either 1) a copyright
                  notice © and/or 2) other copyright and ownership information
                  embedded in the metadata or elsewhere unless otherwise agreed
                  to by the Parties. Removing and/or altering such information
                  is prohibited and constitutes a violation of the Digital
                  Millennium Copyright Act (DMCA) and Client will be responsible
                  to the Artist for any penalties and awards available under
                  that statute.
                </p>
                {/* <br /> */}
              </div>
              <div>
                <h4>Specific Use Rights:</h4>
                <ul>
                  <li>comics</li>
                  <li>Print work</li>
                  <li>Something awesome</li>
                  <li>Tshirt</li>
                </ul>
                <div className="html2pdf__page-break"></div>
                <p style={{ color: 'black' }}>
                  Relationship of the Parties: The parties agree that Artist is
                  an independent contractor and that neither Artist nor Artist’s
                  employees or contract personnel are, or shall be deemed to be,
                  employees of Client. No agency, partnership, joint venture, or
                  employee-employer relationship is intended or created by this
                  Agreement. Neither party is authorized to act as agent or bind
                  the other party except as expressly stated in this Agreement.
                  Artist and the Images or any other deliverables prepared by
                  Artist shall not be deemed a work for hire as defined under
                  Copyright Law. All rights granted to Client are contractual in
                  nature and are expressly defined by this Agreement.{' '}
                </p>
                <p style={{ color: 'black' }}>
                  Creation: The manner and method of creating any Image is
                  solely at the discretion of Artist and the Client has no right
                  to control Artist’s manner and method of performance under
                  this Agreement. Artist will use his/her best efforts to: (a)
                  ensure that the Images conform to HeroLedger™ System
                  Registration Specifications; and (b) submit all Images to
                  Client through HeroLedger application in publishable quality,
                  on or before the applicable deadlines.
                </p>
                <p style={{ color: 'black' }}>
                  Delivery: Artist may select delivery of photographs in JPEG,
                  TIFF, PNG, or other standard formats at a resolution that
                  HeroLedger System Registration Specifications determines will
                  be suitable for the Images as licensed. It is the Client’s
                  responsibility to verify that the Images are suitable for
                  reproduction and that if the Images are not deemed suitable,
                  to notify the Artist within five (5) business days. Artist’s
                  sole obligation will be to replace the Images at a suitable
                  resolution but in no event will Artist be liable for poor
                  reproduction quality, delays, or consequential damages. Unless
                  otherwise specifically provided, Artist is not responsible for
                  providing images 1) larger than 8”x10” at 300 dpi or 2) in a
                  format higher than 8-bit or in RAW format. Artist has no
                  obligation to retain or archive any Images delivered to
                  Client.
                </p>
                <p style={{ color: 'black' }}>
                  Fees: All fees and expenses payable under this agreement are
                  required at point of sale the same business day of the
                  delivery of the Images and payable irrespective of whether
                  Client makes actual use of the Images. In the event rights are
                  revoked, all images in the possession of Client will be
                  removed from all forms of media and permanently destroyed
                  within ten (10) days. Client shall provide Artist with written
                  statement that all images have been removed and destroyed.
                </p>
              </div>
              <br />
              <div>
                <h5>
                  CANCELLATION: If Client cancellation of this Agreement on:
                </h5>
                <p style={{ color: 'black' }}>
                  1) Stated rights transfer date on the Electronic Purchase
                  Receipt, Artist will refund Client License Fee less
                  twenty-five (25)% as a cancellation fee which shall be divided
                  equally between Artist and Rast·Technologies, LLC. All refunds
                  and fees are due by Artist for remittance to all required
                  parties within two days of cancellation.
                </p>
                {/* <br /> */}
                <p style={{ color: 'black' }}>OR</p>
                {/* <br /> */}
                <p style={{ color: 'black' }}>
                  2) Within one (1) month of this agreement, Artist will refund
                  Client License Fee less fifty (50)% as a cancellation fee
                  which shall be divided equally between Artist and
                  Rast·Technologies, LLC. All refunds and fees are due by Artist
                  for remittance to all required parties within two days of
                  cancellation.
                </p>
                <br />
              </div>
              {/* <div className="html2pdf__page-break"></div> */}
              <div>
                <p style={{ color: 'black' }}>
                  No Exclusivity: This Agreement does not create an exclusive
                  relationship between the parties. Client is free to engage
                  others to perform services of the same or similar nature to
                  those provided by Artist, and Artist shall be entitled to
                  offer and provide services to others, solicit other clients
                  and otherwise advertise the services offered by Artist.
                </p>
                <p style={{ color: 'black' }}>
                  Transfer and Assignment: Client may not assign or transfer
                  this agreement or any rights granted under it. No amendment or
                  waiver of any terms is binding unless in writing and signed by
                  the parties. However, the invoice may reflect, and Client is
                  bound by authorizations that could not be confirmed in writing
                  because of insufficient time or other practical
                  considerations.
                </p>
                <p style={{ color: 'black' }}>
                  Indemnification: Client will indemnify and defend Artist
                  against all claims, liability, damages, costs, and expenses,
                  including reasonable legal fees and expenses, arising out of
                  the creation or any use of the Images or materials furnished
                  by Client. It is the Client’s responsibility to obtain the
                  necessary model or property releases are ensure they are full
                  effect and in force.
                </p>
                <p style={{ color: 'black' }}>
                  General Law/Arbitration: This Agreement sets forth the entire
                  understanding of the parties, and supersedes all prior
                  agreements between the parties. This Agreement shall be
                  governed, interpreted and enforced in accordance with the laws
                  of the Geographical Location of {'[COUNTRY]'}. Any claim or
                  litigation arising out of this Agreement or its performance
                  may be commenced only in courts physically located in
                  {' [COUNTRY]'}, and the parties hereby consent to the personal
                  jurisdiction of such courts. In the event of any litigation
                  arising out of or relating to this Agreement, the prevailing
                  party shall be entitled to recover its attorneys’ fees
                  incurred in the litigation. If parties are unable to resolve
                  the dispute, either party may request mediation and/or binding
                  arbitration in a forum mutually agreed to by the parties.
                </p>
                <p style={{ color: 'black' }}>
                  Severability: If one or more of the provisions in the
                  Agreement is found invalid, illegal or unenforceable in any
                  respect, the validity and enforceability of the remaining
                  provisions shall not be affected. Any such provisions will be
                  revised as required to make them enforceable.
                </p>
                <p style={{ color: 'black' }}>
                  Waiver: No action of either party, other than in writing
                  agreed to by the parties, may be construed to waive any
                  provision of this Agreement and a single or partial exercise
                  by either party of any such action will not preclude further
                  exercise of other rights or remedies in this Agreement.
                </p>
                <p style={{ color: 'black' }}>
                  IN WITNESS WHEREOF, the parties have caused this Artist
                  Licensing Agreement to be duly executed as of the dates
                  written below.
                </p>
                <p style={{ color: 'black' }}>{'CLIENT NAME'}</p>
                <p style={{ color: 'black' }}>{'DATE'}</p>
                <br />
                <p style={{ color: 'black' }}>{'ARTIST NAME'}</p>
                <p style={{ color: 'black' }}>{'DATE'}</p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreItemCard;

// {
// InStore: true,
// blockHash: "0x66b1c4b85141fde0196fde26d59c4d16dbe11b23c1247396b9b3d104a46f09b9",
// description: "Sample Description of Spider Web",
// fullDescription: "Full Description of Spider Web",
// image: "9d41b76494973332d02df10baabe13bc.png",
// license: true,
// licenseDescription: "This spider web can be provided in any of the products.",
// licenseFee: 72800000000000000,
// licenseFeeUsd: 28,
// licenseOwnerAddress: "0xc120bE46AB9da3D16BB075C3832795ff35532adB",
// licensee: "N/A",
// licensor: "dineshdsv3@gmail.com",
// originatorEmail: "dineshdsv3@gmail.com",
// ownerAddress: "0xc120bE46AB9da3D16BB075C3832795ff35532adB",
// ownerEmail: "dineshdsv3@gmail.com",
// price: 234000000000000000,
// priceinUsd: 90,
// productId: "32",
// productName: "Spider Web",
// productType: "props",
// royalty: 15,
// term1EndDate: 1629570600,
// term1StartDate: 1592159400,
// term2: "exclusive",
// timestamp: 1600781456,
// transactionHash: "0x4dc26eda17742212f84ea83120b65a37eb120903bc0d16bd4b1e6bd7d2743b1e",
// __v: 0,
// _id: "5f69fc9485a00d74e0fc38a6"
// }
