import Axios from 'axios';
import { getName } from 'country-list';
import React, { useEffect, useState } from 'react';
import Card from '../ui/Card';
import WorldMap from '../ui/WorldMap';

const CardWorldMap = ({ cardDetailsLinkTitle }) => {
  console.log(cardDetailsLinkTitle, 'cardDetailsLinkTitle');
  const [salesByCountry, setSalesByCountry] = useState([]);
  const [mapData, setMapData] = useState({});

  useEffect(() => {
    getSalesByCountryData();
  }, []);

  const getSalesByCountryData = () => {
    // TO-DO
    // Make request to gather sales by country data

    const { email } = JSON.parse(localStorage.getItem('user'));
    Axios.get(`/getLastSevenDaysPurchaseAggregateByEmail?email=${email}`)
      .then((res) => res.data)
      .then((res) => {
        let data = {};
        res.data.forEach((country) => {
          data[country.country] = country.total;
        });
        console.log(res, 'res');
        setSalesByCountry(res.data);
        setMapData(data);
      })
      .catch((err) => console.error(err));
  };
  return (
    <Card
      title="Sales by Location"
      subtitle="(past seven days)"
      detailsTitle={cardDetailsLinkTitle === 'true' ? '' : 'View details'}
      detailsLink="/mydata"
    >
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <div>
            <WorldMap mapData={mapData} />
          </div>
        </div>
        <div className="col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
          <table className="table table-borderless transactions-country-data">
            <thead>
              <tr>
                <th className="text-center">Countries</th>
                <th className="text-center">Amount</th>
              </tr>
            </thead>
            <tbody>
              {salesByCountry &&
                salesByCountry.length > 0 &&
                salesByCountry.map((country) => (
                  <tr key={country.country}>
                    {/* <th className="text-center">{getName(country?.country)}</th> */}
                    <td className="text-center text-muted">
                      Eth {country.total}
                    </td>
                  </tr>
                ))}
              {salesByCountry.length === 0 && (
                <tr>
                  <td colSpan="2" className="text-center">
                    No data found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default CardWorldMap;
