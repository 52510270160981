import Web3 from 'web3';
import Heroledger from '../../blockchain/abis/heroledger.json';
import {
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_SUCCESS,
} from '../constants/contractConstant';

export const getContract = () => async (dispatch) => {
  try {
    dispatch({ type: CONTRACT_LIST_REQUEST });

    window.web3 = new Web3(
      new Web3.providers.HttpProvider('https://comicchain.io')
    );
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    let index = accounts.length - 3;
    console.log(accounts, 'accounts in redux');
    localStorage.setItem('account', accounts[0]);
    const networkId = await web3.eth.net.getId();
    const networkData = Heroledger.networks[networkId];
    if (networkData) {
      const heroledger = await new web3.eth.Contract(
        Heroledger.abi,
        networkData.address
      );
      console.log('HEROLEDGER ASDF', heroledger);
      dispatch({
        type: CONTRACT_LIST_SUCCESS,
        payload: heroledger,
      });
    }
  } catch (error) {
    console.log('Contract execution error');
  }
};
