/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import { Link } from "react-router-dom";
import MainLayout from "../../Components/MainLayout";
import Web3 from "web3";
import { Card } from "react-bootstrap";
import LoadingSpinner from "../../Components/ui/LoadingSpinner";

function Transactions() {
  const [, setUser] = useState({});
  const [buyData, setBuyData] = useState([]);
  const [loader, setLoader] = useState(true);

  const getTransactions = useCallback(async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    setUser(user);
    let email = user.email;
    axios.get("/getTransactions", { params: { email } }).then((res) => {
      const buyRecords = res.data.data.map((ele) => {
        return {
          name: (
            <Link to={`/Product?id=${ele.productId}&prev=transactions`}>
              {ele.productName}
            </Link>
          ),
          hash: (
            <a
              href={`https://kovan.etherscan.io/tx/${ele.transactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ele.transactionHash}
            </a>
          ),
          price: `${getPriceinUSD(ele.amountinEth)} USD`,
          previousOwner: ele.previousOwner,
          currentOwner: ele.currentOwner,
          transactionType: (
            <span className="text-capitalize">{ele.transactionType}</span>
          ),
          registerDate: getDate(ele.registrationDate),
          transactionDate: getDate(ele.purchaseDate),
        };
      });
      setBuyData(buyRecords);
      setLoader(false);
    });
  }, []);

  const getPriceinUSD = (weiPrice) => {
    let Eth = Web3.utils.fromWei(weiPrice.toString(), "Ether");
    const usdPrice = Eth / 0.0026;
    return usdPrice.toFixed(2);
  };

  const getDate = (timestamp) => {
    const stamp = new Date(timestamp * 1000);
    let date = stamp.getDate();
    let month = stamp.getMonth() + 1;
    let year = stamp.getFullYear();
    let hours = stamp.getHours();
    let minutes = stamp.getMinutes();
    let seconds = stamp.getSeconds();

    const time = `${date <= 9 ? "0" + date : date}-${
      month <= 9 ? "0" + month : month
    }-${year} ${hours <= 9 ? "0" + hours : hours}:${
      minutes <= 9 ? "0" + minutes : minutes
    }:${seconds <= 9 ? "0" + seconds : seconds}`;
    return time;
  };

  const customStyles = {
    cells: {
      style: {
        fontSize: "9.9px",
      },
    },
    headCells: {
      style: {
        fontSize: "10.9px",
        fontWeight: "bold",
        color: "#030303",
        alignItems: "center",
        // justifyContent: "center",
        backgroundColor: "#F2F2F2",
      },
    },
  };

  const BuyColumns = [
    {
      name: "Asset Name",
      selector: "name",
      width: "8%",
    },
    {
      name: "Hash",
      selector: "hash",
      // center: true,
      width: "14%",
    },
    {
      name: "Previous Owner",
      selector: "previousOwner",
      // sortable: true,
      width: "14%",
    },
    {
      name: "Current Owner",
      selector: "currentOwner",
      // sortable: true,
      width: "12%",
    },

    {
      name: "Transaction Type",
      selector: "transactionType",
      sortable: true,
      wrap: false,
      // center: true,
      width: "14%",
    },
    {
      name: "Price",
      selector: "price",
      sortable: true,
      // center: true,
      width: "10%",
    },
    {
      name: "Registration Date",
      selector: "registerDate",
      sortable: true,
      // center: true,
      wrap: true,
      width: "14%",
    },
    {
      name: "Transaction Date",
      selector: "transactionDate",
      sortable: true,
      // center: true,
      wrap: true,
      width: "14%",
    },
  ];

  useEffect(() => {
    getTransactions();
  }, []);
  const [crumbs, setCrumbs] = useState([{ name: "Home", route: "/overview" }]);
  return (
    <MainLayout pageTitle="Transactions" crumbs={crumbs} setCrumbs={setCrumbs}>
      <div className="container">
        {loader ? (
          <LoadingSpinner />
        ) : buyData.length > 0 ? (
          <Card>
            <Card.Body className="p-0">
              <h5 className="py-2 px-4 my-2">Transactions History</h5>
              <DataTable
                noHeader
                columns={BuyColumns}
                data={buyData}
                customStyles={customStyles}
                pagination={true}
                responsive={true}
                paginationPerPage={10}
                noDataComponent={
                  <div className="spinner-border text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                }
              />
            </Card.Body>
          </Card>
        ) : (
          <h5 className="text-center text-warning">No Transactions Found</h5>
        )}
      </div>
    </MainLayout>
  );
}

export default Transactions;
