import Sidebar from "./shared/Sidebar";
import React, { useEffect, useState } from "react";
import Topnavbar from "./shared/Topnavbar";
import TermsOfUse from "../Components/ui/TermsAndCondition";
import PrivacyPolicy from "./ui/PrivacyPolicy";
const MainLayout = ({
  pageTitle,
  crumbs,
  setCrumbs,
  children,
  isInStore,
  removeSidebar,
  isInTerms,
  isInPrivacy,
}) => {
  useEffect(() => {
    if (crumbs && crumbs.concat) {
      let isMounted = true;
      setCrumbs(
        crumbs.concat({ name: pageTitle, route: window.location.href })
      );
    }
    if (isInStore) {
      document.getElementById("storeWrappers").classList.add("storeWrappers");
    }
  }, []);
  return (
    <>
      <div className="wrapper" id="storeWrappers" style={isInStore ? {} : {}}>
        <Sidebar
          isInStore={isInStore}
          removeSidebar={removeSidebar}
          isInTerms={isInTerms}
        />
        <Topnavbar
          pageTitle={pageTitle}
          crumbs={crumbs}
          setCrumbs={setCrumbs}
          isInStore={isInStore}
        >
          {children}
        </Topnavbar>
      </div>
      {isInTerms ? isInPrivacy ? <PrivacyPolicy /> : <TermsOfUse /> : <></>}
    </>
  );
};

export default MainLayout;
