/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "./ui/Card";
import LoadingSpinner from "./ui/LoadingSpinner";
import Web3 from "web3";

const TransactionsCard = () => {
  const [transactions, setTransactions] = useState([]);
  const [loader, setLoader] = useState(true);

  const getTransactions = async () => {
    const user = await JSON.parse(localStorage.getItem("user"));
    const email = user.email;
    Axios.get("/getTransactions", { params: { email } }).then((res) => {
      const buyRecords = res.data.data.map((ele) => {
        return {
          id: ele._id,
          name: (
            <Link to={`/Product?id=${ele.productId}&prev=transactions`}>
              {ele.productName}
            </Link>
          ),
          hash: (
            <a
              href={`https://kovan.etherscan.io/tx/${ele.transactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ele.transactionHash}
            </a>
          ),
          price: `${getPriceinUSD(ele.amountinEth)} USD`,
          previousOwner: ele.previousOwner,
          currentOwner: ele.currentOwner,
          transactionType: (
            <span className="text-capitalize">{ele.transactionType}</span>
          ),
          registerDate: getDate(ele.registrationDate),
          transactionDate: getDate(ele.purchaseDate),
        };
      });
      setTransactions(buyRecords);
      setLoader(false);
    });
  };

  const getPriceinUSD = (weiPrice) => {
    const Eth = Web3.utils.fromWei(weiPrice.toString(), "Ether");
    const usdPrice = Eth / 0.0026;
    return usdPrice.toFixed(2);
  };

  const getDate = (timestamp) => {
    const stamp = new Date(timestamp * 1000);
    const date = stamp.getDate();
    const month = stamp.getMonth() + 1;
    const year = stamp.getFullYear();
    const hours = stamp.getHours();
    const minutes = stamp.getMinutes();
    const seconds = stamp.getSeconds();

    const time = `${date <= 9 ? "0" + date : date}-${
      month <= 9 ? "0" + month : month
    }-${year} ${hours <= 9 ? "0" + hours : hours}:${
      minutes <= 9 ? "0" + minutes : minutes
    }:${seconds <= 9 ? "0" + seconds : seconds}`;
    return time;
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Card
      title="Transactions"
      subtitle="(past seven days)"
      detailsTitle="View details"
      detailsLink="/transactions"
    >
      {loader ? (
        <LoadingSpinner />
      ) : (
        <table className="table table-borderless dashboard-transactions">
          <tbody>
            {transactions && transactions.length < 0 ? (
              transactions.slice(0, 5).map((transaction) => {
                return (
                  <tr
                    key={transaction.id}
                    style={{ borderBottom: "1px solid #DFE0EB" }}
                  >
                    <th
                      className="c-text-overflow"
                      scope="row"
                      title="{transaction.name}"
                    >
                      "{transaction.name}"
                    </th>
                    <td className="c-text-overflow" title={transaction.hash}>
                      {transaction.hash}
                    </td>
                    <td>
                      {" "}
                      <span className="text-muted">
                        ${transaction.price}
                      </span>{" "}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
              
                <td colSpan={3}>No transactions found.</td>
             </tr>
              
            )}
          </tbody>
        </table>
      )}
    </Card>
  );
};

export default TransactionsCard;
