import React from 'react';
import HeroLedgerLogo from '../../Assets/updated/heroledgerLogoFinal.png';
import SidebarItem from './SidebarItem';

import DescriptionIcon from '../../Assets/Icons/overview-icon.svg';
import TokensIcon from '../../Assets/Icons/tokens-icon.svg';
import AssetIcon from '../../Assets/Icons/assets-icon.svg';
import StoreIcon from '../../Assets/Icons/store-icon.svg';
import ConnectionsIcon from '../../Assets/Icons/connections-icon.svg';
import LicensingIcon from '../../Assets/Icons/licensing-icon.svg';
import TransactionsIcon from '../../Assets/Icons/transactions-icon.svg';
import SettingsIcon from '../../Assets/Icons/settings-icon.svg';
import DocsIcon from '../../Assets/Icons/documents-icon-real.png';
import MyDataIcon from '../../Assets/Icons/my-data.svg';

const Sidebar = ({ isInStore, removeSidebar, isInTerms }) => {
  const menus = [
    {
      id: 1,
      url: '/overview',
      icon: DescriptionIcon,
      description: 'Overview',
    },
    {
      id: 8,
      url: '/mydata',
      icon: MyDataIcon,
      description: 'My Data',
    },
    {
      id: 3,
      url: '/store',
      icon: StoreIcon,
      description: 'Store',
    },
    {
      id: 2,
      url: '/assets',
      icon: AssetIcon,
      description: 'My Assets',
    },
    {
      id: 6,
      url: "/Tokens",
      icon: TokensIcon,
      description: "Tokens",
    },

    {
      id: 7,
      url: "/connections",
      icon: ConnectionsIcon,
      description: "My Connections",
    },

    {
      id: 4,
      url: '/licensing',
      icon: LicensingIcon,
      description: 'Licensing',
    },
    {
      id: 5,
      url: '/transactions',
      icon: TransactionsIcon,
      description: 'Transactions',
    },
    {
      id: 6,
      url: '/documents',
      icon: DocsIcon,
      description: 'Documents',
    },
    {
      id: 5,
      url: '/settings',
      icon: SettingsIcon,
      description: 'Settings',
    },
  ];

  return (
    <nav
      id="sidebar"
      className={isInStore ? '' : 'bg-dark'}
      style={
        isInStore
          ? { background: 'rgba(0,0,0,0)' }
          : isInTerms
            ? { minHeight: 'auto', background: '#343a40' }
            : {}
      }
    >
      <div
        className="sideBarContainer"
        style={isInTerms ? { position: 'static', overflow: 'scroll' } : {}}
      >
        <div
          className="sidebar-header text-light"
          style={
            isInStore
              ? { background: 'rgba(0,0,0,0)' }
              : isInTerms
                ? { minHeight: 'auto', backgroundColor: '#343a40' }
                : { backgroundColor: '#343a40' }
          }
        >
          <div className="mx-auto align-items-center justify-content-center row">
            <img
              src={HeroLedgerLogo}
              width={70}
              height={'auto'}
              alt="HeroLedger Logo"
            />
            <span className="ml-3">
              HeroLedger&nbsp;<sup>TM</sup>
            </span>
          </div>
        </div>

        {removeSidebar ? (
          <></>
        ) : (
          <>
            <ul className="list-unstyled components">
              {menus.map((menu, index) => (
                <li key={index} className="nav-item">
                  <SidebarItem
                    url={menu.url}
                    icon={menu.icon}
                    description={menu.description}
                  />
                </li>
              ))}
            </ul>
          </>
        )}

        {/* <hr className="bg-light" />
        <li className="nav-item">
          <SidebarItem
            url="/settings"
            icon={SettingsIcon}
            description="Settings"
          />
        </li> */}
      </div>
    </nav>
  );
};

export default Sidebar;
