import React, { useEffect } from 'react';
import Web3 from 'web3';
import axios from 'axios';
import Heroledger from '../../../blockchain/abis/heroledger.json';

let userAccount;
const Metamask = ({ price, productId, purchaseType }) => {
  const getHexa = (num) => {
    return Number(num).toString(16);
  };
  const sendTransaction = async () => {
    console.log(userAccount);
    let exchangeRate = await axios.get(
      'https://api.coinbase.com/v2/exchange-rates?currency=USD'
    );
    let conversionRate = exchangeRate.data.data.rates.ETH;
    const params = [
      {
        from: userAccount,
        to: userAccount,
        gas: getHexa(21000),
        gasPrice: getHexa(7),
        value: getHexa(1000000000000000000 * price * conversionRate),
      },
    ];
    let result = await window.ethereum
      .request({
        method: 'eth_sendTransaction',
        params,
      })
      .catch((err) => {
        console.log(err);
      });

    console.log('Metamaks', result);
  };

  const execute = async () => {
    window.web3 = new Web3(
      new Web3.providers.HttpProvider('https://comicchain.io')
    );
    const web3 = window.web3;
    web3.eth.getChainId().then((e) => console.log('chainID' + e));

    // console.log("CHAIN_ID:" + );
    // console.log(web3);
    console.log('ACCOUNTS');
    const accounts = await web3.eth.getAccounts();
    console.log(accounts);
    const index = accounts.length - 1;
    const networkId = await web3.eth.net.getId();
    console.log(networkId);
    let contract;
    let productInfos;
    const networkData = Heroledger.networks[networkId];
    console.log(networkData);
    if (networkData) {
      console.log('Something');
      const heroledger = await new web3.eth.Contract(
        Heroledger.abi,
        networkData.address
      );
      contract = heroledger;
      console.log(contract, 'contract');
    }
    const serverBaseURL = '';

    console.log(productId, 'productId');
    await axios
      .get(serverBaseURL + `/getSingleProduct?productId=${productId}`)
      .then((res) => {
        productInfos = res.data.data[0];
      });

    try {
      console.log(productInfos, 'productInfos');
      const { email, countryCode, countryName } = JSON.parse(
        localStorage.getItem('user')
      );
      const account = localStorage.getItem('account');
      await contract.methods
        .purchaseProduct(productId, email)
        .send({
          from: accounts[1],
          value: price,
          gas: 6000000,
          gasPrice: 6000000,
        })
        .once('receipt', (receipt) => {
          console.log(receipt, 'receipt');
          const BCData = receipt.events.productPurchased;
          const returnData = receipt.events.productPurchased.returnValues;
          const updatedProduct = {
            productId: returnData.productId,
            ownerAddress: returnData.ownerAddress,
            ownerEmail: returnData.ownerEmail,
            inStore: returnData.inStore,
            timestamp: returnData.timestamp,
            transactionHash: BCData.transactionHash,
          };
          const transactionDetails = {
            productId: returnData.productId,
            productName: returnData.productName,
            transactionHash: BCData.transactionHash,
            transactionType: 'Purchase',
            previousOwner: returnData.seller,
            currentOwner: returnData.ownerEmail,
            purchaseDate: returnData.timestamp,
            amountinEth: returnData.amount,
            registrationDate: returnData.registrationDate,
          };
          axios
            .put('/purchaseProduct', { updatedProduct })
            .then((res) => {
              axios
                .post('/addTransaction', { transactionDetails })
                .then((res) => {
                  alert('Purchase Succesfful, Product added to your account');
                  window.location.reload();
                });
            })
            .catch((error) => {
              alert('Purchase not successful, Please try again');
            });
        });
    } catch (err) {
      console.log(err);
    }
  };

  const switchNetwork = async () => {
    const chainId = 9000;
    if (window.ethereum.networkVersion != chainId) {
      console.log('ChainId network', window.ethereum.networkVersion);
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
      } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Heroledger',
                chainId: Web3.utils.toHex(chainId),
                nativeCurrency: { name: 'INK', decimals: 18, symbol: 'ETH' },
                rpcUrls: ['https://comicchain.io/'],
              },
            ],
          });
        }
      }
    }
  };

  useEffect(() => {
    const getAccount = async () => {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      userAccount = accounts[0];
      console.log(accounts[0]);
      return accounts[0];
    };
    if (typeof window.ethereum !== 'undefined') {
      console.log('Ethereum is installed');
      const account = getAccount();
      switchNetwork();
      console.log(account);
    }
  }, []);
  return (
    <div
      className="metamask"
      style={{
        backgroundColor: 'transparent',
        width: '100%',
        height: '100%',
      }}
    >
      <button
        onClick={() => {
          sendTransaction();
          execute();
        }}
        style={{
          backgroundColor: 'transparent',
          width: '100%',
          height: '100%',
          border: 'none',
          paddingLeft: '3rem',
          paddingRight: '3rem',
          backgroundColor: '#f2994a',
        }}
        className="btn-primary btn px-5 text-center metamaskBtnPayment"
      >
        Initiate payment
      </button>
    </div>
  );
};
export default Metamask;
//
