/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import EditAssetModal from './EditAssetModal';
import { Link } from 'react-router-dom';
import $ from 'jquery';
// import { getContract } from "../../Redux/actions/contractAction";
// import { useDispatch } from "react-redux";
import MainLayout from '../../Components/MainLayout';
import { Card } from 'react-bootstrap';
import Web3 from 'web3';
import NotInStoreIcon from '../../Assets/Icons/not-in-store-icon.png';
import InStoreIcon from '../../Assets/Icons/in-store-icon.png';
import DeleteAssetIcon from '../../Assets/Icons/delete-asset-icon.png';
import EditAssetIcon from '../../Assets/Icons/edit-asset-icon.png';
import DownloadAssetIcon from '../../Assets/Icons/download-icon.png';
import LoadingSpinner from '../../Components/ui/LoadingSpinner';
import Heroledger from '../../blockchain/abis/heroledger.json';
import { Button } from 'react-bootstrap';
import Footer from '../../Components/shared/footer';
import { ToastContainer, toast } from 'react-toastify';

import { getDate } from './utility';

import Metamask from '../../Components/ui/Metamask';

const serverBaseURL = '';

const productOptions = [
  { id: 0, name: 'Character', value: 'character', type: 'image' },
  { id: 1, name: 'Script', value: 'script', type: 'file' },
  { id: 2, name: 'Logo', value: 'logo', type: 'image' },
  { id: 3, name: 'Backgrounds', value: 'background', type: 'background' },
  { id: 4, name: 'Audio', value: 'audio', type: 'audio' },
  { id: 5, name: 'Video', value: 'video', type: 'video' },
  { id: 6, name: 'Props', value: 'props', type: 'image' },
];

function Assets() {
  const [user, setUser] = useState({});
  const [location, setLocation] = useState({});
  const [contract, setContract] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetLoader, setAssetLoader] = useState(true);
  const [purchaseAssets, setPurchaseAssets] = useState([]);
  const [purchaseAssetLoader, setPurchaseAssetLoader] = useState(true);
  const [editAssetData, setEditAssetData] = useState([]);
  const [account, setAccount] = useState('');
  const [toggleEditAsset, setToggleEditAsset] = useState(false);
  const [productDetails, setProductDetails] = useState({
    name: '',
    description: '',
    terms: '',
    productType: '',
    upload: '',
    price: 0,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [thumbnail, setThumbnail] = useState('');

  const customStyles = {
    cells: {
      style: {
        fontSize: '12.9px',
        padding: 5,
      },
    },
    headCells: {
      style: {
        fontSize: '11.9px',
        fontWeight: 'bold',
        color: '#030303',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F2F2F2',
        padding: 13,
      },
    },
  };

  const columns = [
    {
      name: 'Image',
      selector: 'image',
      center: true,
      width: '8%',
    },
    {
      name: 'Asset Name',
      selector: 'name',
      width: '10%',
      center: true,
    },
    {
      name: 'Hash',
      selector: 'hash',
      center: true,
      width: '10%',
    },
    {
      name: 'Owner',
      selector: 'owner',
      width: '10%',
      sortable: true,
      center: true,
    },
    {
      name: 'Uploaded On',
      selector: 'timestamp',
      sortable: true,
      center: true,
      wrap: true,
      width: '15%',
    },
    {
      name: 'Product Type',
      selector: 'productType',
      sortable: true,
      center: true,
      width: '12%',
    },
    {
      name: 'Price',
      selector: 'price',
      sortable: true,
      center: true,
      wrap: true,
      width: '8%',
    },
    {
      name: 'In Store',
      selector: 'inStore',
      sortable: true,
      center: true,
      width: '9%',
    },
    {
      name: 'Licensing',
      selector: 'licensing',
      sortable: true,
      center: true,
      width: '8%',
    },
    {
      name: 'Actions',
      selector: 'actions',
      sortable: true,
      center: true,
      wrap: true,
      width: '10%',
    },
  ];

  const loadContract = async () => {
    window.web3 = new Web3(
      new Web3.providers.HttpProvider('https://comicchain.io')
    );
    const web3 = window.web3;
    web3.eth.getChainId().then((e) => console.log('chainID' + e));

    const accounts = await web3.eth.getAccounts();
    const index = accounts.length - 1;
    setAccount(accounts[0]);
    const networkId = await web3.eth.net.getId();
    const networkData = Heroledger.networks[networkId];
    if (networkData) {
      const heroledger = await new web3.eth.Contract(
        Heroledger.abi,
        networkData.address
      );
      setContract(heroledger);
      console.log(heroledger);
    }
  };

  const getUserDetails = async () => {
    const user = await JSON.parse(localStorage.getItem('user'));
    setUser(user);
    const email = user.email;

    const location = await JSON.parse(localStorage.getItem('location'));
    location && setLocation(location);

    axios
      .get(serverBaseURL + '/getUserAssets', { params: { email } })
      .then((res) => {
        const assetData = res.data.data.map((ele) => {
          return {
            image: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => editAsset(ele.productId)}
              >
                {getImage(ele.productType, ele.image)}
              </div>
            ),
            name: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => editAsset(ele.productId)}
              >
                {ele.productName}
              </div>
            ),
            hash: (
              <a
                href={`https://kovan.etherscan.io/tx/${ele.transactionHash}`}
                target="_blank"
              >
                {ele.transactionHash}
              </a>
            ),
            owner: ele.ownerEmail,
            timestamp: getDate(ele.timestamp),
            productType: ele.productType,
            price: `$ ${ele.priceinUsd}`,
            inStore: ele.InStore ? (
              <img
                src={InStoreIcon}
                alt="In store icon"
                style={{ height: '15px', width: '15px' }}
              />
            ) : (
              <img
                src={NotInStoreIcon}
                alt="Not in store icon"
                style={{ height: '15px', width: '15px' }}
              />
            ),
            licensing: ele.license ? (
              <img
                src={InStoreIcon}
                alt="In store icon"
                style={{ height: '15px', width: '15px' }}
              />
            ) : (
              <img
                src={NotInStoreIcon}
                alt="Not in store icon"
                style={{ height: '15px', width: '15px' }}
              />
            ),
            actions: (
              <div className="d-flex justify-content-between">
                <button
                  className="btn border-0"
                  disabled={ele.ownerEmail !== user.email}
                  onClick={() => editAsset(ele.productId)}
                >
                  <img
                    src={EditAssetIcon}
                    alt="Edit Asset Icon"
                    style={{ height: '15px', width: '15px' }}
                  />
                </button>
                &nbsp;
                {/* <button
                className="btn border-0"
                onClick={() => deleteAsset(ele.productId)}
              >
                <img
                  src={DeleteAssetIcon}
                  alt="Delete Asset Icon"
                  style={{ height: "15px", width: "15px" }}
                />
              </button> */}
              </div>
            ),
          };
        });
        setAssets(assetData);
        setAssetLoader(false);
      });
    axios
      .get(serverBaseURL + '/getPurchasedUserAssets', { params: { email } })
      .then((res) => {
        const purchaseAssetData = res.data.data
          .filter((ele) => ele.originatorEmail !== ele.ownerEmail)
          .map((ele) => {
            return {
              image: (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => editAsset(ele.productId)}
                >
                  {getImage(ele.productType, ele.image)}
                </div>
              ),
              name: (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => editAsset(ele.productId)}
                >
                  {ele.productName}
                </div>
              ),
              hash: (
                <a
                  href={`https://kovan.etherscan.io/tx/${ele.transactionHash}`}
                  target="_blank"
                >
                  {ele.transactionHash}
                </a>
              ),
              owner: ele.ownerEmail,
              timestamp: getDate(ele.timestamp),
              productType: ele.productType,
              price: `$ ${ele.priceinUsd}`,
              inStore: ele.InStore ? (
                <img
                  src={InStoreIcon}
                  alt="In store icon"
                  style={{ height: '15px', width: '15px' }}
                />
              ) : (
                <img
                  src={NotInStoreIcon}
                  alt="Not in store icon"
                  style={{ height: '15px', width: '15px' }}
                />
              ),
              licensing: ele.license ? (
                <img
                  src={InStoreIcon}
                  alt="In store icon"
                  style={{ height: '15px', width: '15px' }}
                />
              ) : (
                <img
                  src={NotInStoreIcon}
                  alt="Not in store icon"
                  style={{ height: '15px', width: '15px' }}
                />
              ),
              actions: (
                <div className="d-flex justify-content-between">
                  {/* onClick={() => editAsset(ele.productId)} */}
                  <button className="btn border-0" disabled>
                    <img
                      src={EditAssetIcon}
                      alt="Edit Asset Icon"
                      style={{ height: '15px', width: '15px' }}
                    />
                  </button>
                  &nbsp;
                  <a
                    className="btn border-0"
                    download
                    href={`/image/${ele.image}`}
                  >
                    <img
                      src={DownloadAssetIcon}
                      alt="Download Asset Icon"
                      style={{ height: '15px', width: '15px' }}
                    />
                  </a>
                </div>
              ),
            };
          });

        setPurchaseAssets(purchaseAssetData);
        setPurchaseAssetLoader(false);
      });
  };
  const editAsset = async (productId) => {
    await axios
      .get(serverBaseURL + '/getSingleProduct', { params: { productId } })
      .then((res) => {
        setEditAssetData(res.data.data);
      });
    setToggleEditAsset(true);
  };

  const deleteAsset = (productId) => {
    console.log(productId + 'delete');
  };

  const addProductPostRequest = (product) => {
    const formData = new FormData();
    formData.append('image', productDetails.upload);
    axios.post(serverBaseURL + '/addUpload', formData).then((res) => {
      product.image = res.data.file.filename;
      axios
        .post(serverBaseURL + '/addProduct', { product })
        .then((res) => {
          $('#register-asset').modal('hide');
          alert('Your Product has been successfully Registered');
          window.location.reload();
        })
        .catch((err) => {
          console.log(err, 'error');
          alert(
            'Product Registration failed. Please try again after few minutes'
          );
        });
    });
  };

  const addProduct = async (name, type, email, price) => {
    const ownerAddress = localStorage.getItem('cosmosaddress');
    console.log(ownerAddress);
    contract.methods
      .createProduct(name, type, email, price, false, false, ownerAddress)
      .send({ from: account, gas: 6000000, gasPrice: 6000000 })
      .once('receipt', (receipt) => {
        console.log(receipt, ' reciept');
        if (Object.keys(receipt.events).length !== 0) {
          const blockchainData = receipt.events.productCreated.returnValues;
          const product = {
            productId: blockchainData.productId,
            originatorEmail: blockchainData.originator,
            ownerEmail: blockchainData.ownerEmail,
            ownerAddress: ownerAddress,
            productName: blockchainData.productName,
            description: productDetails.description,
            productType: blockchainData.productType,
            price: blockchainData.price,
            transactionHash: receipt.transactionHash,
            blockHash: receipt.blockHash,
            timestamp: blockchainData.timestamp,
            // image: productDetails.upload,
            inStore: blockchainData.inStore,
            license: blockchainData.license,
            fullDescription: '',
            priceinUsd: 0.01,
            latitude: location.latitude || '',
            longitude: location.longitude || '',
            state: location.state || '',
            city: location.city || '',
            countryName: location.countryName || '',
            countryCode: location.countryCode || '',
          };
          addProductPostRequest(product);
        } else {
          alert(
            'Product Registration failed. Please try again after few minutes'
          );
          window.location.reload();
        }
      })
      .on('error', (err) => {
        console.log(err, 'error');
      });
  };

  const getImage = (characterType, image) => {
    if (characterType == 'audio') {
      return (
        <div>
          <img
            className="rounded-circle"
            src={require('../../Assets/Images/music.png')}
            width="40"
            height="40"
          />
        </div>
      );
    } else if (characterType == 'video') {
      return (
        <div>
          <img
            className="rounded-circle"
            src={require('../../Assets/Images/video.jpeg')}
            width="40"
            height="40"
          />
        </div>
      );
    } else if (characterType == 'script') {
      return (
        <div>
          <img
            className="rounded-circle"
            src={require('../../Assets/Images/doc.jpeg')}
            width="40"
            height="40"
          />
        </div>
      );
    } else {
      return (
        <div>
          <img
            className="rounded-circle"
            src={`/image/${image}`}
            width="40"
            height="40"
          />
        </div>
      );
    }
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1148576) {
        alert(
          'In Beta version you need to upload file less than or equal to 1 MB'
        );
      } else {
        setProductDetails({ ...productDetails, upload: file });
        setThumbnail(file.name);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      productDetails.name === '' ||
      productDetails.description === '' ||
      productDetails.productType === '' ||
      productDetails.upload === ''
    ) {
      setShowPopup(true);
    } else {
      setShowPopup(false);

      setSubmitLoader(true);
      const resp = await addProduct(
        productDetails.name,
        productDetails.productType,
        user.email,
        productDetails.price
      );
      console.log('resp', resp);
    }
  };

  const handleSetInputValue = () => {
    const event = new Event('input', { bubbles: true });
    const nameForm = document.getElementById('name');
    const description = document.getElementById('description');
    const prodType = document.getElementById('productType');
    nameForm.value = '';
    description.value = '';
    prodType.value = '';
    nameForm.dispatchEvent(event);
    description.dispatchEvent(event);
    prodType.dispatchEvent(event);
  };

  useEffect(() => {
    loadContract();
    getUserDetails();
  }, []);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getContract());
  // }, [contract, dispatch]);
  const [crumbs, setCrumbs] = useState([{ name: 'Home', route: '/overview' }]);
  return toggleEditAsset ? (
    <MainLayout pageTitle="Edit Asset" crumbs={crumbs} setCrumbs={setCrumbs}>
      <EditAssetModal
        data={editAssetData}
        setToggleEditAsset={setToggleEditAsset}
      />
    </MainLayout>
  ) : (
    <MainLayout pageTitle="Assets" crumbs={crumbs} setCrumbs={setCrumbs}>
      {/* <PaypalLogin /> */}
      {/* <Metamask /> */}
      <ToastContainer />
      <div className="container" style={{ minHeight: '70vh' }}>
        <div className="my-2">
          <button
            className="btn btn-primary ml-auto mr-2"
            data-toggle="modal"
            data-target="#register-asset"
          >
            New Asset
          </button>
        </div>
        <div>
          {assetLoader ? (
            <LoadingSpinner />
          ) : assets.length > 0 ? (
            <Card>
              <Card.Body className="p-0">
                <h5 className="py-2 px-4 my-2">Registered Assets</h5>
                <DataTable
                  noHeader
                  columns={columns}
                  data={assets}
                  customStyles={customStyles}
                  pagination={true}
                  responsive={true}
                  paginationPerPage={5}
                  noDataComponent={
                    <div className="spinner-border text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                />
              </Card.Body>
            </Card>
          ) : (
            <h5 className="text-center text-warning">
              No Registered Assets Found
            </h5>
          )}
        </div>
        <hr />
        <div>
          {purchaseAssetLoader ? (
            <LoadingSpinner />
          ) : purchaseAssets.length > 0 ? (
            <Card>
              <Card.Body className="p-0">
                <h5 className="py-2 px-4 my-2">Purchased Assets</h5>
                <DataTable
                  noHeader
                  columns={columns}
                  data={purchaseAssets}
                  customStyles={customStyles}
                  pagination={true}
                  responsive={true}
                  paginationPerPage={5}
                  noDataComponent={
                    <div className="spinner-border text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                />
              </Card.Body>
            </Card>
          ) : (
            <h5 className="text-center text-warning">
              No Purchased Assets Found
            </h5>
          )}
        </div>
      </div>
      {/* Asset Registration Modal */}
      <div className="modal fade" id="register-asset">
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title">Register your Comic Asset</h4>
                <button
                  id="close"
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={handleSetInputValue}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body asset-modal-body">
                <div>
                  <label htmlFor="name">Product Name</label>
                  <input
                    id="name"
                    defaultValue=""
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        name: e.target.value,
                      })
                    }
                    required
                    maxLength="20"
                  />
                </div>
                <div>
                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div>
                  <label htmlFor="productType">Product type</label>
                  <select
                    id="productType"
                    name="productType"
                    onChange={(e) =>
                      setProductDetails({
                        ...productDetails,
                        productType: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select an option</option>
                    {productOptions.map((ele, ind) => (
                      <option key={ele + ind} value={ele.value}>
                        {ele.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    className="btn primary-btn text-center d-flex justify-content-center"
                    htmlFor="upload"
                  >
                    <i className="fa fa-upload"></i>&nbsp;Upload
                  </label>
                  <input
                    type="file"
                    id="upload"
                    onChange={handleUpload}
                    accept="*"
                    required
                    hidden
                  />
                </div>
                {thumbnail && (
                  <div className="text-success text-center">
                    <small>
                      Uploaded{' '}
                      <span className="text-secondary">{thumbnail}</span>{' '}
                      Successfully
                    </small>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                {showPopup && (
                  <div style={{ color: 'red' }}>Required fields missing.</div>
                )}
                <Button
                  variant="primary"
                  type="submit"
                  className="btn"
                  disabled={submitLoader}
                  onClick={handleSubmit}
                >
                  {submitLoader ? 'Registering...' : 'Confirm'}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  className="btn"
                  data-dismiss="modal"
                  id="cancel"
                  onClick={handleSetInputValue}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* End of Modal */}
      <Footer />
    </MainLayout>
  );
}

export default Assets;
