/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { getContract } from '../../Redux/actions/contractAction';
import LoadingSpinner from '../../Components/ui/LoadingSpinner';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';
import OTPpage from './OTPpage';
// Styles are in pre-login1

const serverBaseURL = '';
function AccountRecoveryPage() {
  const dispatch = useDispatch();
  let history = useHistory();

  useSelector((state) => state.contract);

  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
  });

  const [otpInfo, setOtpInfo] = useState({
    email: '',
    _id: '',
  });

  const [otpInput, setOtpInput] = useState();
  const [token, setToken] = useState('');

  const [page, setPage] = useState(0);

  const [loader, setLoader] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (page === 0) submitEmail();
    else if (page === 1) submitOTP();
    else submitPassword();
    //     setLoader(true);
    //     axios.post(serverBaseURL + "/changePw1", {
    //       email: userDetails.email,
    //     });
    // await axios
    //   .post(`/login`, { userDetails })
    //   .then(async (res) => {
    //     if (res.data.error === "Not verified") {
    //       setOpenOTPpage(true);
    //       setOtpInfo({ ...otpInfo, email: res.data.email, _id: res.data._id });
    //     } else {
    //       const userResponseDetails = {
    //         name: res.data.user.name,
    //         email: res.data.user.email,
    //         image: res.data.user.image,
    //         token: res.data.token,
    //         remember: userDetails.remember,
    //       };
    //       localStorage.setItem("token", res.data.token);
    //       localStorage.setItem("user", JSON.stringify(userResponseDetails));
    //       localStorage.setItem("cosmosaddress", res.data.user.cosmosaddress);
    //       localStorage.setItem("countrycode", res.data.user.countryCode);
    //       localStorage.setItem("countryname", res.data.user.countryName);

    //       // history.push("/overview");
    //       window.location.pathname = "/overview";
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     // alert("Incorrect Email and Password combination!!. Try again");
    //     toast.warn("Incorrect Email and Password combination!!. Try again", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       hideProgressBar: true,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     setLoader(false);
    //   });
  };
  const submitOTP = () => {
    setLoader(true);
    axios
      .post(serverBaseURL + '/changePw2', {
        OTP: otpInput,
        email: userDetails.email,
      })
      .then((res) => {
        if (res.data.error) {
          if (res.data.reason === 'Invalid OTP') {
            setOtpInput();
          }
          toast.warn(res.data.reason);
        } else {
          setToken(res.data.token);
          setPage(2);
        }
        setLoader(false);
      })
      .catch((err) => {
        toast.warn(
          'There was some error in our server, please reload and try again'
        );
        setLoader(false);
      });
  };
  const submitEmail = () => {
    setLoader(true);
    axios
      .post(serverBaseURL + '/changePw1', {
        email: userDetails.email,
      })
      .then((res) => {
        if (res.data.error) {
          toast.warn(res.data.reason);
        } else {
          setPage(1);
        }
        setLoader(false);
      })
      .catch((err) => {
        toast.warn(
          'There was some error in our server, please reload and try again'
        );
        setLoader(false);
      });
  };

  const submitPassword = () => {
    setLoader(true);
    axios
      .post(serverBaseURL + '/changePw3', {
        email: userDetails.email,
        password: userDetails.password,
        token: token,
      })
      .then((res) => {
        if (res.data.error) {
          toast.warn(res.data.reason);
        } else {
          console.log('DONE');
          window.location.pathname = '/';
        }
        setLoader(false);
      })
      .catch((err) => {
        toast.warn(
          'There was some error in our server, please reload and try again'
        );
        setLoader(false);
      });
  };

  return (
    <div className="container-fluid pre-login-home">
      <div className="row pre-login-card">
        <ToastContainer />
        <div className="col-md-7 img-left d-flex">
          <span className="m-auto text-center">
            <img
              style={{ width: '350px' }}
              alt="HeroLedger Logo"
              className="img-fluid"
              src={require('../../Assets/updated/HeroledgerLogoEye.png')}
            />
            <br />

            <h1
              style={{ position: 'relative' }}
              className="tag-line  text-white pb-4 font-weight-bold "
            >
              A MULTIVERSE!<sup>TM</sup> &nbsp;APPLICATION
            </h1>
          </span>
          <div className="d-flex align-self-end justify-content-start version-text mb-2 text-white">
            Version Beta
          </div>
        </div>
        <div className="col-md-5 card-back flex-row mx-auto px-0 d-flex align-items-center">
          <LoginPage
            setUserDetails={setUserDetails}
            userDetails={userDetails}
            loader={loader}
            page={page}
            setPage={setPage}
            otpInput={otpInput}
            setOtpInput={setOtpInput}
            submitEmail={submitEmail}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

const LoginPage = ({
  handleSubmit,
  setUserDetails,
  userDetails,
  loader,
  otpInput,
  setOtpInput,
  page,
  setPage,
  submitEmail,
}) => {
  return (
    <>
      <div className="pre-login-card-body card-body">
        <h4 className="title text-center mt-2 font-weight-bold">
          <span style={{ color: '#ef992f' }}>Account recovery</span> for
          Heroledger
        </h4>
        <form className="pre-login-form-box px-3" onSubmit={handleSubmit}>
          <div className="form-input">
            <span>
              <i className="fa fa-envelope-o"></i>
            </span>
            {page === 0 ? (
              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
              />
            ) : page === 1 ? (
              <OTPINPUT
                otpInput={otpInput}
                setOtpInput={setOtpInput}
                email={'bimalpandey32@gmail.com'}
                submitEmail={submitEmail}
              />
            ) : (
              <>
                <input
                  type="password"
                  name="password"
                  placeholder="New Password"
                  required
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                />
              </>
            )}
          </div>

          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-block text-uppercase"
              onClick={() => {}}
            >
              {loader ? (
                <LoadingSpinner />
              ) : (
                <>
                  <i className="fas fa-sign-in-alt"></i> Next
                </>
              )}
            </button>
          </div>
          {/* <div className="text-right font-12">
            <button href="#" className="forget-link btn btn-default btn-sm">
              <small className="font-weight-bold">Forgot Password?</small>
            </button>
          </div> */}

          <hr className="my-2" />
          <div className="text-center font-12 mb-1 font-weight-bold text-white">
            Don't have an account?{' '}
            <Link to="/register" className="text-primary font-12">
              Register here
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

const OTPINPUT = ({ otpInput, setOtpInput, email }) => {
  return (
    <>
      <div className="otpContainer">
        <div className="otpContainer_txt">
          Please enter the verification code sent to{' '}
          <span style={{ fontSize: '16px', color: 'orange' }}>{email}</span>
        </div>
        <div className="otpContainer_otp">
          <OtpInput
            numInputs={6}
            value={otpInput}
            onChange={(e) => setOtpInput(e)}
            inputStyle={'otpInputReset'}
            focusStyle={{
              border: '1px solid white',
              color: 'orange',
              borderRadius: '0px',
            }}
            isInputNum={true}
            shouldAutoFocus={true}
          />
        </div>
      </div>
    </>
  );
};

export default AccountRecoveryPage;
