import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <br />
      <div className="terms">
        <div className="terms_con">
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            General Information
          </h2>
          <p>
            We collect the e-mail addresses of those who communicate with us via
            e-mail, aggregate information on what pages consumers access or
            visit, and information volunteered by the consumer (such as survey
            information and/or site registrations). The information we collect
            is used to improve the content of our Web pages and the quality of
            our service, and is not shared with or sold to other organizations
            for commercial purposes, except to provide products or services
            you've requested, when we have your permission, or under the
            following circumstances:
          </p>
          <p>
            <ul>
              <li>
                It is necessary to share information in order to investigate,
                prevent, or take action regarding illegal activities, suspected
                fraud, situations involving potential threats to the physical
                safety of any person, violations of{" "}
                <a
                  href="http://localhost:3000/terms"
                  style={{ textDecoration: "underline", color: "blue" }}
                >
                  Terms of Service
                </a>
                , or as otherwise required by law.
              </li>
              <br />
              <li>
                We transfer information about you if Rast·r Technologies
                (Rast·r) and/or the HeroLedger application, its websites and
                services are acquired by or merged with another company. In this
                event, Rast·r will notify you before information about you is
                transferred and becomes subject to a different privacy policy.
              </li>
            </ul>
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Information Gathering and Usage
          </h2>
          <p>
            <ul>
              <li>
                When you register for HeroLedger we ask for information such as
                your name, email address, mobile number and country.
              </li>
              <br />
              <li>
                To make a purchase or receive payment we ask for information
                such as your billing address, credit card information and public
                crypto wallet address.
              </li>
              <br />
              <li>
                Rast·r uses collected information for the following general
                purposes: products and services provision, billing,
                identification and authentication, services improvement,
                contact, and research.
              </li>
            </ul>
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Device Information
          </h2>
          <p>
            We may collect information about the computer, mobile device or
            other device you use to access the Services such as IP address,
            geolocation information, unique device identifiers, browser type,
            browser language or other information of this nature. We use this
            information in an aggregate fashion to track access to the Services.
          </p>
          <p>
            IP Address. Your IP address is automatically assigned to your device
            by your Internet Service Provider. An IP address may be identified
            and logged automatically in our server log files whenever a user
            accesses the Services, along with the time of the visit and the
            page(s) that were visited. Collecting IP addresses is standard
            practice and is done automatically by many websites, applications
            and other services. We use IP addresses for several purposes, such
            as security, calculating usage levels, and diagnosing server
            problems. We also use your IP address to administer our Services by
            identifying (1) which parts of a Service are most heavily used, (2)
            where our users come from (i.e., a referring sign or a search
            engine, etc.), and (3) which portion of our audience comes from
            within or outside the Rast·r network. We may also derive your
            approximate location from your IP address. We do not link IP
            addresses to anything personally identifiable unless we are required
            to do so under applicable law or court order or authorized to do so
            under our policies.
          </p>
          <p>
            Physical Location. We may use your device’s physical location to
            provide you with personalized location-based services and content.
            We may also share your device’s physical location with our service
            providers to enable them to provide you with more personalized
            content and to study the effectiveness of advertising campaigns. In
            some instances, you may be permitted to allow or deny such uses
            and/or sharing of your device’s location, but if you do, we and/or
            our service providers may not be able to provide you with the
            applicable personalized services and content.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Cookies
          </h2>
          <p>
            <ul>
              <li>
                A cookie is a small amount of data, which often includes an
                anonymous unique identifier, that is sent to your browser from a
                web site's computers and stored on your computer's hard drive.
              </li>
              <br />
              <li>
                We do not use cookies to record current session information.
                Cookies are not required to use the HeroLedger website service.
              </li>
              <br />
              <li>
                You are required to re-login to your HeroLedger account after a
                certain period of time has elapsed to protect you against others
                accidentally accessing your account contents.
              </li>
            </ul>
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Data Storage
          </h2>
          <p>
            Rast·r uses third party vendors and hosting partners to provide the
            necessary hardware, software, networking, storage, and related
            technology required to run HeroLedger. Although Rast·r owns the
            code, databases, and all rights to the HeroLedger application, you
            retain all rights to your data.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Disclosure
          </h2>
          <p>
            Rast·r may disclose personally identifiable information under
            special circumstances, such as to comply with subpoenas or when your
            actions violate the{" "}
            <a
              href="http://localhost:3000/terms"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              {" "}
              Terms of Service
            </a>
            .
          </p>
          <br />
          <p>
            If you choose to provide Rast·r with your information, you consent
            to the transfer and storage of that information on our servers
            located in the United States.
          </p>
          <p>
            For any questions or concerns regarding the use or disclosure of
            your information should be directed to Rast·r by sending an email
            to:{" "}
            <a href="mailto:privacy@rastr.io" style={{ color: "blue" }}>
              {" "}
              privacy@rastr.io
            </a>
            . We will investigate and attempt to resolve complaints and disputes
            regarding use and disclosure of your information in accordance with
            this Privacy Policy. We have committed to cooperate with data
            protection authorities located around the world (or their authorized
            representatives).
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Information We Collect from Others
          </h2>
          <p>
            In addition to the information described under Information Gathering
            and Device Information above, we may obtain personal information
            about you from third parties.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Partners and Service Providers
          </h2>
          <p>
            We use partners and service providers, such as licensing
            agents/firms, payment processors and analytics providers, to perform
            services on our behalf. Some of these partners have access to
            personal information about you that we may not otherwise have (for
            example, if you sign up directly with that provider) and may share
            some or all of this information with us. We use this information to
            administer our services and conduct marketing and advertising
            campaigns, as well as to process transactions that you request.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Supplemental Information
          </h2>
          <p>
            We may receive additional personal information from third-party
            sources, such as credit reference agencies and public or private
            databases, or companies or institutions that may sponsor or
            facilitate your participation in one of our programs, which we may
            append to existing personal information, such as email address
            verification. We may use this supplemental information to process
            transactions that you request and to prevent fraud, deliver relevant
            offers and advertising to you, and to improve our operations,
            services and our advertising and marketing campaigns.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Will Rast·r Share My Information with Others?
          </h2>
          <p>
            We may share your personal information within our community of legal
            and financial service affiliates. User information may also be
            shared with third-party partners to the extent necessary for such
            third parties to provide services to us or to users of our services
            or provide and improve our Services or other communications to
            users. Any third parties who receive user information for this
            purpose are prohibited from sending or sharing user information for
            any purpose other than providing services to Rast·r or to users. We
            may also provide your information to third parties in circumstances
            where we believe that doing so is necessary or appropriate to
            satisfy any applicable law, regulation, legal process or
            governmental request; to enforce our rights, to detect, prevent or
            otherwise address fraud, security or technical issues; or to protect
            the rights, property or safety of us, our users or others.
            Additional information about how we may share your information is
            provided below.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Service Providers
          </h2>
          <p>
            <ul>
              <li>
                We share your information with third-party service providers who
                complete transactions or perform services on our behalf or for
                your benefit, such as:
              </li>
              <br />
              <li>Payment processing</li>
              <br />
              <li>Marketing and analytics</li>
              <br />
              <li>Processing employment applications</li>
              <br />
              <li>Event registration and coordination</li>
              <br />
              <li>Business operations and collaborations</li>
              <br />
              <li>Research insights and analytics</li>
              <br />
              <li>
                Performing human resources administration, including for
                employee benefits
              </li>
              <br />
              <li>
                Customer relationship management (CRM) services, such as for
                HeroLedger Support Desk Services
              </li>
              <br />
              <li>System maintenance and security</li>
              <br />
              <li>Partners</li>
            </ul>
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            We may share your information with other institutions and
            organizations for the purposes of administering programs and
            services, such as:
          </h2>
          <p>
            <ul>
              <li>Research arrangements with other companies and partners</li>
              <br />
              <li>Conference, workshop and similar proceedings</li>
              <br />
              <li>
                Events and activities with clubs and special interest groups
              </li>
              <br />
              <li>Third-Party Mobile App Providers</li>
            </ul>
          </p>
          <p>
            Our Services on your mobile device may gather and transfer your
            information, including location information, from and to other
            applications, functions and tools within your mobile device.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Legal Process, Safety and Terms Enforcement
          </h2>
          <p>
            We may disclose your information to legal or government regulatory
            authorities as required by applicable law. We may also disclose your
            information to third parties as required by applicable law in
            connection with claims, disputes or litigation, when otherwise
            required by applicable law, or if we determine its disclosure is
            necessary to protect the health and safety of you or us, or to
            enforce our legal rights or contractual commitments that you have
            made.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            User Generated Content
          </h2>
          <p>
            Some of our Services may enable users to submit their own content
            for marketing, advertisements, contests, blogs, videos, and other
            functions. Unless otherwise indicated, please remember that any
            information you submit or post as user-generated content to our
            Services may become public information. You should exercise caution
            when deciding to disclose your personal, financial or other
            information in such submissions or posts. We cannot prevent others
            from using such information in a manner that may violate this
            Privacy Statement, the law, or your personal privacy and safety. We
            are not responsible for the results of such postings.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Links to Third Party Sites and Social Media
          </h2>
          <p>
            The Services may include links to websites, applications and digital
            services operated by third parties. This Privacy Statement does not
            apply to, and we are not responsible for the content, privacy
            policies or data practices of third parties that collect your
            information. We encourage you to review the privacy policies for
            those third parties to learn about their information practices.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Services Used by Minors
          </h2>
          <p>
            The Services are not directed to individuals under the age of
            thirteen (13), and we do not knowingly collect personal information
            from individuals under 13.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Personal Information of Persons outside the United States
          </h2>
          <p>
            The Services are published in the United States and are subject to
            the laws of the United States. If you are located in a country
            outside the United States and voluntarily submit personal
            information to us, your information will be used as provided in this
            Privacy Statement and will be transferred to, and/or be stored in,
            the United States. This Privacy Policy also pertains to personal
            information that may be subject to Privacy Laws that include notice
            and other requirements that may apply to your personal data. Among
            those Privacy Laws are the EEA GDPR the UK and other countries.{" "}
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Changes
          </h2>
          <p>
            Rast·r may periodically update this policy. We will notify you about
            significant changes in the way we treat personal information by
            sending a notice to the primary email address specified in your
            HeroLedger account or by placing a prominent notice on our site.
          </p>
          <h2 className="terms_con_1" style={{ bold: "900" }}>
            Questions
          </h2>
          <p>
            Any questions about this Privacy Policy should be addressed to{" "}
            <a href="mailto:support@rastr.io" style={{ color: "blue" }}>
              {" "}
              support@rastr.io
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
