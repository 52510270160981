import React, { useState } from 'react';
// import  {MultiStepForm}  from 'react-multi-form';
import CreditCard from '../../Assets/Icons/payment/credit-card.png';
import Paypal from '../../Assets/Icons/payment/paypal.png';
import Cryptocurrency from '../../Assets/Icons/payment/crypto-currency.png';
import ArbiterToken from '../../Assets/Icons/payment/arbiter-token.png';
import PaypalLogo from '../../Assets/Icons/payment/paypal-logo.svg';
import PaypalInfo from '../../Assets/Icons/payment/paypalInfo.png';
import Bitcoin from '../../Assets/Icons/payment/bitcoin.png';
import Coin2 from '../../Assets/Icons/payment/coin2.png';
import Coin3 from '../../Assets/Icons/payment/coin3.png';
import { toast } from 'react-toastify';

import IconCart from '../../Assets/Icons/icon-cart.png';
// import ArbiterStepForms from '../Home/PaymentScreens/ArbiterStepForms';

import {
  TabContainer,
  Logo,
  TabTitle,
  TabHeader,
  TabContent,
  Heading,
  Body,
} from './PaymentElements';
import PaypalButton from '../../Components/ui/Paypal';
import Metamask from '../../Components/ui/Metamask';
import CoinbaseButton from '../../Components/ui/Coinbase';

const Setting = ({ handleBuyAction, price, productId }) => {
  const [toggleState, setToggleState] = useState(2);
  const toggle = (index) => {
    setToggleState(index);
  };
  const [active, setActive] = React.useState(1);

  return (
    <div className="container">
      <TabContainer>
        <TabHeader>
          {/* <TabTitle
              show={toggleState === 1 ? true : false}
              onClick={() => toggle(1)}
            >
              <div className="row">
                <div className="col-2">
                  <img src={CreditCard} alt="Credit Card" />
                </div>
                <div className="col">
                  Credit Card
                </div>
              </div>

            </TabTitle> */}
          <TabTitle
            show={toggleState === 2 ? true : false}
            onClick={() => toggle(2)}
          >
            <div className="row">
              <div className="col-2">
                <img src={Paypal} alt="Paypal" />
              </div>
              <div className="col">Paypal</div>
            </div>
          </TabTitle>
          <TabTitle
            show={toggleState === 3 ? true : false}
            onClick={() => toggle(3)}
          >
            <div className="row">
              <div className="col-2">
                {/* <img src={Cryptocurrency} alt="about" /> */}
                <div
                  className="metamaskConnect"
                  style={{
                    backgroundColor: 'transparent',
                    width: '26px',
                    height: '26px',
                  }}
                  // style={{ width: "26px", height: "26px" }}
                  onClick={() => {
                    const getAccount = async () => {
                      const accounts = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                      });
                      console.log(accounts[0]);
                      return accounts[0];
                    };
                    if (typeof window.ethereum !== 'undefined') {
                      console.log('Ethereum is installed');
                      const account = getAccount();
                      console.log(account);
                    } else {
                      toast.warn(
                        'Metamask is not installed, please download the extension for your browser'
                      );
                    }
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/64px-MetaMask_Fox.svg.png"
                    className="metamaskButton"
                    alt="Connect to Metamask"
                    // width={20}
                    // height={20}
                    style={{ width: '26px', height: '26px' }}
                  />
                </div>
              </div>
              <div className="col">Metamask</div>
            </div>
          </TabTitle>
          <TabTitle
            show={toggleState === 4 ? true : false}
            onClick={() => toggle(4)}
          >
            <div className="row">
              <div className="col-2">
                <img src={ArbiterToken} alt="about" />
              </div>
              <div className="col">Coinbase</div>
            </div>
          </TabTitle>
        </TabHeader>
        {/* <TabContent show={toggleState === 1 ? true : false}>
          <Heading>Pay with Credit Card</Heading>
          <Body>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <div className="small">Billing Address 1</div>
                  <input></input>
                </div>
                <div className="form-group">
                  <div className="small">Billing Address 2</div>
                  <input></input>
                </div>
                <div className="form-group">
                  <div className="small">Zipcode</div>
                  <input></input>
                </div>
                <div className="form-group">
                  <div className="small">City</div>
                  <input></input>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <div className="small">Country</div>
                    <select id="country" name="country">
                      <option value="volvo">USA</option>
                      <option value="saab">India</option>
                      <option value="fiat">Japan</option>
                      <option value="audi">Russia</option>
                    </select>
                  </div>

                  <div className="form-group col-md-4">
                    <div className="small">State/Province</div>
                    <input style={{ width: "135px" }}></input>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="small">Card holder's name</div>
                  <input></input>
                </div>
                <div className="form-group">
                  <div className="small">Card number</div>
                  <input placeholder="xxx-xxx-xxx-xxxx"></input>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <div className="small">CV Code</div>
                    <input
                      style={{ width: "100px" }}
                      placeholder="xxxx"
                    ></input>
                  </div>

                  <div className="form-group col-md-4">
                    <div className="small">Expiration Date</div>
                    <input
                      style={{ width: "135px" }}
                      placeholder="xx/xxxx"
                    ></input>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <div className="btn-primary btn text-center mr-5">
                    <small>Pay US$25.00</small>
                  </div>
                </div>
              </div>
            </div>
          </Body>
        </TabContent> */}
        <TabContent show={toggleState === 2 ? true : false}>
          <Body>
            <div className="container mt-3">
              <div className="row d-flex justify-content-between border p-4 rounded">
                <div className="col-md-6">
                  <div className="row d-flex justify-content-between">
                    <Logo src={PaypalLogo} alt="paypal-logo" />
                    <div>
                      <img
                        style={{ width: '15px' }}
                        src={IconCart}
                        alt="paypal-logo"
                      />
                      <small className="pl-2">${price} USD</small>
                    </div>
                  </div>
                  <hr />
                  <div className="row d-flex justify-content-between mt-3">
                    <h4 style={{ fontWeight: '300' }}>Pay with paypal</h4>
                    <p>English</p>
                  </div>
                  <div className="row">
                    <p>
                      As a member, your eligible purchases are covered by Paypal
                      Purchase Protection
                    </p>

                    {price && (
                      <PaypalButton
                        price={price}
                        description={'This is a random description'}
                        handleBuyAction={handleBuyAction}
                      />
                    )}

                    {/* <form action="POST">
                      <div className="form-group">
                        <div className="small">Email</div>
                        <input placeholder="Email"></input>
                      </div>
                      <div className="form-group">
                        <div className="small">Password</div>
                        <input
                          className="form-control"
                          placeholder="Password"
                        ></input>
                      </div>
                      <div className="d-flex justify-content-center mt-2">
                        <div
                          style={{ backgroundColor: "#007BFF" }}
                          className=" btn btn-block text-center"
                        >
                          <small className="text-white font-weight-bold">
                            Log In
                          </small>
                        </div>
                      </div>
                      <p className="text-center">
                        <small>Having trouble logging in?</small>
                      </p>
                      <div className="horizontal-line">
                        <span>or</span>
                      </div>
                      <div className="d-flex justify-content-center mt-4">
                        <div
                          style={{ backgroundColor: "#DBDBDB" }}
                          className=" btn btn-block text-center"
                        >
                          <small className="font-weight-bold">
                            Pay with Debit or Credit Card
                          </small>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-center">
                  <div className="row d-flex flex-column ">
                    <Logo
                      className="m-auto"
                      bigWidth="true"
                      src={PaypalInfo}
                      alt="paypal-logo"
                    />
                    <br />
                    <h4 className="text-center" style={{ fontWeight: '300' }}>
                      New. Faster. Easier.
                    </h4>
                    <p className="text-center">
                      Welcome to the new PayPal checkout! <br /> The security
                      you rely on - now even faster. <br />
                      It's everything checkout should be.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Body>
        </TabContent>
        <TabContent show={toggleState === 4 ? true : false}>
          <Body>
            <h5 className="mb-3" style={{ fontWeight: '400' }}>
              Pay with Coinbase
            </h5>
            {/* <div className="row d-flex justify-content-between">
              <div className="col-md-4 shadow p-3 rounded">
                <p className="text-center font-weight-bold ">
                  Choose your wallet
                </p>
                <div className="d-flex justify-content-center">
                  <div className="btn-primary btn btn-block text-center">
                    
                    <Metamask />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div className="btn-primary btn btn-block text-center">
                    <small>Login to your preferred wallet</small>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <p className="font-weight-bold">Infos</p>
                <p>
                  Our wallet uses the standard ERC-20 so it is compatible with
                  every system that accepts either ERC-20 type tokens or ERC-721
                  Digital Asset tokens as well as our systems.
                </p>
                <div className="row f-flex justify-content-center">
                  <div>
                    <p>
                      Other currencies <br /> accepted by our system
                    </p>
                  </div>
                  <div>
                    <Logo
                      style={{ width: "40px" }}
                      src={Bitcoin}
                      alt="paypal-logo"
                    />
                    <Logo
                      style={{ width: "32px" }}
                      src={Coin2}
                      alt="paypal-logo"
                    />
                    <Logo
                      className="ml-2"
                      style={{ width: "32px" }}
                      src={Coin3}
                      alt="paypal-logo"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row shadow p-3 rounded mt-5 d-flex flex-column align-items-center justify-content-center">
              <p className="font-weight-bold">Payment infos</p>
              <p>
                To complete your payment, send the amount due to the indicated
                address.
              </p>
              <div className="row w-50 d-flex">
                <div className="col-md-6">
                  <p>Currency</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <p>USD</p>
                </div>
              </div>
              <div className="row w-50 d-flex">
                <div className="col-md-6">
                  <p>Amount due</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <p>
                    {
                      /* {price} USD <br /> */ <span className="ml-4">
                        ${price}
                      </span>
                    }
                  </p>
                </div>
              </div>
              {/* <div className="row w-50 d-flex">
                <div className="col-md-6">
                  <p>Conversion rate</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <p>1 ARBT = 0.01 USD</p>
                </div>
              </div> */}
              <div className="d-flex justify-content-center mt-4">
                {/* <div className="btn-primary btn px-5 text-center"> */}
                {/* <small>Initiate payment</small> */}
                <CoinbaseButton productID={productId} price={price} />
                {/* </div> */}
              </div>
            </div>
          </Body>
        </TabContent>
        <TabContent show={toggleState === 3 ? true : false}>
          <Body>
            <h5 className="mb-3" style={{ fontWeight: '400' }}>
              Pay with Metamask
            </h5>
            {/* <div className="row d-flex justify-content-between">
              <div className="col-md-4 shadow p-3 rounded">
                <p className="text-center font-weight-bold ">
                  Choose your wallet
                </p>
                <div className="d-flex justify-content-center">
                  <div className="btn-primary btn btn-block text-center">
                    <Metamask />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div className="btn-primary btn btn-block text-center">
                    <small>Login to your preferred wallet</small>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <p className="font-weight-bold">Infos</p>
                <p>
                  Our wallet uses the standard ERC-20 so it is compatible with
                  every system that accepts either ERC-20 type tokens or ERC-721
                  Digital Asset tokens as well as our systems.
                </p>
                <div className="row f-flex justify-content-center">
                  <div>
                    <p>
                      Other currencies <br /> accepted by our system
                    </p>
                  </div>
                  <div>
                    <Logo
                      style={{ width: "40px" }}
                      src={Bitcoin}
                      alt="paypal-logo"
                    />
                    <Logo
                      style={{ width: "32px" }}
                      src={Coin2}
                      alt="paypal-logo"
                    />
                    <Logo
                      className="ml-2"
                      style={{ width: "32px" }}
                      src={Coin3}
                      alt="paypal-logo"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row shadow p-3 rounded mt-5 d-flex flex-column align-items-center justify-content-center">
              <p className="font-weight-bold">Payment infos</p>
              <p>
                To complete your payment, send the amount due to the indicated
                address.
              </p>
              <div className="row w-50 d-flex">
                <div className="col-md-6">
                  <p>Currency</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <p>Arbiter Token</p>
                </div>
              </div>
              <div className="row w-50 d-flex">
                <div className="col-md-6">
                  <p>Amount due</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <p>
                    2,000 ARBT <br /> <span className="ml-4">$20.00</span>
                  </p>
                </div>
              </div>
              <div className="row w-50 d-flex">
                <div className="col-md-6">
                  <p>Conversion rate</p>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <p>1 ARBT = 0.01 USD</p>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4">
                {/* <div className="btn-primary btn px-5 text-center"> */}
                {/* <small>Initiate payment</small> */}
                <Metamask
                  price={price}
                  productId={productId}
                  purchaseType="BuyProduct"
                />
                {/* </div> */}
              </div>
            </div>
          </Body>
        </TabContent>
        {/* {
          <TabContent show={toggleState === 4 ? true : false}>
            <Body>
              {/* <MultiStepForm activeStep={active} accentColor="#FF9800">
                //<ArbiterStepForms />
                  //</MultiStepForm> }
              {active !== 1 && (
                <button onClick={() => setActive(active - 1)}>Previous</button>
              )}
              {active !== 3 && (
                <button
                  onClick={() => setActive(active + 1)}
                  style={{ float: "right" }}
                >
                  Next
                </button>
              )}
            </Body>
          </TabContent>
        } */}
      </TabContainer>
    </div>
  );
};

export default Setting;
