/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MainLayout from '../Components/MainLayout';
import Card from '../Components/ui/Card';
import TransactionsCard from '../Components/TransactionsCard';
import FindMe from '../Components/FindMe';
import DummyUser from '../Assets/Images/dummy-user.png';
import LoadingSpinner from '../Components/ui/LoadingSpinner';
import { Button, Modal } from 'react-bootstrap';

const serverBaseURL = '';
function Profile() {
  const [, setImage] = useState('');
  const [, setbase64Image] = useState('');
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: null,
    email: null,
    password: null,
    confirmPassword: null,
    latitude: null,
    longitude: null,
    countryCode: null,
    countryName: null,
    state: null,
    phone: null,
    skills: null,
    website: null,
    address: null,
    profession: null,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (userDetails.password === userDetails.confirmPassword) {
      const registerDetails = {
        name: userDetails.name,
        email: userDetails.email,
        password: userDetails.password,
        latitude: userDetails.latitude,
        longitude: userDetails.longitude,
        countryCode: userDetails.countryCode,
        countryName: userDetails.countryName,
        state: userDetails.state,
        image: userDetails.image,
        phone: userDetails.phone,
        others: JSON.stringify({
          skills: userDetails.skills,
          website: userDetails.website,
          address: userDetails.address,
          profession: userDetails.profession,
        }),
      };
      console.log(registerDetails);
      axios
        .put(serverBaseURL + `/updateuserprofile/${userDetails.email}`, {
          registerDetails,
        })
        .then((res) => {
          // alert("Registration Successful, continue to login");
          setLoader(false);
          location.reload();
        })
        .catch((error) => {
          alert('Update Failed, Please try again');
          setLoader(false);
        });
    } else {
      alert('Passwords dont match');
    }
  };

  // const { countryName, state } = JSON.parse(localStorage.getItem("location"));

  const capitalizeName = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleUpload = (e) => {
    setLoader(true);
    let result;
    let file = e.target.files[0];

    if (file) {
      if (file.size > 1148576) {
        alert('In Beta version you need to upload file less than 1 MB');
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {
          result = reader.result;
          setImage(file);
          setbase64Image(result);
        };

        uploadImage(file);
      }
    }
  };

  const uploadImage = (imageToUpload) => {
    const formData = new FormData();
    formData.append('image', imageToUpload);

    axios.post(serverBaseURL + '/addUpload', formData).then((res) => {
      if (imageToUpload) {
        let updatedUser = {
          email: userDetails.email,
          image: res.data.file.filename,
        };
        axios
          .post(serverBaseURL + '/imageUpload', { updatedUser })
          .then((res) => {
            setTimeout(() => {
              location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error('Upload not successful!!! Please Try again');
          });
      }
    });
  };
  const deleteImage = () => {
    axios
      .post(serverBaseURL + '/imageDelete', { email: userDetails.email })
      .then((res) => {
        console.log(res.data)
        console.log(res.status)

        setTimeout(() => {
          location.reload();
        }, 1000);
      })
      .catch((error) => {
        console.log(error)
        console.error('Deletion not successful! Please Try again');
      });
  }


  const getUserInfo = async () => {
    const { email } = JSON.parse(localStorage.getItem('user'));
    await axios
      .get(serverBaseURL + '/getImage', { params: { email } })
      .then((res) => {
        const userData = res.data.data[0];
        localStorage.setItem('user', JSON.stringify(userData));
        const othersJSON = userData.others
          ? JSON.parse(userData.others)
          : {
            skills: '',
            profession: '',
            address: '',
            website: '',
          };

        setUserDetails({
          ...userDetails,
          email: userData.email,
          name: userData.name,
          image: userData.image,
          phone: userData.phone,
          profession: othersJSON.profession,
          address: othersJSON.address,
          website: othersJSON.website,
        });
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  const [crumbs, setCrumbs] = useState([{ name: 'Home', route: '/overview' }]);
  return (
    <MainLayout pageTitle="Profile" crumbs={crumbs} setCrumbs={setCrumbs}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Card title="Profile Infos">
              <div className="profile-page">
                <div className="profile-page__wrapper row px-3">
                  <div className="profile-page__avatar col-md-4 col-sm-12">
                    {loader ? (
                      <LoadingSpinner />
                    ) : (
                      <div className="profile-image">
                        <img
                          src={
                            userDetails.image
                              ? '/image/' + userDetails.image
                              : DummyUser
                          }
                          alt="User Avatar"
                          className="img-fluid w-100 pr-3 pb-3"
                        />
                      </div>
                    )}
                    <div className="row justify-content-between">
                      {/* <div className="col-3"></div> */}
                      <div className="col-6">
                        <label
                          htmlFor="image"
                          className="btn-primary btn w-100 text-center "
                        >
                          <small>Upload</small>
                        </label>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                          onChange={handleUpload}
                          hidden
                        />
                      </div>
                      <div className="col-6">
                        <button
                          htmlFor="image"
                          className="btn-primary btn w-100 text-center "
                          onClick={deleteImage}
                        >
                          <small>Delete</small>
                        </button>
                      </div>
                      <div className="col-3"></div>
                    </div>
                  </div>
                  <div className="profile-page__infos col-md-8 col-sm-12">
                    <div className="profile-page__infos-name row pb-3 d-flex justify-content-between">
                      <div className="profile-page__infos-value col-4 font-weight-bold">
                        {capitalizeName(userDetails.name)}
                      </div>
                      {userDetails.countryName && (
                        <div className="profile-page__infos-location col-4 text-muted">
                          <i className="fas fa-map-marker-alt"></i>{' '}
                          {userDetails.state + ', ' || ''}
                          {userDetails.countryName}
                        </div>
                      )}
                      <div className="profile-page__infos-edit col-4 text-muted">
                        <Button variant="outline-primary" onClick={handleShow}>
                          Edit Profile
                        </Button>
                      </div>
                      {userDetails.profession && (
                        <div className="profile-page__infos-job col-12 text-primary">
                          {userDetails.profession}
                        </div>
                      )}
                    </div>
                    <div className="profile-page__infos-contact">
                      <div className="profile-page__infos-contact-title font-weight-bold text-uppercase py-3">
                        Contact Information
                      </div>
                      {userDetails.phone && (
                        <div className="profile-page__infos-contact-item row pl-3 pb-3">
                          <div className="profile-page__infos-contact-label font-weight-bold pr-2">
                            Phone Number:
                          </div>
                          <div className="profile-page__infos-contact-value">
                            {userDetails.phone}
                          </div>
                        </div>
                      )}
                      {userDetails.address && (
                        <div className="profile-page__infos-contact-item row pl-3 pb-3">
                          <div className="profile-page__infos-contact-label font-weight-bold pr-2">
                            Address:
                          </div>
                          <div className="profile-page__infos-contact-value">
                            {userDetails.address}
                          </div>
                        </div>
                      )}
                      {userDetails.email && (
                        <div className="profile-page__infos-contact-item row pl-3 pb-3">
                          <div className="profile-page__infos-contact-label font-weight-bold pr-2">
                            Email:
                          </div>
                          <div className="profile-page__infos-contact-value text-primary">
                            <a
                              className="text-primary"
                              href={'mailto:' + userDetails.email}
                            >
                              {userDetails.email}
                            </a>
                          </div>
                        </div>
                      )}
                      {userDetails.website && (
                        <div className="profile-page__infos-contact-item row pl-3 pb-3">
                          <div className="profile-page__infos-contact-label font-weight-bold pr-2">
                            Website:
                          </div>
                          <div className="profile-page__infos-contact-value text-primary">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={'http://' + userDetails.website}
                            >
                              {userDetails.website}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-6 my-3">{/* <TransactionsCard /> */}</div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="pre-login-form-box px-3" onSubmit={handleSubmit}>
            <div className="form-input">
              <span>
                <i className="fa fa-user"></i>
              </span>
              <input
                type="text"
                name="username"
                placeholder="Name"
                value={userDetails.name}
                maxLength={30}
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fa fa-envelope-o"></i>
              </span>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={userDetails.email}
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fas fa-phone"></i>
              </span>
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                value={userDetails.phone}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, phone: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fas fa-laptop"></i>
              </span>
              <input
                type="text"
                name="website"
                placeholder="Website"
                value={userDetails.website}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, website: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fas fa-map-marked-alt"></i>
              </span>
              <input
                type="text"
                name="address"
                placeholder="Address"
                value={userDetails.address}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, address: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fas fa-map-marked-alt"></i>
              </span>
              <input
                type="text"
                name="profession"
                placeholder="Profession"
                value={userDetails.profession}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, profession: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fa fa-key"></i>
              </span>
              <input
                type="password"
                name="password"
                placeholder="Password"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, password: e.target.value })
                }
              />
            </div>
            <div className="form-input">
              <span>
                <i className="fa fa-key"></i>
              </span>
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                required
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    confirmPassword: e.target.value,
                  })
                }
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </MainLayout>
  );
}

export default Profile;
