import React, { useState, useEffect, useRef } from "react";
import MainLayout from "../../Components/MainLayout";
import Card from "../../Components/ui/Card";
import TransactionsCard from "../../Components/TransactionsCard";
import CardWorldMap from "../../Components/shared/CardWorldMap";
import { select } from "d3-selection";
import { line } from "d3-shape";
import Axios from "axios";
import moment from "moment";
import LoadingSpinner from "../../Components/ui/LoadingSpinner";
import Footer from "../../Components/shared/footer";
import axios from "axios";
import publicIp from "public-ip";
import { toast } from "react-toastify";

function Overview() {
  const [salesData, setSalesData] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const svgRef = useRef();

  const getLocation = async () => {
    const ip = await publicIp.v4().then((res) => res);
    // Used https://geolocation-db.com/dashboard# to get location by IP
    // const ip = "200.153.146.238";
    axios
      .get(
        `https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91/${ip}`
      )
      .then((res) => {
        const location = {
          latitude: res.data.latitude,
          longitude: res.data.longitude,
          state: res.data.state,
          city: res.data.city,
          countryName: res.data.country_name,
          countryCode: res.data.country_code,
        };
        localStorage.setItem("location", JSON.stringify(location));
      });
  };

  useEffect(() => {
    if (localStorage.getItem("newLogin") === "true") {
      toast.success("Welcome Back Mighty Hero!");
    }
    localStorage.setItem("newLogin", false);
  }, []);

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    const svg = select(svgRef.current);

    // Curved Line Chart
    const myLine = line()
      .x((value, index) => index * 50)
      .y((value) => 100 - value);

    svg
      .selectAll(".dot")
      .data(chartData)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("cx", function (d, i) {
        return i * 50;
      })
      .attr("cy", function (d) {
        return 100 - d;
      })
      .attr("r", 5);

    svg
      .selectAll("path")
      .data([chartData])
      .join("path")
      .attr("d", (value) => myLine(value))
      .attr("fill", "none")
      .attr("stroke", "orange");

    setLoading(false);
  }, [chartData]);

  useEffect(() => {
    getSalesData();
  }, []);

  const getSalesData = () => {
    const { email } = JSON.parse(localStorage.getItem("user"));
    Axios.get(`/getLastSevenDaysPurchaseByEmail?email=${email}`)
      .then((res) => res.data)
      .then((res) => {
        const data = res.data.map((item) => new Date(item.purchaseDate * 1000));

        let lastSevenDays = [];
        for (let i = 1; i <= 7; i++) {
          lastSevenDays.push(new Date(moment().subtract(i, "days").format()));
        }

        let salesChartData = [];
        lastSevenDays.forEach((day) => {
          const filter = data.filter((item) => {
            return item.getDate() === day.getDate();
          });
          salesChartData.push(filter.length);
        });

        setChartData(salesChartData);

        const lastDaySalesQuantity = salesChartData[salesChartData.length - 1];
        const secondToLastDaySalesQuantity =
          salesChartData[salesChartData.length - 2];
        const sinceLastDay =
          lastDaySalesQuantity === 0 && secondToLastDaySalesQuantity === 0
            ? 0
            : (lastDaySalesQuantity / secondToLastDaySalesQuantity - 1) * 100;

        setSalesData({
          sinceLastDay: Math.round(sinceLastDay * 100) / 100,
          pastSevenDays: data.length,
          tokenBalance: null,
          tokensCollected: null,
        });
      })
      .catch((err) => console.log(err));

    setSalesData({
      sinceLastDay: null,
      pastSevenDays: null,
      tokenBalance: null,
      tokensCollected: null,
    });
  };
  const [crumbs, setCrumbs] = useState([{ name: "Home", route: "/overview" }]);
  const selected = (crumb) => {
    console.log(crumb);
  };

  return (
    <>
      <MainLayout pageTitle="Overview" crumbs={crumbs} setCrumbs={setCrumbs}>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="p-3 col-md-6 col-sm-12">
                <Card
                  title="Earnings Overview"
                  subtitle="(past seven days)"
                  detailsTitle="View details"
                  detailsLink="/mydata"
                  removePadding
                >
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {salesData ? (
                        <div className="my-data">
                          <div className="my-data__chart">
                            <div className="my-data__chart-title px-3">
                              <span>Since last day</span>
                              {salesData.sinceLastDay !== null &&
                              salesData.sinceLastDay !== undefined ? (
                                <span className="my-data__chart-value">
                                  {salesData.sinceLastDay > 0 && (
                                    <i className="fas fa-long-arrow-alt-up text-success"></i>
                                  )}
                                  {salesData.sinceLastDay < 0 && (
                                    <i className="fas fa-long-arrow-alt-down text-danger"></i>
                                  )}
                                  <span className="pl-2">
                                    {salesData.sinceLastDay.toFixed(2)}%
                                  </span>{" "}
                                </span>
                              ) : (
                                <span>No data found.</span>
                              )}
                            </div>
                            <div className="my-data__chart-chart">
                              <svg ref={svgRef}></svg>
                            </div>
                          </div>
                          <div className="my-data__data">
                            <div className="my-data__data-item">
                              <div className="my-data__data-title">
                                Sales (past seven days)
                              </div>
                              <div className="my-data__data-value">
                                {salesData.pastSevenDays
                                  ? salesData.pastSevenDays
                                  : "No data found."}
                              </div>
                            </div>
                            <div className="my-data__data-item">
                              <div className="my-data__data-title">
                                Token Balance
                              </div>
                              <div className="my-data__data-value">
                                {salesData.tokenBalance
                                  ? salesData.tokenBalance
                                  : "No data found."}
                              </div>
                            </div>

                            <div className="my-data__data-item">
                              <div className="my-data__data-title">
                                Tokens Collected
                              </div>
                              <div className="my-data__data-value">
                                {salesData.tokensCollected
                                  ? salesData.tokensCollected
                                  : "No data found."}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span>No data found.</span>
                      )}
                    </>
                  )}
                </Card>
              </div>
              <div className="p-3 col-md-6 col-sm-12">
                <TransactionsCard />
              </div>
              <div className="p-3 col-12">
                <CardWorldMap cardDetailsLinkTitle="false" />
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </MainLayout>
    </>
  );
}

export default Overview;
