import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Heroledger from '../../blockchain/abis/heroledger.json';
// import PaypalLogin from '../../Components/ui/Paypal/paypalPayouts';

import Web3 from 'web3';
// import Navbar from "../../Components/shared/Navbar";
// import { getContract } from "../../Redux/actions/contractAction";
// import { useDispatch } from "react-redux";

import { getDate } from './utility';

const serverBaseURL = '';

function EditAsset(props) {
  const data = props.data[0];
  const [contract, setContract] = useState({});
  const [account, setAccount] = useState('');
  const [editLoader, setEditLoader] = useState(false);

  useEffect(() => {
    loadContract();
  }, []);

  const loadContract = async () => {
    window.web3 = new Web3(
      new Web3.providers.HttpProvider('https://comicchain.io')
    );
    const web3 = window.web3;
    const accounts = await web3.eth.getAccounts();
    console.log(accounts, 'accounts');
    const index = accounts.length - 1;
    setAccount(accounts[0]);
    const networkId = await web3.eth.net.getId();
    const networkData = Heroledger.networks[networkId];
    if (networkData) {
      const heroledger = await new web3.eth.Contract(
        Heroledger.abi,
        networkData.address
      );
      setContract(heroledger);
    }
  };

  const [editDetails, setEditDetails] = useState({
    price: 0.01,
    license: data.license,
    inStore: data.InStore,
    briefDescription: data.description,
    fullDescription: data.fullDescription,
    priceinUSD: data.priceinUsd,
    payerID: '',
    eth: '',
  });

  const handlePrice = (e) => {
    const usdValue = e.target.value;
    const ethValue = usdValue * 0.0026;
    const ethPrice = window.web3.utils.toWei(ethValue.toString(), 'Ether');
    // Validate the input to allow only numbers with up to 2 decimal places
    if (/^\d*\.?\d{0,2}$/.test(usdValue) || usdValue === '') {
      setEditDetails({ ...editDetails, price: ethPrice, priceinUSD: usdValue });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditLoader(true);
    const ownerAddress = localStorage.getItem('cosmosaddress');
    try {
      console.log(contract);
      await contract.methods
        .editProduct(
          data.productId,
          editDetails.price,
          editDetails.inStore,
          editDetails.license,
          ownerAddress
        )
        .send({ from: account, gas: 6000000, gasPrice: 6000000 })
        .once('receipt', (receipt) => {
          const returnData = receipt.events.productEdited.returnValues;

          const updatedProduct = {
            productId: data.productId,
            license: returnData.license,
            inStore: returnData.inStore,
            ethPrice: returnData.price,
            usdPrice: parseFloat(editDetails.priceinUSD).toFixed(2),
            description: editDetails.briefDescription,
            fullDescription: editDetails.fullDescription,
            licensor: data.originatorEmail,
          };
          axios
            .put(serverBaseURL + '/updateProduct', { updatedProduct })
            .then((res) => {
              alert('Changed Asset Details Successfully!!!');
              window.location.reload();
            })
            .catch((err) => {
              alert('Error in Changing Asset Details, Please co');
              console.error(err);
            });
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="container edit-asset-modal">
        <form onSubmit={handleSubmit}>
          <div className="row pt-5 mt-md-3 mb-5 ml-1">
            <div className="col-5 mt-1">
              <div className="my-1">
                Hash:{' '}
                <a
                  href={`https://kovan.etherscan.io/tx/${data.transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <small>{data.transactionHash}</small>
                </a>
              </div>
              <div className="my-1">
                Registration Date:{' '}
                <span className="text-info">{getDate(data.timestamp)}</span>
              </div>
              <div className="my-1">
                Product Name:{' '}
                <span className="text-info text-capitalize">
                  {data.productName}
                </span>
              </div>
              <div className="my-1">
                Product Type:{' '}
                <span className="text-info text-capitalize">
                  {data.productType}
                </span>
              </div>
              <div className="asset-edit-image-col">
                {data.productType === 'script' ? (
                  <img
                    alt="Script Icon"
                    className="img-fluid mt-2"
                    src={require('../../Assets/Images/doc.jpeg')}
                    width="45%"
                    height="30vh"
                  />
                ) : data.productType === 'audio' ? (
                  <img
                    alt="Audio Icon"
                    className="img-fluid mt-2"
                    src={require('../../Assets/Images/music.png')}
                    width="45%"
                    height="30vh"
                  />
                ) : data.productType === 'video' ? (
                  <img
                    alt="Video Icon"
                    className="img-fluid mt-2"
                    src={require('../../Assets/Images/video.jpeg')}
                    width="45%"
                    height="30vh"
                  />
                ) : (
                  <img
                    alt="Data"
                    className="img-fluid mt-2"
                    src={`/image/${data.image}`}
                    width="45%"
                    height="30vh"
                  />
                )}
              </div>
            </div>
            <div className="col-2 mt-5">
              <div className="row mt-3">
                <div className="col-12 mt-5">
                  <div className="asset-form-input">
                    ${' '}
                    <input
                      type="number"
                      id="price"
                      name="price"
                      placeholder="Price"
                      onChange={handlePrice}
                      required
                      step="0.02"
                      value={editDetails.priceinUSD}
                    />{' '}
                    USD
                  </div>
                </div>
                <div className="col-12 d-flex asset-form-input">
                  <span className="ml-3 mr-2">License&nbsp;</span>
                  <input
                    id="license"
                    name="license"
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        license: !editDetails.license,
                      })
                    }
                    checked={editDetails.license}
                  />
                </div>
                <div className="col-12 d-flex asset-form-input">
                  <span className="ml-3 mr-2">In Store&nbsp;</span>
                  <input
                    id="inStore"
                    name="inStore"
                    type="checkbox"
                    className="custom-checkbox"
                    onChange={(e) =>
                      setEditDetails({
                        ...editDetails,
                        inStore: !editDetails.inStore,
                      })
                    }
                    checked={editDetails.inStore}
                  />
                </div>
              </div>
            </div>
            <div className="col-5 mt-5">
              <h5 className="text-primary">Comic Asset Details</h5>
              <div className="asset-form-input my-3">
                <input
                  id="briefDescription"
                  name="briefDescription"
                  value={editDetails.briefDescription}
                  onChange={(e) =>
                    setEditDetails({
                      ...editDetails,
                      briefDescription: e.target.value,
                    })
                  }
                  placeholder="Brief Description"
                  required
                />
              </div>
              <div className="asset-form-input my-3">
                <textarea
                  id="briefDescription"
                  name="briefDescription"
                  value={editDetails.fullDescription}
                  onChange={(e) =>
                    setEditDetails({
                      ...editDetails,
                      fullDescription: e.target.value,
                    })
                  }
                  required
                  placeholder="Full Description"
                />
              </div>
              {/* <PaypalLogin /> */}
              <div className="d-flex justify-content-end">
                {editLoader ? (
                  <button
                    type="submit"
                    className="btn btn-success mr-2"
                    disabled
                  >
                    Updating
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success mr-2">
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditAsset;
